import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TWeekDays, weekDays } from 'commons/constants/commercial'
import moment from 'moment'
import { CSSProperties, useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { numericMask } from 'utlis/mask'
import { useUpdateDataTable } from '../../hooks/dataTable'
import { useLoading } from '../../hooks/loading'
import api from '../../services/api'
import TableHeader from './Header'
import Pagination from './Pagination'
import Search from './Search'
import { LinkContainer } from './style'

interface Action {
  name: string
  icon?: IconDefinition
  spanIcon?: string
  htmlIcon?: JSX.Element
  title: string
  link?: string
  classname?: string
  activeClassname?: string
  style?: CSSProperties
  onClick?: (params: any) => void
  hasIcon?: (params: any) => boolean
  linkTo?: (params: any) => string
}

interface Header {
  name: string
  field: string
  sortable: boolean
  custom?: boolean
  element?: (item: any) => JSX.Element
  tdStyle?: React.CSSProperties
  download?: {
    fileName?: string
    fileUrl?: string
  }
  hasTypes?: {
    types?: any
  }
  concat?: {
    field: string
    position: 'start' | 'end'
  }
}

interface SearchParameters {
  [key: string]: string
}

interface DataTableProps {
  onActions?: {
    onClickButtonEdit?: <T>(currentValue: T | any) => void
    onClickButtonRemove?: <T>(currentValue: T | any) => void
    onClickButtonList?: <T>(currentValue: T | any) => void
  }
  entity: string
  source: string
  headers?: Header[]
  customHeaders?: Header[]
  actions?: Action[]
  notHasChildren?: boolean
  onlyParent?: boolean
  notParent?: boolean
  onlyView?: boolean
  parentId?: string
  viewField?: { source: string; field: string }
  entityId?: string
  searchParameters?: SearchParameters[]
  showParentId?: boolean
  format: {
    orderBy: string
  }
  orderBySort?: 'ASC' | 'DESC'
  excludeColumn?: string
  excludeKey?: string
  activeItems?: number[]
  handleError?: (error: any) => void
  handleSuccess?: (items: any) => void
}

const DataTable = ({
  onActions,
  entity,
  source,
  notHasChildren,
  viewField,
  onlyView,
  onlyParent,
  notParent,
  excludeColumn,
  excludeKey,
  headers = [
    { name: 'Data', field: 'created_at', sortable: true },
    { name: 'Usuário', field: 'user.name', sortable: false },
    { name: 'Descrição', field: 'descriptions', sortable: true },
    { name: 'Ação', field: 'type', sortable: true }
  ],
  customHeaders,
  actions,
  format,
  orderBySort,
  parentId,
  entityId,
  searchParameters,
  showParentId,
  activeItems,
  handleError,
  handleSuccess
}: DataTableProps): JSX.Element => {
  const [items, setItems] = useState<any[]>([])
  const [filterItems, setFilterItems] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [search, setSearch] = useState('')
  const [ItemsPerPage, setItemsPerPage] = useState(50)
  const { isUpdated } = useUpdateDataTable()
  const [pageChange, setPageChange] = useState(false)
  const { activeLoading, disableLoading } = useLoading()

  const lastSort = useRef<{ field: string; order: string }>()

  const handlerOnClickButtonList = (currentValue: any) => {
    if (viewField) {
      return `/${viewField.source}/view/${currentValue[viewField.field]}?tab=${
        currentValue.id
      }`
    }
    if (typeof onActions?.onClickButtonList === 'function') {
      onActions.onClickButtonList(currentValue)
    } else {
      return `/${source}/view/${currentValue.id}`
    }
  }

  const handlerOnClickButtonEdit = (currentValue: any) => {
    return `/${source}/update/${currentValue.id}`
  }

  const handlerOnClickButtonRemove = (currentValue: any) => {
    if (onActions?.onClickButtonRemove) {
      onActions.onClickButtonRemove(currentValue)
    }
  }

  const sortColumn = (field: string, order: string, items: any[]): any[] => {
    const sortedItems = items.sort((a, b) => {
      const fields = field.split('.')
      let currentFieldA
      let currentFieldB
      if (fields.length === 1) {
        currentFieldA = a[fields[0]]
        currentFieldB = b[fields[0]]
      }
      if (fields.length === 2) {
        currentFieldA = a[fields[0]]?.[fields[1]]
        currentFieldB = b[fields[0]]?.[fields[1]]
      }
      if (fields.length === 3) {
        currentFieldA = a[fields[0]]?.[fields[1]]?.[fields[2]]
        currentFieldB = b[fields[0]]?.[fields[1]]?.[fields[2]]
      }
      if (fields.length === 4) {
        currentFieldA = a[fields[0]]?.[fields[1]]?.[fields[2]]?.[fields[3]]
        currentFieldB = b[fields[0]]?.[fields[1]]?.[fields[2]]?.[fields[3]]
      }
      if (
        moment(currentFieldA, 'DD/MM/YYYY').isValid() &&
        moment(currentFieldB, 'DD/MM/YYYY').isValid()
      ) {
        const date =
          order === 'ASC'
            ? moment(currentFieldA, 'DD/MM/YYYY').isAfter(
                moment(currentFieldB, 'DD/MM/YYYY')
              )
            : moment(currentFieldA, 'DD/MM/YYYY').isBefore(
                moment(currentFieldB, 'DD/MM/YYYY')
              )

        return date ? 1 : -1
      }
      if (
        typeof currentFieldA === 'string' &&
        typeof currentFieldB === 'string'
      ) {
        currentFieldA = currentFieldA.toLowerCase()
        currentFieldB = currentFieldB.toLowerCase()
      }
      const position =
        order === 'ASC'
          ? currentFieldA > currentFieldB
          : currentFieldA < currentFieldB
      return position ? 1 : -1
    })

    return sortedItems
  }

  const loadParams = useCallback(() => {
    const params = {
      entity,
      source,
      keyword: search ? { [format.orderBy]: search } : '',
      page: currentPage,
      perPage: ItemsPerPage,
      orderByField: '',
      searchParameters:
        searchParameters?.length > 0 ? searchParameters : undefined,
      onlyParent,
      notParent,
      orderBy: format.orderBy,
      orderBySort,
      parentId,
      entityId,
      excludeColumn,
      excludeKey
    }

    if (!parentId) Object.assign(params, { parentId: '' })
    if (!entityId) Object.assign(params, { entityId: '' })

    return params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ItemsPerPage,
    currentPage,
    entity,
    entityId,
    excludeColumn,
    excludeKey,
    format.orderBy,
    orderBySort,
    onlyParent,
    notParent,
    parentId,
    searchParameters,
    source
  ])
  const isDoubleSearch = search && searchParameters
  // UseEffect pra pesquisas que não envolvem FILTRO/SEARCH
  useEffect(() => {
    ;(async () => {
      if (search || isDoubleSearch) {
        return
      }
      try {
        activeLoading()
        const params = loadParams()
        const response = await api.get('dataTable', { params })
        let items = response.data.items
        if (lastSort.current) {
          const sortedItems = sortColumn(
            lastSort.current.field,
            lastSort.current.order,
            items
          )
          items = sortedItems
        }
        setItems(items)
        setFilterItems(items)
        setTotalItems(response.data.totalItens)
        setCurrentPage(response.data.page)
        disableLoading()
        if (handleSuccess) {
          handleSuccess(items)
        }
      } catch (error) {
        disableLoading()
        console.error(error)
        if (handleError) {
          handleError(error)
        }
      }
    })()
  }, [
    loadParams,
    isUpdated,
    activeLoading,
    disableLoading,
    search,
    isDoubleSearch,
    handleError,
    handleSuccess,
    searchParameters
  ])

  const firstItem =
    totalItems === 0 ? totalItems : ItemsPerPage * (currentPage - 1) + 1
  const getTotalItems = (initialValue: number): number => {
    let sum = 0
    if (initialValue > 1) {
      return items.length + initialValue - 1
    } else {
      if (notHasChildren) {
        sum = items.reduce((sum, value) => {
          if (!showParentId && !value.parent_id) {
            return sum + 1
          }
          if (showParentId) {
            return sum + 1
          }
          return sum
        }, 0)
      } else {
        sum = items.length
      }
    }
    return sum
  }

  const getSearched = async (value: string) => {
    const params = loadParams()

    const newParams = {
      ...params,
      keyword: { [params.orderBy]: value },
      page: 1
    }
    const response = await api.get('dataTable', {
      params: newParams
    })
    setItems(response.data.items)
    setTotalItems(response.data.totalItens)

    setCurrentPage(1)
  }

  let timeOutId: NodeJS.Timeout
  const onSearchItem = async (value: string) => {
    if (value.length === 0) {
      setItems(filterItems)
      clearTimeout(timeOutId)
      return
    }

    clearTimeout(timeOutId)
    timeOutId = setTimeout(() => getSearched(value), 0)
  }

  const handleDownload = useCallback((item: any, header: Header) => {
    let filename = item[header.download.fileName]
    const indexTrace = filename.indexOf('-') + 1
    filename = filename.slice(indexTrace, filename.length)
    fetch(`${item[header.download.fileUrl]}`).then(response => {
      response.blob().then((blobObject: any) => {
        const url = window.URL.createObjectURL(blobObject)
        const a = document.createElement('a')
        a.download = filename
        a.href = url
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      })
    })
  }, [])

  return (
    <div id="kt_datatable">
      <div className="card-header p-0 py-5 gap-2 gap-md-5">
        <div className="">
          <div id="kt_datatable" className="dataTables_length">
            <label>
              <select
                onChange={e => {
                  setItemsPerPage(Number(e.target.value))
                  setCurrentPage(1)
                }}
                className="form-select form-select-solid form-select-sm fw-boldl"
              >
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
              </select>
              <p className="">resultados por página</p>
            </label>
          </div>
        </div>
        <div className="gap-5 d-flex flex-column">
          <div className="dataTables_filter d-flex align-items-center">
            <label
              className="fw-bolder pe-2 fs-6 text-gray-800"
              htmlFor="search"
            >
              Pesquisar
            </label>
            <Search
              isPageChanged={pageChange}
              search={search}
              setSearch={setSearch}
              setIsPageChanged={setPageChange}
              onSearch={value => onSearchItem(value)}
            />
          </div>
          <div className="dataTables_paginate paging_bootstrap_number d-flex justify-content-end">
            <Pagination
              total={totalItems}
              itemsPerPage={ItemsPerPage}
              currentPage={currentPage}
              onPageChange={page => setCurrentPage(page)}
              setIsPageChanged={setPageChange}
            />
          </div>
        </div>
      </div>

      <div className="table-scrollable dataTable">
        <table
          id="kt_datatable"
          className="dataTable table table-striped table-row-bordered gs-3 border table-hover"
        >
          <TableHeader
            headers={headers}
            onSorting={(field, order) => {
              lastSort.current = {
                field,
                order
              }
              const itemSorted = sortColumn(field, order, items)
              setItems([...itemSorted])
            }}
          />
          <tbody>
            {(items.length > 0 &&
              items.map(item => {
                const types = [
                  { key: 'locacao', label: 'LOCAÇÃO' },
                  { key: 'L', label: 'LOCAÇÃO' },
                  { key: 'materia-prima', label: 'MATERIA PRIMA' },
                  { key: 'revenda', label: 'REVENDA' },
                  { key: 'semi-acabado', label: 'SEMI ACABADO' },
                  { key: 'consumo', label: 'USO E CONSUMO' },
                  { key: 'CONSUMO', label: 'USO E CONSUMO' },
                  { key: 'venda', label: 'VENDA' },
                  { key: 'V', label: 'VENDA' },
                  { key: 'C', label: 'Crédito' },
                  { key: 'D', label: 'Débito' }
                ]

                if (
                  item.payament_form &&
                  typeof item.payament_form === 'string'
                ) {
                  item.payament_form = JSON.parse(item.payament_form)
                  if (item.payament_form.value) {
                    item.payament_form.value = numericMask(
                      item.payament_form.value.toString()
                    )
                  }
                }
                if (item.due_date) {
                  item.due_date = item.due_date.substring(0, 10)
                }
                if (item.banknote_send_at) {
                  item.banknote_send_at = item.banknote_send_at.substring(0, 10)
                }
                if (source === 'financial/financialMoviments') {
                  if (item.situation) {
                    if (item.situation === 1) {
                      item.situation = 'Pago'
                    }
                  } else {
                    item.situation = 'Aguardando'
                  }
                }
                if (item.banknote_send_at) {
                  item.banknote_send_at = item.banknote_send_at.substring(0, 10)
                }

                const typeProduct = types.find(
                  t => t.key === item.product?.type
                )
                if (typeProduct) {
                  item.product.type = typeProduct.label
                }
                const type = types.find(t => t.key === item.type)
                if (type) {
                  item.type = type.label
                }

                if (item.start_date) {
                  item.start_date = item.start_date.substring(0, 10)
                }

                return (
                  <tr key={item.id}>
                    {headers.map(
                      header =>
                        (header?.field === 'descriptions' &&
                          entity === 'AuditLog' && (
                            <td
                              key={`${header?.field}-${item.id}`}
                              className="border"
                            >
                              {typeof item.descriptions === 'string' ? (
                                <p>{item.descriptions}</p>
                              ) : (
                                item?.descriptions?.map(
                                  (description: string) => (
                                    <p key={description}>{description}</p>
                                  )
                                )
                              )}
                            </td>
                          )) ||
                        (header?.field === 'menu_dishes' &&
                          entity === 'MenuFoodDish' && (
                            <td
                              key={`${header?.field}-${item.id}`}
                              className="border col-md-6"
                            >
                              {item?.menu_dishes?.length === 0 &&
                                'O cardápio não possui nenhum item associado.'}
                              {item?.menu_dishes.map((menu_dish: any) => {
                                return JSON.parse(menu_dish.dishes_info)?.map(
                                  (dish_info: any) => {
                                    return (
                                      <p key={dish_info.id} className="py-2">
                                        {`${dish_info.dish_type_name} - ${dish_info.dish_name} - ${dish_info.dish_amount} unidade(s)`}
                                      </p>
                                    )
                                  }
                                )
                              })}
                            </td>
                          )) ||
                        (header?.field === 'date' &&
                          entity === 'MenuFoodDish' && (
                            <td
                              key={`${header?.field}-${item.id}`}
                              className="border col-md-6"
                              style={{ width: '200px' }}
                            >
                              {item.date}
                              {' - '}
                              {
                                weekDays[
                                  moment(
                                    item.date,
                                    'DD/MM/YYYY'
                                  ).weekday() as TWeekDays
                                ]
                              }
                            </td>
                          )) ||
                        (header?.field === 'institutions_list' &&
                          entity === 'MenuFood' && (
                            <td
                              key={`${header?.field}-${item.id}`}
                              className="border"
                            >
                              {item?.menu_institutions?.length === 0 &&
                                'O cardápio não possui nenhuma instituição associada.'}
                              {item?.menu_institutions?.map(
                                (menu_inst: any) => {
                                  return (
                                    <p key={menu_inst.id}>
                                      {
                                        menu_inst.institution
                                          .company_social_name
                                      }
                                    </p>
                                  )
                                }
                              )}
                            </td>
                          )) ||
                        (header?.field === 'period' && (
                          <td
                            key={`${header?.field}-${item.id}`}
                            className="border"
                          >
                            <p style={{ textAlign: 'left' }}>
                              {`${item.start_validity.slice(
                                0,
                                10
                              )} - ${item.end_validity.slice(0, 10)}`}
                            </p>
                          </td>
                        )) ||
                        (header?.field === 'menu_food.period' && (
                          <td
                            key={`${header?.field}-${item.id}`}
                            className="border"
                          >
                            <p style={{ textAlign: 'left' }}>
                              {`${
                                header?.field.split('.').length === 2 &&
                                item.menu_food?.start_validity.slice(0, 10)
                              }
                                  - ${
                                    header?.field.split('.').length === 2 &&
                                    item.menu_food?.end_validity.slice(0, 10)
                                  }`}
                            </p>
                          </td>
                        )) ||
                        (header?.field !== 'actions' &&
                          !header?.download &&
                          !header?.hasTypes &&
                          !header.custom && (
                            <td
                              key={`${header?.field}-${item.id}`}
                              className="border"
                            >
                              <p
                                style={{
                                  textAlign: 'left',
                                  whiteSpace: 'pre'
                                }}
                              >
                                {typeof item[header?.field] === 'boolean' &&
                                  (item[header?.field] ? 'Sim' : 'Não')}
                                {header?.field.split('.').length === 2 &&
                                  typeof item[header?.field.split('.')[0]]?.[
                                    header?.field.split('.')[1]
                                  ] === 'boolean' &&
                                  (item[header?.field.split('.')[0]]?.[
                                    header?.field.split('.')[1]
                                  ]
                                    ? 'Sim'
                                    : 'Não')}
                                {header?.field.split('.').length === 4 &&
                                  item[header?.field.split('.')[0]]?.[
                                    header?.field.split('.')[1]
                                  ]?.[header?.field.split('.')[2]]?.[
                                    header?.field.split('.')[3]
                                  ]}
                                {header?.field.split('.').length === 3 &&
                                  item[header?.field.split('.')[0]]?.[
                                    header?.field.split('.')[1]
                                  ]?.[header?.field.split('.')[2]]}
                                {header?.field.split('.').length === 2 &&
                                  item[header?.field.split('.')[0]]?.[
                                    header?.field.split('.')[1]
                                  ]}
                                {header?.concat
                                  ? header?.concat?.position === 'start'
                                    ? `${item[header?.concat?.field]} ${
                                        item[header?.field]
                                      }`
                                    : `${item[header?.field]} ${
                                        item[header?.concat?.field]
                                      }`
                                  : header?.field.split('.').length === 1 &&
                                    item[header?.field]}
                              </p>
                            </td>
                          )) ||
                        (header.custom && (
                          <td style={header.tdStyle}>
                            {customHeaders
                              .find(h => h.field === header.field)
                              .element(item)}
                          </td>
                        )) ||
                        (header?.download && (
                          <td
                            key={`${header?.field}-${item.id}`}
                            className="border"
                          >
                            <a
                              href="#"
                              onClick={() => handleDownload(item, header)}
                              style={{
                                display: 'flex',
                                alignItems: 'flex-start'
                              }}
                            >
                              <span
                                className="fa fa-download"
                                style={{ marginRight: '5px' }}
                              ></span>
                              <span>Download</span>
                            </a>
                          </td>
                        )) ||
                        (header?.hasTypes && (
                          <td
                            key={`${header?.field}-${item.id}`}
                            className="border"
                          >
                            <p style={{ textAlign: 'left' }}>
                              {header.hasTypes.types[item[header?.field]]}
                            </p>
                          </td>
                        )) || (
                          <td
                            key={`actions-${item.id}`}
                            className="actions border"
                          >
                            {(actions && (
                              <LinkContainer>
                                {actions.map(
                                  action =>
                                    (action.hasIcon
                                      ? action.hasIcon(item)
                                      : true) && (
                                      <Link
                                        className={
                                          activeItems?.length &&
                                          activeItems.includes(item.id)
                                            ? `${action.classname} ${action.activeClassname}`
                                            : `${action.classname}`
                                        }
                                        to={
                                          action.link ||
                                          (action.linkTo &&
                                            action.linkTo(item)) ||
                                          '#'
                                        }
                                        key={action.name}
                                        title={action.title}
                                        onClick={e => {
                                          if (action.onClick) {
                                            e.preventDefault()
                                            action.onClick(item)
                                          }
                                        }}
                                      >
                                        {action.spanIcon && (
                                          <span
                                            className={action.spanIcon}
                                            style={action.style}
                                          />
                                        )}
                                        {action.icon && (
                                          <FontAwesomeIcon icon={action.icon} />
                                        )}
                                        {action.htmlIcon && action.htmlIcon}
                                      </Link>
                                    )
                                )}
                              </LinkContainer>
                            )) || (
                              <LinkContainer>
                                {showParentId && (
                                  <Link
                                    className="link"
                                    key={Math.random()}
                                    title="Visualizar"
                                    to={() => handlerOnClickButtonList(item)}
                                  >
                                    <span className="fa fa-search" />
                                  </Link>
                                )}
                                {!showParentId && (
                                  <Link
                                    className="link"
                                    key={Math.random()}
                                    title="Visualizar"
                                    to={() => handlerOnClickButtonList(item)}
                                  >
                                    <span className="fa fa-search" />
                                  </Link>
                                )}
                                <div>
                                  {!onlyView && (
                                    <Link
                                      className="link"
                                      key={Math.random()}
                                      title="Editar"
                                      onClick={e => {
                                        if (onActions?.onClickButtonEdit) {
                                          e.preventDefault()
                                          onActions.onClickButtonEdit(item)
                                        }
                                      }}
                                      to={() => handlerOnClickButtonEdit(item)}
                                    >
                                      <span className="fa fa-edit" />
                                    </Link>
                                  )}
                                  {!notHasChildren && (
                                    <a
                                      href="#"
                                      key={Math.random()}
                                      title="Remover"
                                      className="link"
                                      onClick={() => {
                                        handlerOnClickButtonRemove(item)
                                      }}
                                    >
                                      <span className="fa fa-remove" />
                                    </a>
                                  )}
                                </div>
                              </LinkContainer>
                            )}
                          </td>
                        )
                    )}
                  </tr>
                )
              })) || (
              <tr>
                <td colSpan={headers.length} style={{ textAlign: 'center' }}>
                  Nenhum registro encontrado
                </td>
              </tr>
            )}
          </tbody>
          <tfoot />
        </table>
      </div>
      <div className="d-flex flex-stack align-items-end h-60px">
        <div className="">
          <div className="dataTables_info" id="kt_datatable">
            Mostrando de {firstItem} até {getTotalItems(firstItem)} de{' '}
            {totalItems} {totalItems === 1 ? 'registro' : 'registros'}
          </div>
        </div>
        <div className="">
          <div className="dataTables_paginate paging_bootstrap_number">
            <Pagination
              total={totalItems}
              itemsPerPage={ItemsPerPage}
              currentPage={currentPage}
              onPageChange={page => setCurrentPage(page)}
              setIsPageChanged={setPageChange}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DataTable
