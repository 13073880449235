import { calculateDiscountToBRL } from 'utlis/calculateDiscount'
import { currencyMask } from 'utlis/mask'
import { ButtonCircle, Card, CardSubText } from '../FormRegister/styles'
import Boleto from 'assets/image/boleto.png'
import CardImage from 'assets/image/card.png'
import Pix from 'assets/image/pix.png'
import { BsCheckCircle, BsCircle } from 'react-icons/bs'
import { CardSummary, SummaryContractRight, SummarySubText } from './styles'
import { Select } from 'components/Form'
import { OPTIONS_FORM_BOLETO } from 'commons/constants/commercial'
import moment from 'moment'

interface IContractSummary {
  client_name: string
  student_name: string
  institution_name: string
  contract_plans: IContractPlanDisplay[]
  total: string
  discount: string
  subTotal: string
  dueDate: string | Date
  setDueDate: React.Dispatch<React.SetStateAction<string | Date>>
  selectedPayamentForm: TFormPayament
  setSelectedPayamentForm: React.Dispatch<React.SetStateAction<TFormPayament>>
  setRecurrenceNumber: React.Dispatch<React.SetStateAction<number>>
  recurrenceNumber: number
}

const Summary = ({
  total,
  discount,
  subTotal,
  contract_plans,
  dueDate,
  setDueDate,
  selectedPayamentForm,
  setSelectedPayamentForm,
  setRecurrenceNumber,
  recurrenceNumber
}: IContractSummary) => {
  const actualMonth = new Date().getMonth() + 1
  const actualYear = new Date().getFullYear()

  const alloweDaysAndMonth = [
    {
      day: 10,
      month: actualMonth
    },
    {
      day: 15,
      month: actualMonth
    },
    {
      day: 20,
      month: actualMonth
    }
  ]
  const allowedDates = []
  for (let i = 0; i < 6; i++) {
    let date
    if (i < 3) {
      const { month, day } = alloweDaysAndMonth[i]
      date = moment(`${month}-${day}-${actualYear}`)
    } else {
      const { month, day } = alloweDaysAndMonth[i - 3]
      date = moment(`${month}-${day}-${actualYear}`).add(1, 'month')
    }
    if (date.isSameOrAfter(new Date())) {
      allowedDates.push(date)
    }
  }
  const allowedDatesOptions = allowedDates.map(date => {
    return {
      name: moment(date).format('DD/MM/YYYY'),
      value: moment(date).format('DD/MM/YYYY')
    }
  })

  return (
    <div className="row mb-3">
      <div>
        <h3 className="col-form-label fw-bold fs-5">Método de pagamento</h3>
      </div>
      <div className="row mb-5">
        <div className="row col-md-9 align-content-start">
          <div className="row col-md-12">
            <div className="col-xl-4 mb-5">
              <Card
                isSelected={selectedPayamentForm === 'Boleto'}
                className="d-flex align-items-center justify-content-center"
              >
                <ButtonCircle
                  type="button"
                  onClick={() => {
                    setSelectedPayamentForm('Boleto')
                  }}
                  style={{ width: '22px', height: '22px' }}
                >
                  {selectedPayamentForm === 'Boleto' ? (
                    <BsCheckCircle fontSize={22} color="#009EF7" />
                  ) : (
                    <BsCircle fontSize={20} color="#A1A5B7" />
                  )}
                </ButtonCircle>
                <img src={Boleto} alt="Boleto" />
              </Card>
            </div>

            <div className="col-xl-4">
              <Card
                isSelected={selectedPayamentForm === 'Paypal'}
                className="d-flex align-items-center justify-content-center"
              >
                <ButtonCircle
                  type="button"
                  onClick={() => {
                    setSelectedPayamentForm('Paypal')
                  }}
                  style={{ width: '22px', height: '22px' }}
                >
                  {selectedPayamentForm === 'Paypal' ? (
                    <BsCheckCircle fontSize={22} color="#009EF7" />
                  ) : (
                    <BsCircle fontSize={20} color="#A1A5B7" />
                  )}
                </ButtonCircle>
                <img src={CardImage} alt="CardImage" height={91} />
              </Card>
            </div>
          </div>
          {selectedPayamentForm === 'Boleto' && (
            <div className="row">
              <Select
                options={OPTIONS_FORM_BOLETO(12, total)}
                name="number_recurrence"
                rules={{ required: true }}
                value={recurrenceNumber}
                onChange={event => setRecurrenceNumber(+event.target.value)}
                className={'col-md-6'}
                label="Parcelas"
                blank
              />
              <Select
                options={allowedDatesOptions}
                name="number_recurrence"
                rules={{ required: true }}
                value={dueDate as string}
                onChange={event => setDueDate(event.target.value)}
                className="col-md-3"
                label="Primeiro vencimento"
                blank
              />
            </div>
          )}
        </div>
        <SummaryContractRight className="row col-md-3">
          <h3 style={{ color: '#3F4254' }}>RESUMO DA CONTRATAÇÃO</h3>
          <div className="gap-2 d-flex flex-column">
            {contract_plans.map(contract => {
              return (
                <CardSummary key={contract.plan_id}>
                  <div className="d-flex justify-content-between mb-2">
                    <h3 style={{ color: '#3F4254' }}>{contract.plan_name}</h3>
                    <CardSubText>{contract.periodicity_payament}</CardSubText>
                  </div>

                  <p className="d-flex justify-content-between mb-2">
                    <SummarySubText>Valor do plano</SummarySubText>{' '}
                    <SummarySubText>
                      {currencyMask(contract.plan_value)}
                    </SummarySubText>
                  </p>
                  <p className="d-flex justify-content-between mb-2">
                    <SummarySubText>Desconto</SummarySubText>{' '}
                    <SummarySubText>
                      {calculateDiscountToBRL(
                        contract.discount.toString(),
                        +contract.plan_value
                      )}
                    </SummarySubText>
                  </p>
                  <p className="d-flex justify-content-between">
                    <SummarySubText fontSize={13} color={'#464E5F'}>
                      Total
                    </SummarySubText>{' '}
                    <SummarySubText fontSize={13} color={'#464E5F'}>
                      {currencyMask(contract.value_with_discount)}
                    </SummarySubText>
                  </p>
                </CardSummary>
              )
            })}
            <p className="d-flex justify-content-between mb-2">
              <SummarySubText fontSize={14}>Subtotal</SummarySubText>
              <SummarySubText fontSize={14}>{subTotal}</SummarySubText>
            </p>
            <p className="d-flex justify-content-between mb-2">
              <SummarySubText fontSize={14}>Desconto</SummarySubText>

              <SummarySubText fontSize={14}>{discount}</SummarySubText>
            </p>
            <p className="d-flex justify-content-between mb-2">
              <SummarySubText color="#009EF7" fontSize={16}>
                Total
              </SummarySubText>
              <SummarySubText color="#009EF7" fontSize={16}>
                {total}
              </SummarySubText>
            </p>
          </div>
        </SummaryContractRight>
      </div>

      <div className="row mb-5"></div>
    </div>
  )
}

export default Summary
