import DataTable from '../../../../../components/DataTable'
import { nameEntity, nameSource, situations } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'
import Container from '../../../../../components/Container'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'
import Form, { Input, Select } from 'components/Form'
import { DateInput } from 'components/Form/date'
import { FilterContainer } from './styles'
import api from 'services/api'
import { apiListInstitutionsOptions, apiListMenuFoodTypes } from '../domain/api'
import { useToast } from 'hooks/toast'

const MenuFoodsDishesList = (): JSX.Element => {
  const { addToast } = useToast()
  const [startDate, setStartDate] = useState<Date>()
  const [finalDate, setFinalDate] = useState<Date>()
  const [situation, setSituation] = useState<string>()
  const [menuFoodType, setMenuFoodType] = useState<string>()
  const [institutionId, setInstitutionId] = useState<string>()
  const [menuFoodTypeOptions, setMenuFoodTypeOptions] = useState<IMenuFood[]>(
    []
  )
  const [institutionsOptions, setInstitutionsOptions] = useState<
    {
      id: number
      company_social_name: string
    }[]
  >([])
  const [parameters, setParameters] = useState<any>()
  const history = useHistory()

  useEffect(() => {
    api
      .get(apiListMenuFoodTypes())
      .then(res => {
        const orderedData = res.data.sort(
          (a: IMenuFoodType, b: IMenuFoodType) => a.name.localeCompare(b.name)
        )
        setMenuFoodTypeOptions(orderedData)
      })
      .catch(e => {
        addToast({
          type: 'error',
          title: 'Erro ao carregar as refeições',
          description:
            'Houve um erro ao carregar as refeições disponíveis no banco de dados.'
        })
      })
    api
      .get(apiListInstitutionsOptions())
      .then(res => {
        setInstitutionsOptions(res.data)
      })
      .catch(e => {
        addToast({
          type: 'error',
          title: 'Erro ao carregar as instituições',
          description:
            'Houve um erro ao carregar as instituições disponíveis no banco de dados.'
        })
      })
  }, [])

  const onSearch = async () => {
    const query = []
    if (institutionId) {
      query.push(`institution_id=${institutionId}`)
    }
    if (menuFoodType) {
      query.push(`menu_food_type_id=${menuFoodType}`)
    }
    if (startDate) {
      query.push(
        `start_validity=${moment(startDate).format().substring(0, 10)}`
      )
    }
    if (finalDate) {
      query.push(`end_validity=${moment(finalDate).format().substring(0, 10)}`)
    }
    if (situation) {
      query.push(`is_active=${situation}`)
    }

    history.replace(`/commercial/menuFoods?${query.join('&')}`)
    if (startDate || finalDate || situation || menuFoodType || institutionId) {
      let is_active
      if (situation === 'false') {
        is_active = false
      } else if (situation === 'true') {
        is_active = true
      }
      setParameters([
        {
          institution_id: institutionId || undefined,
          menu_food_type_id: menuFoodType || undefined,
          start_validity: startDate ? moment(startDate).toDate() : undefined,
          end_validity: finalDate ? moment(finalDate).toDate() : undefined,
          is_active: is_active
        }
      ])
    } else {
      setParameters([])
    }
  }

  const location = new URLSearchParams(useLocation().search)

  useEffect(() => {
    const institutionId = location.get('institution_id')
    const menuFoodType = location.get('menu_food_type_id')
    const startDate = location.get('start_validity')
    const finalDate = location.get('end_validity')
    const situation = location.get('is_active')
    let is_active
    if (situation === 'false') {
      is_active = false
    } else if (situation === 'true') {
      is_active = true
    }

    if (startDate || finalDate || situation || menuFoodType || institutionId) {
      if (institutionId) {
        setInstitutionId(institutionId)
      }
      if (menuFoodType) {
        setMenuFoodType(menuFoodType)
      }
      if (startDate) {
        setStartDate(moment(`${startDate} 00:00:00`).toDate())
      }
      if (finalDate) {
        setFinalDate(moment(`${finalDate} 00:00:00`).toDate())
      }
      setSituation(situation)
      setParameters([
        {
          institution_id: institutionId || undefined,
          menu_food_type_id: menuFoodType || undefined,
          start_validity: startDate ? moment(startDate).toDate() : undefined,
          end_validity: finalDate ? moment(finalDate).toDate() : undefined,
          is_active: is_active
        }
      ])
    } else {
      setParameters([])
    }
  }, [])
  return (
    <Container
      pageTitle="Listagem"
      breadcrumb={breadcrumbList}
      tools={toolsList}
    >
      <FilterContainer>
        <Form onSubmit={onSearch} defaultValues={{}} className="col-md-12">
          <div className="col-md-12 d-flex">
            <div className="col-md-3 pe-8">
              <Select
                label="Instituição"
                name="institution_id"
                options={institutionsOptions?.map(type => {
                  return {
                    name: type.company_social_name,
                    value: type.id
                  }
                })}
                onChange={e => setInstitutionId(e.target.value)}
                value={institutionId}
                blank
                controlled
                defaultValue={''}
              />
            </div>
            <div className="col-md-3 pe-8">
              <Select
                label="Tipo"
                name="type"
                options={menuFoodTypeOptions?.map(type => {
                  return {
                    name: type.name,
                    value: type.id
                  }
                })}
                onChange={e => setMenuFoodType(e.target.value)}
                value={menuFoodType}
                blank
                controlled
                defaultValue={''}
              />
            </div>
            {/* <div className="col-md-3 pe-8">
              <DateInput
                label="Data inicial"
                name="start_validity"
                onChange={date => setStartDate(date)}
                selected={startDate}
                controlled
              />
            </div>
            <div className="col-md-3 pe-8">
              <DateInput
                label="Data final"
                name="end_validity"
                onChange={date => setFinalDate(date)}
                selected={finalDate}
                controlled
              />
            </div> */}
            <div className="col-md-3">
              <Select
                label="Ativo"
                name="is_active"
                options={situations}
                onChange={e => setSituation(e.target.value)}
                value={situation}
                controlled
                blank
                defaultValue={''}
              />
            </div>
          </div>
          <footer className="col-md-12 d-flex justify-content-end pb-5 gap-5">
            <button
              type="reset"
              className="btn btn-secondary btn-sm"
              onClick={() => {
                setStartDate(undefined)
                setFinalDate(undefined)
                setSituation(undefined)
                setMenuFoodType(undefined)
                setInstitutionId(undefined)
              }}
            >
              LIMPAR
            </button>
            <button type="submit" className="btn btn-primary btn-sm">
              BUSCAR
            </button>
          </footer>
        </Form>
        <hr />
      </FilterContainer>

      <div>
        <h3 className="h3 pt-6">Listagem</h3>
      </div>
      <DataTable
        source={nameSource}
        entity={nameEntity}
        format={{ orderBy: 'id' }}
        headers={headers}
        searchParameters={parameters}
        actions={[
          {
            name: 'Visualizar',
            title: 'Visualizar',
            spanIcon: 'fa fa-search',
            linkTo: dish => `/commercial/menuFoods/view/${dish.id}`,
            style: { margin: '0 5px' }
          },
          {
            name: 'Editar',
            title: 'Editar',
            spanIcon: 'fa fa-edit',
            linkTo: dish => `/commercial/menuFoods/update/${dish.id}`
          }
        ]}
      />
    </Container>
  )
}
export default MenuFoodsDishesList
