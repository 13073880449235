type TypeActionNameWithTo = {
  name: string
  to: string
  icon: string
}

type PortletTitleCrudType = {
  create: TypeActionNameWithTo
  read: TypeActionNameWithTo
  update: TypeActionNameWithTo
  delete: TypeActionNameWithTo
}

const nameEntity = 'Institution'
const nameSource = 'commercial/institutions'
const namePageTitle = 'Instituições'

const nameActionPageMain: TypeActionNameWithTo = {
  name: 'Instituições',
  to: '/commercial/institutions',
  icon: ''
}

const nameActions: PortletTitleCrudType = {
  create: {
    name: 'Adicionar',
    to: '/commercial/institutions/create/',
    icon: 'fa fa-plus'
  },
  read: {
    name: 'Visualizar',
    to: '/commercial/institutions/',
    icon: 'fa fa-list'
  },
  update: {
    name: 'Editar',
    to: '/commercial/institutions/update/',
    icon: 'fa fa-edit'
  },
  delete: {
    name: 'Remover',
    to: '#!',
    icon: 'fa fa-remove'
  }
}

const schoolPeriods = ['Manha', 'Tarde', 'Noite', 'Integral']

const schoolYears = [
  'Bercario',
  'Infantil 1',
  'Infantil 2',
  'Infantil 3',
  'Infantil 4',
  'Infantil 5',
  '1 ano',
  '2 ano',
  '3 ano',
  '4 ano',
  '5 ano',
  '6 ano',
  '7 ano',
  '8 ano',
  '9 ano'
]

export {
  nameEntity,
  nameSource,
  namePageTitle,
  nameActionPageMain,
  nameActions,
  schoolPeriods,
  schoolYears
}
