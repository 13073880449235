import styled from 'styled-components'

interface ICardProps {
  isSelected?: boolean
  height?: number
  height_big_screen?: number
}

export const Card = styled.div<ICardProps>`
  position: relative;
  /* padding: 1rem; */
  border: ${({ isSelected }) =>
    isSelected ? '1px solid #00A3FF' : '1px solid #A1A5B7'};
  background-color: ${({ isSelected }) =>
    isSelected ? '#F1FAFF' : '#F5F8FA4D'};
  border-style: dashed;
  padding: 21px 39px;
  height: ${({ height }) => (height ? `${height}px` : 'auto')};

  border-radius: 1rem;
  h4 {
    font-size: 1.2rem;
    color: #3f4254;
  }

  .card__see-items {
    font-size: 1rem;
    background-color: transparent;
    border: none;
    :hover {
      text-decoration: underline;
    }
  }

  @media screen and (min-width: 1440px) {
    height: ${({ height_big_screen }) =>
      height_big_screen ? `${height_big_screen}px` : 'auto'};
  }
`

export const ButtonCircle = styled.button`
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 8px;
  border: none;
  background-color: transparent;
`

export const PlanFrequencyCard = styled.div`
  span {
    font-weight: 700;
  }
`

export const CardText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 130px;

  word-wrap: break-word;
  color: #7e8299;
`
