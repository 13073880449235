/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react'
import Form from '../../../../../../components/Form'

import api from 'services/api'
import { DateInput as DatePicker } from '../../../../../../components/Form/date'
import { FormContainer } from './styles'
import { useLoading } from 'hooks/loading'
import moment from 'moment'
import { useToast } from 'hooks/toast'

type TypesFormProps = {
  isOpenInModal: {
    handleOnClose: () => void
  }
  menu_food_id: number
  menuFood: IMenuFood
}

export const FormPdfItems = ({
  isOpenInModal,
  menu_food_id,
  menuFood
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const { activeLoading, disableLoading } = useLoading()
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()

  const onSubmitForm = () => {
    activeLoading()
    api
      .post(
        'commercial/menuFoods/itemsPdf',
        {
          menu_food_id,
          start_date: moment(startDate).format('YYYY-MM-DD'),
          end_date: moment(endDate).format('YYYY-MM-DD')
        },
        {
          responseType: 'arraybuffer'
        }
      )
      .then(res => {
        const a = document.createElement('a')
        const blob = new Blob([res.data], {
          type: 'application/pdf'
        })
        const url = window.URL.createObjectURL(blob)
        a.href = url
        const name = menuFood?.menu_food_type?.name
        const period = `${moment(startDate).format('DD_MM_YYYY')} -  ${moment(
          endDate
        ).format('DD_MM_YYYY')}}`

        const fileName = `Refeição - ${name} ${period}.pdf`
        a.download = fileName
        a.click()
        disableLoading()
        isOpenInModal.handleOnClose()
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: 'Itens não encontrados'
        })
        disableLoading()
        isOpenInModal.handleOnClose()
      })
  }
  return (
    <Form onSubmit={onSubmitForm} setReset>
      <div className="">
        <FormContainer className="form-body">
          <div className="row">
            <DatePicker
              label="Vigência inicial"
              className="col-md-6"
              name="start_validity"
              rules={{ required: true }}
              selected={startDate}
              onChange={date => setStartDate(date)}
              controlled
            />
            <DatePicker
              label="Vigência final"
              className="col-md-6"
              name="end_validity"
              rules={{ required: true }}
              selected={endDate}
              onChange={date => setEndDate(date)}
              controlled
            />
          </div>
        </FormContainer>
        <div className="card-footer d-flex justify-content-end py-6 px-9">
          <button type="submit" className="btn btn-primary">
            Baixar PDF
          </button>
        </div>
      </div>
    </Form>
  )
}
