import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'
import ContractList from 'pages/Commercial/ContractsMenuFoods/List'
import ContractListDaily from 'pages/Commercial/ContractsMenuFoods/ListDaily'
import ContractListTotal from 'pages/Commercial/ContractsMenuFoods/ListTotal'
import ListTotalItemsByMenuFood from 'pages/Commercial/ContractsMenuFoods/ListTotalItemsByMenuFood'
import ListRevenueByMenuFood from 'pages/Commercial/ContractsMenuFoods/ListRevenueByMenuFood'

export const ContractsMenuFoodsRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route
      path="/commercial/contractsMenuFoods"
      component={ContractList}
      exact
    />
    <Route
      path="/commercial/contractsMenuFoods/daily"
      component={ContractListDaily}
      exact
    />
    <Route
      path="/commercial/contractsMenuFoods/total"
      component={ContractListTotal}
      exact
    />
    <Route
      path="/commercial/contractsMenuFoods/totalItemsByMenuFood"
      component={ListTotalItemsByMenuFood}
      exact
    />
    <Route
      path="/commercial/contractsMenuFoods/revenueByMenuFood"
      component={ListRevenueByMenuFood}
      exact
    />
  </CustomSwitch>
)
