import ClientsList from '../../pages/Commercial/Clients/Register/List'
import ClientsUpdate from '../../pages/Commercial/Clients/Register/Update'
import ClientsCreate from '../../pages/Commercial/Clients/Register/Create'
import ClientsView from '../../pages/Commercial/Clients/Register/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const ClientsRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/commercial/clients" exact component={ClientsList} />
    <Route path="/commercial/clients/create" component={ClientsCreate} exact />
    <Route path="/commercial/clients/view/:id" component={ClientsView} exact />
    <Route
      path="/commercial/clients/update/:id"
      component={ClientsUpdate}
      exact
    />
  </CustomSwitch>
)
