import Form, { Input, Select, Textarea } from 'components/Form'
import { DateInput } from 'components/Form/date'
import { useToast } from 'hooks/toast'
import moment from 'moment'
import { useEffect, useState } from 'react'
import api from 'services/api'
import { convertValueMaskInNumberWithTwoZero } from 'utlis/mask'
import { addContractDiscount, updateContractDiscount } from '../../domain/api'

interface IAddDiscountForm {
  contract: {
    id: string
  }
  charge_type: string
  isOpenInModal: {
    handleOnClose: () => void
    contractDiscount: IContractDiscount
  }
}

interface IAddDiscountData {
  value: string
  due_date: Date
  unique: string
}

const AddDiscountForm = ({
  contract,
  isOpenInModal,
  charge_type
}: IAddDiscountForm) => {
  const { addToast } = useToast()
  const [dueDate, setDueDate] = useState<Date>()
  const [defaultValues, setDefaultValues] = useState<any>()

  const typeOptions = [
    {
      value: 'percentual',
      name: 'Percentual'
    },
    {
      value: 'valor',
      name: 'Valor'
    }
  ]

  useEffect(() => {
    if (isOpenInModal.contractDiscount?.id) {
      setDefaultValues({
        ...isOpenInModal.contractDiscount,
        unique: isOpenInModal.contractDiscount.unique ? 'true' : 'false'
      })
      setDueDate(
        moment(
          String(isOpenInModal.contractDiscount.due_date)
            .split('/')
            .reverse()
            .join('-')
        ).toDate()
      )
    }
  }, [isOpenInModal.contractDiscount])
  const onSubmitForm = async (data: IAddDiscountData) => {
    const dataToCreate = {
      ...data,
      contract_id: contract.id,
      value: convertValueMaskInNumberWithTwoZero(data.value),
      due_date: dueDate,
      charge_type: charge_type
    }
    const { handleOnClose, contractDiscount } = isOpenInModal
    const id = contractDiscount?.id
    if (id) {
      await api
        .put(updateContractDiscount(String(id)), dataToCreate)
        .then(() => {
          handleOnClose()
          addToast({
            type: 'success',
            title: 'Desconto atualizado com sucesso'
          })
        })
        .catch(err => {
          console.log(err.response.data)
          addToast({
            type: 'error',
            title: 'Error ao atualizar o desconto',
            description:
              'Houve um error ao atualizar o desconto, tente novamente mais tarde!'
          })
        })
    } else {
      await api
        .post(addContractDiscount(), dataToCreate)
        .then(() => {
          handleOnClose()
          addToast({
            type: 'success',
            title: 'Desconto adicionado com sucesso'
          })
        })
        .catch(err => {
          console.log(err.response.data)
          addToast({
            type: 'error',
            title: 'Error ao adicionar o desconto',
            description:
              'Houve um error ao adicionar o desconto, tente novamente mais tarde!'
          })
        })
    }
  }

  return (
    <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
      <div className="row">
        <Select
          name="type"
          className=" col-md-4"
          label="Tipo"
          rules={{ required: true }}
          options={typeOptions}
          blank
          defaultValue={''}
        />
        <Input
          name="value"
          label="Valor"
          price
          className="col-md-2"
          rules={{ required: true }}
        />
        <DateInput
          label="Validade"
          name="due_date"
          className=" col-md-4"
          onChange={date => setDueDate(date)}
          rules={{ required: true }}
          selected={dueDate}
          minDate={new Date()}
          controlled
        />
        <Select
          name="unique"
          className=" col-md-2"
          label="Único"
          options={[
            {
              name: 'SIM',
              value: 'true'
            },
            {
              name: 'NÃO',
              value: 'false'
            }
          ]}
          blank
          defaultValue={''}
        />
      </div>

      <Textarea
        name="description"
        className=" col-md-12"
        label="Descrição"
        style={{ minHeight: 120 }}
        rules={{ required: true }}
        defaultValue=""
      />
      <button type="submit" className="btn btn-primary float-end">
        Confirmar
      </button>
    </Form>
  )
}

export default AddDiscountForm
