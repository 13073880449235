import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'
import { useEffect, useState } from 'react'
import { DateInput } from 'components/Form/date'
import { TableHeader } from './styles'
import api from 'services/api'
import Container from 'components/Container'
import { FaDownload } from 'react-icons/fa'
import { useLoading } from 'hooks/loading'

import { MenuFoodTypesMultiSelect } from '../components/MenuFoodTypesMultiSelect'

export type ReportQuery = {
  id: number
  startDate: Date
  endDate: Date
  menuFoodTypesIds: number[]
}

const ListTotalItemsByMenuFood = (): JSX.Element => {
  const [menuFoodOptions, setMenuFoodOptions] = useState<IMenuFoodType[]>([])
  const { activeLoading, disableLoading } = useLoading()

  const [reportQuery, setReportQuery] = useState<ReportQuery[]>([])

  const loadMenuFoods = async () => {
    const { data } = await api.get('commercial/menuFoodTypes/findAllActive')
    setMenuFoodOptions(data)
    setReportQuery([
      {
        id: 1,
        startDate: new Date(),
        endDate: new Date(),
        menuFoodTypesIds: []
      }
    ])
  }

  useEffect(() => {
    loadMenuFoods()
  }, [])

  return (
    <Container
      pageTitle="Listagem"
      breadcrumb={breadcrumbList}
      tools={toolsList}
    >
      {reportQuery.map(report => {
        return (
          <div key={report.id}>
            <div className="col-md-12 d-flex">
              <div className="col-md-4 pe-8">
                <MenuFoodTypesMultiSelect
                  menuFoodTypesOptions={menuFoodOptions}
                  report={report}
                  reportQuery={reportQuery}
                  setReportQuery={setReportQuery}
                />
              </div>
              <div className="col-md-2 pe-8">
                <DateInput
                  label="Data inicial"
                  name="start_validity"
                  onChange={date => {
                    report.startDate = date
                    const copy = reportQuery.map(b => ({
                      ...b
                    }))
                    setReportQuery(copy)
                  }}
                  selected={report.startDate}
                  controlled
                />
              </div>
              <div className="col-md-2 pe-8">
                <DateInput
                  label="Data final"
                  name="end_validity"
                  onChange={date => {
                    report.endDate = date
                    const copy = reportQuery.map(b => ({
                      ...b
                    }))
                    setReportQuery(copy)
                  }}
                  selected={report.endDate}
                  controlled
                />
              </div>
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                disabled={reportQuery.length <= 1}
                style={{ height: '50px', alignSelf: 'center' }}
                onClick={() => {
                  if (reportQuery.length <= 1) {
                    return
                  }
                  const index = reportQuery.findIndex(a => a.id === report.id)
                  if (index !== -1) {
                    reportQuery.splice(index, 1)
                  }
                  const copy = reportQuery.map(b => ({
                    ...b
                  }))
                  setReportQuery(copy)
                }}
              >
                Remover
              </button>
            </div>
            <hr />
          </div>
        )
      })}
      <footer className="col-md-12 d-flex justify-content-end pb-5 gap-5 mt-4">
        <button
          type="button"
          className="btn btn-secondary btn-sm"
          onClick={() => {
            setReportQuery([
              ...reportQuery,
              {
                id: reportQuery[reportQuery.length - 1].id + 1,
                endDate: new Date(),
                startDate: new Date(),
                menuFoodTypesIds: []
              }
            ])
          }}
        >
          Adicionar
        </button>
      </footer>

      <TableHeader>
        <div>
          <button
            type="button"
            className="btn"
            onClick={async () => {
              activeLoading()
              api
                .post(
                  'commercial/contractsMenuFoods/totalItemsByMenuFoodPdf',
                  {
                    reportQuery
                  },
                  {
                    responseType: 'arraybuffer'
                  }
                )
                .then(res => {
                  const a = document.createElement('a')
                  const blob = new Blob([res.data], {
                    type: 'application/pdf'
                  })
                  const url = window.URL.createObjectURL(blob)
                  a.href = url
                  const date = new Date()
                  const today = `${date.getDate()}-${String(
                    date.getMonth() + 1
                  ).padStart(2, '0')}-${date.getFullYear()}`
                  const fileName = `Relatório de itens - ${today}.pdf`
                  a.download = fileName
                  a.click()
                  disableLoading()
                })
                .catch(() => {
                  disableLoading()
                })
            }}
          >
            <FaDownload />
            Baixar relatório
          </button>
        </div>
      </TableHeader>
    </Container>
  )
}
export default ListTotalItemsByMenuFood
