import { useCallback, useEffect, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Textarea } from '../../../../../components/Form'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { apiList, apiSendMessageByMenuFoods } from '../../domain/api'
import { Loading } from 'components/Loading'

type SendMessageByMenuFoodsProps = {
  id: number
  handleClickOnCloseModal: () => void
}

export const SendMessageByMenuFoods = ({
  id,
  handleClickOnCloseModal
}: SendMessageByMenuFoodsProps): JSX.Element => {
  const { addToast } = useToast()
  const { activeLoading, disableLoading } = useLoading()
  const [loading, setLoading] = useState(false)

  const [menuFoods, setMenuFoods] = useState<IMenuFood[]>([])
  const [menuFoodsIds, setMenuFoodsIds] = useState<number[]>([])

  const getMenuFoods = useCallback(async () => {
    const { data } = await api.get<IInstitutionData>(apiList(String(id)))
    const menuFoods = data.menu_institution
      .map(mi => mi.menu_food)
      .filter(mi => mi.is_active)
    setMenuFoods(menuFoods)
  }, [id])

  useEffect(() => {
    getMenuFoods()
  }, [getMenuFoods])

  const onSubmitForm = async (data: IClientData) => {
    if (!menuFoodsIds.length) {
      addToast({
        type: 'error',
        title: 'Por favor, selecione pelo menos um cardápio.'
      })
      return
    }
    activeLoading()
    setLoading(true)
    try {
      await api.post(apiSendMessageByMenuFoods(), {
        ...data,
        institution_id: Number(id),
        menuFoodsIds
      })
      handleClickOnCloseModal()
      disableLoading()
      setLoading(false)
      addToast({
        type: 'success',
        title: 'Mensagem enviada',
        description: 'Mensagem enviada com sucesso'
      })
    } catch (error: any) {
      const message = error?.response?.data?.message || 'Tente novamente'
      addToast({
        type: 'error',
        title: 'Erro ao enviar mensagem para o cliente',
        description: message
      })
      handleClickOnCloseModal()
      disableLoading()
      setLoading(false)
    }
  }

  const addMenuFoodId = (menuFoodId: number) => {
    setMenuFoodsIds(old => [...old, menuFoodId])
  }

  const removeMenuFoodId = (menuFoodIdToDelete: number) => {
    setMenuFoodsIds(old => {
      return old.filter(menuFoodId => menuFoodId !== menuFoodIdToDelete)
    })
  }
  if (!menuFoods.length) {
    return (
      <div>
        <Loading isActive />
      </div>
    )
  }
  return (
    <Form onSubmit={onSubmitForm}>
      <>
        <div className="row mb-6">
          <p className="mb-2">Selecione os cardápios: </p>
          {menuFoods?.map(menuFood => (
            <div key={menuFood.id}>
              <input
                type="checkbox"
                name={`menu_foods_ids${menuFood.id}`}
                id={`menu_foods_ids${menuFood.id}`}
                value={menuFood.id}
                onChange={e =>
                  e.target.checked
                    ? addMenuFoodId(Number(e.target.value))
                    : removeMenuFoodId(Number(e.target.value))
                }
              />
              <label htmlFor={`menu_foods_ids${menuFood.id}`} className="mx-2">
                {menuFood.menu_food_type?.name}
              </label>
            </div>
          ))}
        </div>
        <div className="row mb-5">
          <Textarea
            name="message"
            className=" col-md-12"
            label="Mensagem"
            rows={3}
            rules={{ required: true }}
          />
        </div>
        <div className="card-footer d-flex justify-content-end py-6 px-9">
          <button type="submit" className="btn btn-primary" disabled={loading}>
            Enviar
          </button>
        </div>
      </>
    </Form>
  )
}
