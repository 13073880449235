import { useState } from 'react'
import DataTable from '../../../../components/DataTable'
import { nameEntity, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'
import Container from '../../../../components/Container'
import { useToast } from 'hooks/toast'
import { FaWhatsapp } from 'react-icons/fa'
import Modal from 'components/Modal'
import { SendMessageByMenuFoods } from '../components/SendMessageByMenuFoodsForm'

const InstitutionList = (): JSX.Element => {
  const { addToast } = useToast()
  const [modalMessage, setModalMessage] = useState<{
    open: boolean
    id: number
    name?: string
  }>()

  const handleClickOnCloseModal = (): void => {
    setModalMessage({
      id: 0,
      open: false
    })
  }

  return (
    <Container
      pageTitle="Listagem"
      breadcrumb={breadcrumbList}
      tools={toolsList}
    >
      <DataTable
        source={nameSource}
        entity={nameEntity}
        format={{ orderBy: 'company_social_name' }}
        headers={headers}
        actions={[
          {
            name: 'Visualizar',
            title: 'Visualizar',
            spanIcon: 'fa fa-search',
            linkTo: institutition =>
              `/commercial/institutions/view/${institutition.id}`,
            style: { margin: '0 5px' }
          },
          {
            name: 'Editar',
            title: 'Editar',
            spanIcon: 'fa fa-edit',
            linkTo: institution =>
              `/commercial/institutions/update/${institution.id}`
          },
          {
            name: 'Copiar link',
            title: 'Copiar',
            style: { margin: '0 5px' },
            spanIcon: 'fa fa-clipboard',
            onClick: institution => {
              console.log(process.env.REACT_APP_CLIENT_URL)
              navigator.clipboard.writeText(
                `https://contratacao.merenderia.com.br/contract/${institution.token}`
              )
              addToast({
                type: 'success',
                title: 'Link da instituição copiado com sucesso!'
              })
            }
          },
          {
            name: 'Enviar mensagem',
            title: 'Enviar mensagem',
            htmlIcon: (
              <div style={{ marginLeft: '5px' }}>
                <FaWhatsapp size={15} />
              </div>
            ),
            onClick: institution => {
              setModalMessage({
                open: true,
                id: Number(institution.id),
                name: institution.company_social_name
              })
            }
          }
        ]}
      />
      {modalMessage && (
        <Modal
          pageTitle={`Enviar mensagem para ${modalMessage.name}`}
          onClickButtonCancel={handleClickOnCloseModal}
          isOpenModal={modalMessage.open}
          Children={
            <SendMessageByMenuFoods
              id={modalMessage.id}
              handleClickOnCloseModal={handleClickOnCloseModal}
            />
          }
        />
      )}
    </Container>
  )
}

export default InstitutionList
