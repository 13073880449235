import { useCallback, useEffect, useState } from 'react'
import Form, { Select } from 'components/Form'
import { DateInput } from 'components/Form/date'
import { useToast } from 'hooks/toast'
import api from 'services/api'
import { ChangeDueDateFinancial } from '../../View'
import { useLoading } from 'hooks/loading'
import { Loading } from 'components/Loading'

interface IDownloadFinancialMovimentManual {
  isOpenInModal: {
    handleOnClose: () => void
  }
  financialMoviment: {
    financialMoviment: ChangeDueDateFinancial
    contract: any
  }
}

interface IDownloadFinancialMovimentManualData {
  due_date: string
  payment_gateway_id?: string
}

const DownloadFinancialMovimentManual = ({
  isOpenInModal,
  financialMoviment
}: IDownloadFinancialMovimentManual) => {
  const { addToast } = useToast()
  const [loading, setIsloading] = useState(false)
  const [dueDate, setDueDate] = useState<Date>()
  const [paymentGateways, setPaymentGateways] = useState([])

  const loadPaymentGateways = useCallback(async () => {
    setIsloading(true)
    try {
      const paymentGatewaysResponse = await api.get(
        '/financial/paymentGateways'
      )
      const optionsPaymentGateways = paymentGatewaysResponse.data
        .filter((paymentGateway: { id: number }) => {
          if (
            paymentGateway.id ===
            financialMoviment.financialMoviment.payment_gateway_id
          ) {
            return false
          }
          return true
        })
        .map(
          (filterPaymentGateway: {
            id: number
            paymentModule: { name: string }
          }) =>
            filterPaymentGateway && {
              ...filterPaymentGateway,
              name: filterPaymentGateway.paymentModule.name,
              value: filterPaymentGateway.id
            }
        )
      setPaymentGateways(optionsPaymentGateways)
      setIsloading(false)
    } catch (error) {
      addToast({
        title: 'Erro ao carregar as formas de pagamento',
        description: 'Ocorreu um erro ao carregar as formas de pagamento',
        type: 'error'
      })
      setIsloading(false)
    }
  }, [addToast, financialMoviment?.financialMoviment?.payment_gateway_id])

  useEffect(() => {
    loadPaymentGateways()
  }, [loadPaymentGateways])

  const onSubmitForm = async (data: IDownloadFinancialMovimentManualData) => {
    const paymentMethod = paymentGateways.find(
      paymentGateway =>
        Number(paymentGateway.value) === Number(data.payment_gateway_id)
    )
    try {
      await api.put(
        `/financial/financialMoviments/downloadManual/${financialMoviment?.financialMoviment?.id}`,
        {
          ...data,
          payment_method: paymentMethod?.paymentModule?.name
            .split('')?.[0]
            ?.toUpperCase()
        }
      )
      isOpenInModal?.handleOnClose?.()
      addToast({
        type: 'success',
        title: 'Boleto emitido com sucesso'
      })
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Error ao emitir o boleto',
        description:
          'Houve um error ao emitir o boleto, tente novamente mais tarde!'
      })
    }
  }

  return (
    <>
      <Loading isActive={loading} />
      <Form onSubmit={onSubmitForm}>
        <div>
          <div className="row">
            <Select
              name="payment_gateway_id"
              className="col-6"
              label="Forma de Pagamento"
              rules={{ required: true }}
              options={paymentGateways}
              blank
              defaultValue=""
            />
            <DateInput
              label="Data de Liquidação"
              name="settlement_date"
              className="col-6"
              onChange={date => setDueDate(date)}
              selected={dueDate}
              controlled
            />
          </div>
          <button type="submit" className="btn btn-primary float-end">
            Confirmar
          </button>
        </div>
      </Form>
    </>
  )
}

export default DownloadFinancialMovimentManual
