import Form, { Select, Textarea } from 'components/Form'
import { DateInput } from 'components/Form/date'

import { useToast } from 'hooks/toast'
import api from 'services/api'
import { cancelContract } from '../../domain/api'
import { useState } from 'react'
import moment from 'moment'

interface ICancelContractForm {
  contract: {
    id: string
  }
  isOpenInModal: {
    handleOnClose: () => void
  }
  setContract: React.Dispatch<React.SetStateAction<IContractData>>
}

interface ICancelContractData {
  cancellation_reason: string
  cancellation_description?: string
}

const CancelContractForm = ({
  contract,
  isOpenInModal,
  setContract
}: ICancelContractForm) => {
  const { addToast } = useToast()
  const [type, setType] = useState('now')
  const [scheduleDate, setScheduleDate] = useState<Date>()

  const reasonsOptions = [
    {
      value: 'INDISPONIBILIDADE',
      name: 'Indisponibilidade de atendimento'
    },
    {
      value: 'APEDIDODOCLIENTE',
      name: 'A pedido do cliente'
    },
    {
      value: 'OUTROS',
      name: 'Outros'
    }
  ]
  const onSubmitForm = (data: ICancelContractData) => {
    let dataToCancel
    if (data.cancellation_description === '') {
      dataToCancel = {
        cancellation_reason: data.cancellation_reason
      }
    } else {
      dataToCancel = {
        ...data,
        date: scheduleDate
          ? moment(scheduleDate).format('YYYY-MM-DD')
          : undefined
      }
    }
    console.log(dataToCancel)
    ;(async () => {
      await api
        .put(cancelContract(contract.id), dataToCancel)
        .then(res => {
          const { handleOnClose } = isOpenInModal
          handleOnClose()
          addToast({
            type: 'success',
            title: 'Contrato cancelado com sucesso'
          })
        })
        .catch(err => {
          console.log(err.response?.data)
          addToast({
            type: 'error',
            title: 'Error ao cancelar o contrato',
            description:
              'Houve um error ao cancelar o contrato, tente novamente mais tarde!'
          })
        })
    })()
  }

  return (
    <Form onSubmit={onSubmitForm}>
      <Select
        name="type"
        className=" col-md-6"
        label="Tipo do cancelamento"
        rules={{ required: true }}
        options={[
          {
            name: 'Imediato',
            value: 'now'
          },
          {
            name: 'Programado',
            value: 'scheduled'
          }
        ]}
        value={type}
        onChange={e => {
          setType(e.target.value)
          if (e.target.value === 'scheduled') {
            const endOfMonth = moment().endOf('M').format('YYYY-MM-DD')
            const today = moment().format('YYYY-MM-DD')
            if (endOfMonth !== today) {
              setScheduleDate(moment().endOf('M').add(1, 'd').toDate())
            } else {
              setScheduleDate(moment().add(1, 'd').endOf('M').toDate())
            }
          }
        }}
        blank
        defaultValue={'now'}
      />

      {type === 'scheduled' && (
        <DateInput
          label="Data de cancelamento"
          name="date"
          className="col-6"
          onChange={date => setScheduleDate(date)}
          selected={scheduleDate}
          minDate={moment().add(1, 'd').toDate()}
          controlled
        />
      )}
      <Select
        name="cancellation_reason"
        className=" col-md-12"
        label="Motivo de cancelamento"
        rules={{ required: true }}
        options={reasonsOptions}
        blank
      />
      <Textarea
        name="cancellation_description"
        className=" col-md-12"
        label="Descrição"
        style={{ minHeight: 120 }}
        defaultValue=""
      />
      <button type="submit" className="btn btn-primary float-end">
        Confirmar
      </button>
    </Form>
  )
}

export default CancelContractForm
