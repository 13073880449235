export const headers = [
  { name: 'Nome', field: 'name', sortable: true },
  { name: 'CPF', field: 'cpf', sortable: false },
  { name: 'Telefone', field: 'phone', sortable: false },
  { name: 'E-mail', field: 'email', sortable: false },
  {
    name: 'Ativo',
    field: 'is_active',
    sortable: false,
    hasTypes: {
      types: {
        true: 'Sim',
        false: 'Não'
      }
    }
  },
  { name: 'Cadastrado em', field: 'created_at', sortable: false },
  { name: 'Ações', field: 'actions', sortable: false }
]
