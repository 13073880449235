import DataTable from '../../../../../components/DataTable'
import { nameEntity, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'
import Container from '../../../../../components/Container'
import { useState } from 'react'
import { FaWhatsapp } from 'react-icons/fa'
import Modal from 'components/Modal'
import { SendMessage } from '../components/SendMessageForm'

const ClientList = (): JSX.Element => {
  const [modalMessage, setModalMessage] = useState<{
    open: boolean
    id: number
    name?: string
  }>()

  const handleClickOnCloseModal = (): void => {
    setModalMessage({
      id: 0,
      open: false
    })
  }

  return (
    <Container
      pageTitle="Listagem"
      breadcrumb={breadcrumbList}
      tools={toolsList}
    >
      <DataTable
        source={nameSource}
        entity={nameEntity}
        format={{ orderBy: 'name' }}
        notHasChildren
        headers={headers}
        actions={[
          {
            name: 'Visualizar',
            title: 'Visualizar',
            spanIcon: 'fa fa-search',
            linkTo: order => `/commercial/clients/view/${order.id}`,
            style: { margin: '0 5px' }
          },
          {
            name: 'Editar',
            title: 'Editar',
            spanIcon: 'fa fa-edit',
            linkTo: order => `/commercial/clients/update/${order.id}`
          },
          {
            name: 'Enviar mensagem',
            title: 'Enviar mensagem',
            htmlIcon: (
              <div style={{ marginLeft: '5px' }}>
                <FaWhatsapp size={15} />
              </div>
            ),
            onClick: order => {
              setModalMessage({
                open: true,
                id: Number(order.id),
                name: order.name
              })
            }
          }
        ]}
      />
      {modalMessage && (
        <Modal
          pageTitle={`Enviar mensagem para ${modalMessage.name}`}
          onClickButtonCancel={handleClickOnCloseModal}
          isOpenModal={modalMessage.open}
          Children={
            <SendMessage
              id={modalMessage.id}
              handleClickOnCloseModal={handleClickOnCloseModal}
            />
          }
        />
      )}
    </Container>
  )
}

export default ClientList
