import RenewPaymentsList from '../../pages/Financial/RenewPayments/List'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const RenewPaymentsRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route
      path="/financial/renewPayments"
      exact
      component={RenewPaymentsList}
    />
  </CustomSwitch>
)
