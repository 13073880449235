import { ToolsContainerProps } from '../../../../../../components/Container'
import { nameActions } from '../../info'

export const toolsPrintPdf = (
  id: string,
  handleOnClick: any
): ToolsContainerProps => ({
  name: 'Imprimir',
  to: `/commercial/contracts/view/${id}`,
  icon: nameActions.print.icon,
  handleOnClick: handleOnClick
})

export const cancelContract = (
  id: string,
  handleOnClick: any
): ToolsContainerProps => ({
  name: 'Cancelar',
  to: `/commercial/contracts/view/${id}`,
  icon: 'fa fa-remove',
  color: 'btn-light-danger',
  handleOnClick: handleOnClick
})
