import DataTable from '../../../../../components/DataTable'
import { INGREDIENT_TYPES_MAP, nameEntity, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'
import Container from '../../../../../components/Container'

const IngredientsList = (): JSX.Element => (
  <Container pageTitle="Listagem" breadcrumb={breadcrumbList} tools={toolsList}>
    <DataTable
      source={nameSource}
      entity={nameEntity}
      format={{ orderBy: 'name' }}
      notHasChildren
      headers={headers}
      customHeaders={[
        {
          field: 'unit_measure',
          name: 'Unidade de medida',
          sortable: true,
          element: (item: IIngredient) => (
            <span>{INGREDIENT_TYPES_MAP[item.unit_measure]}</span>
          )
        }
      ]}
      actions={[
        {
          name: 'Visualizar',
          title: 'Visualizar',
          spanIcon: 'fa fa-search',
          linkTo: ingredient => `/commercial/ingredients/view/${ingredient.id}`,
          style: { margin: '0 5px' }
        },
        {
          name: 'Editar',
          title: 'Editar',
          spanIcon: 'fa fa-edit',
          linkTo: ingredient =>
            `/commercial/ingredients/update/${ingredient.id}`
        }
      ]}
    />
  </Container>
)

export default IngredientsList
