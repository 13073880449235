import { useEffect, useState, useCallback } from 'react'
import DataTable from '../../../../components/DataTable'
import { nameEntity, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import Container from '../../../../components/Container'
import { useToast } from 'hooks/toast'
import { useLoading } from 'hooks/loading'
import Form, { Select } from '../../../../components/Form'
import { FilterContainer } from './styles'
import { DateInput } from 'components/Form/date'
import { useHistory, useLocation } from 'react-router-dom'
import api from 'services/api'
import { apiListInstitution } from 'pages/Commercial/Contracts/domain/api'
import moment from 'moment'
import { useUpdateDataTable } from 'hooks/dataTable'
import { FaDownload } from 'react-icons/fa'

const InstitutionList = (): JSX.Element => {
  const [startDate, setStartDate] = useState<Date>()
  const [finalDate, setFinalDate] = useState<Date>()
  const [situation, setSituation] = useState<string>()
  const [overdue, setOverdue] = useState<string>()
  const [open, setOpen] = useState<string>()
  const [contractSituation, setContractSituation] = useState<string>()
  const [institutionOptions, setInstitutionOptions] = useState<
    IInstitutionData[]
  >([])
  const [institution, setInstitution] = useState<string>()
  const [dateColumn, setDateColumn] = useState<string>()
  const [parameters, setParameters] = useState<any>()

  const { addToast } = useToast()
  const { activeLoading, disableLoading } = useLoading()
  const { updateDataTable } = useUpdateDataTable()

  const history = useHistory()

  useEffect(() => {
    api
      .get(apiListInstitution())
      .then(res => {
        const orderedData = res.data.sort(
          (a: IInstitutionData, b: IInstitutionData) =>
            a.company_social_name.localeCompare(b.company_social_name)
        )
        setInstitutionOptions(orderedData)
      })
      .catch(e => {
        addToast({
          type: 'error',
          title: 'Erro ao carregar as instituições',
          description:
            'Houve um erro ao carregar as instituições disponíveis no banco de dados.'
        })
      })
  }, [])

  const onSearch = async () => {
    const query = []
    if (dateColumn) {
      query.push(`date_column=${dateColumn}`)
    }
    if (startDate) {
      query.push(`start_date=${moment(startDate).format().substring(0, 10)}`)
    }
    if (finalDate) {
      query.push(`end_date=${moment(finalDate).format().substring(0, 10)}`)
    }
    if (situation) {
      query.push(`situation=${situation}`)
    }
    if (overdue) {
      query.push(`overdue=${overdue}`)
    }
    if (open) {
      query.push(`open=${open}`)
    }
    if (contractSituation) {
      query.push(`contract_situation=${contractSituation}`)
    }

    if (institution) {
      query.push(`institution_id=${institution}`)
    }

    history.replace(`/financial/financialMoviments?${query.join('&')}`)
    if (
      startDate ||
      finalDate ||
      situation ||
      overdue ||
      open ||
      institution ||
      contractSituation
    ) {
      setParameters([
        {
          date_column: dateColumn || undefined,
          start_date: startDate ? moment(startDate).toDate() : undefined,
          end_date: finalDate ? moment(finalDate).toDate() : undefined,
          institution_id: institution || undefined,
          situation: situation || undefined,
          overdue: overdue || undefined,
          open: open || undefined,
          contract_situation: contractSituation || undefined
        }
      ])
    } else {
      setParameters([])
    }
  }

  const location = new URLSearchParams(useLocation().search)

  useEffect(() => {
    const dateColumn = location.get('date_column')
    const startDate = location.get('start_date')
    const finalDate = location.get('end_date')
    const institution = location.get('institution_id')
    const situation = location.get('situation')
    const contractSituation = location.get('contract_situation')
    const overdue = location.get('overdue')
    const open = location.get('open')
    const today = location.get('today')
    const month = location.get('month')

    if (
      startDate ||
      finalDate ||
      institution ||
      situation ||
      overdue ||
      open ||
      today ||
      month ||
      contractSituation
    ) {
      if (dateColumn) {
        setDateColumn(dateColumn)
      }
      if (startDate) {
        setStartDate(moment(`${startDate} 00:00:00`).toDate())
      }
      if (finalDate) {
        setFinalDate(moment(`${finalDate} 00:00:00`).toDate())
      }
      if (situation) {
        setSituation(situation)
      }
      if (overdue) {
        setOverdue(overdue)
      }
      if (open) {
        setOpen(open)
      }
      if (contractSituation) {
        setContractSituation(contractSituation)
      }
      if (institution) {
        setInstitution(institution)
      }
      setParameters([
        {
          date_column: dateColumn || undefined,
          start_date: startDate ? moment(startDate).toDate() : undefined,
          end_date: finalDate ? moment(finalDate).toDate() : undefined,
          institution_id: institution || undefined,
          situation: situation || undefined,
          overdue: overdue || undefined,
          open: open || undefined,
          today: today || undefined,
          month: month || undefined,
          contract_situation: contractSituation || undefined
        }
      ])
    } else {
      setParameters([])
    }
  }, [])
  const handleSendBankSlip = useCallback(
    async ({ financial_moviment_id, status }) => {
      if (status === 'pago') return
      activeLoading()
      try {
        await api.put(
          `/financial/financialMoviments/sendBankSlip/${financial_moviment_id}`
        )
        disableLoading()
        addToast({
          type: 'success',
          title: 'Boleto enviado com sucesso',
          description: 'Boleto enviado com sucesso para cliente!'
        })
      } catch (error) {
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao enviar o boleto',
          description:
            'Houve um error ao enviar o boleto, tente novamente mais tarde!'
        })
      }
      updateDataTable()
    },
    [activeLoading, addToast, disableLoading, updateDataTable]
  )

  return (
    <Container pageTitle="Listagem" breadcrumb={breadcrumbList} tools={[]}>
      <FilterContainer>
        <Form onSubmit={onSearch} defaultValues={{}} className="col-md-12">
          <div className="col-md-12 d-flex">
            <div className="col-md-3 pe-8">
              <Select
                label="Instituição"
                name="institution_id"
                options={institutionOptions?.map(institution => {
                  return {
                    name: institution.company_social_name,
                    value: institution.id
                  }
                })}
                onChange={e => setInstitution(e.target.value)}
                value={institution}
                blank
                controlled
                defaultValue={''}
              />
            </div>
            <div className="col-md-3 pe-8">
              <Select
                label="Pesquisar por"
                name="form_payament"
                options={[
                  { name: 'Vencimento', value: 'due_date' },
                  { name: 'Liquidação', value: 'settlement_date' },
                  { name: 'Criação', value: 'created_at' }
                ]}
                onChange={e => setDateColumn(e.target.value)}
                value={dateColumn}
                blank
                controlled
                defaultValue={''}
              />
            </div>
            <div className="col-md-2 pe-8">
              <DateInput
                label="Data inicial"
                name="start_date"
                onChange={date => setStartDate(date)}
                selected={startDate}
                disabled={!dateColumn}
                controlled
              />
            </div>
            <div className="col-md-2 pe-8">
              <DateInput
                label="Data final"
                name="end_date"
                onChange={date => setFinalDate(date)}
                selected={finalDate}
                disabled={!dateColumn}
                controlled
              />
            </div>
            <div className="col-md-2">
              <Select
                label="Situação"
                name="situation"
                options={[
                  { name: 'Pago', value: 1 },
                  { name: 'Aguardando Pagamento', value: 0 }
                ]}
                onChange={e => setSituation(e.target.value)}
                value={situation}
                controlled
                blank
                defaultValue={''}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-2">
              <Select
                label="Situação do contrato"
                name="contract_situation"
                options={[
                  { name: 'Ativo', value: 'ativo' },
                  {
                    name: 'Aguardando pagamento',
                    value: 'aguardando-pagamento'
                  },
                  { name: 'Cancelado', value: 'cancelado' }
                ]}
                onChange={e => setContractSituation(e.target.value)}
                value={contractSituation}
                controlled
                blank
                defaultValue={''}
              />
            </div>
          </div>
          <footer className="col-md-12 d-flex justify-content-end pb-5 gap-5">
            <button
              type="button"
              className="btn"
              disabled={!startDate || !finalDate}
              onClick={async () => {
                activeLoading()
                api
                  .post(
                    'financial/financialMoviments/financialReport',
                    {
                      reportQuery: {
                        date_column: dateColumn || undefined,
                        start_date: startDate
                          ? moment(startDate).toDate()
                          : undefined,
                        end_date: finalDate
                          ? moment(finalDate).toDate()
                          : undefined,
                        institution_id: institution || undefined,
                        situation: situation || undefined
                      }
                    },
                    {
                      responseType: 'arraybuffer'
                    }
                  )
                  .then(res => {
                    const a = document.createElement('a')
                    const blob = new Blob([res.data], {
                      type: 'application/pdf'
                    })
                    const url = window.URL.createObjectURL(blob)
                    a.href = url
                    const date = new Date()
                    const today = `${date.getDate()}-${String(
                      date.getMonth() + 1
                    ).padStart(2, '0')}-${date.getFullYear()}`
                    const fileName = `Relatório financeiro - ${today}.pdf`
                    a.download = fileName
                    a.click()
                    disableLoading()
                  })
                  .catch(() => {
                    disableLoading()
                  })
              }}
            >
              <FaDownload />
              Baixar relatório
            </button>

            <button
              type="reset"
              className="btn btn-secondary btn-sm"
              onClick={() => {
                setStartDate(undefined)
                setFinalDate(undefined)
                setSituation(undefined)
                setInstitution(undefined)
                setDateColumn(undefined)
                setContractSituation(undefined)
              }}
            >
              LIMPAR
            </button>
            <button type="submit" className="btn btn-primary btn-sm">
              BUSCAR
            </button>
          </footer>
        </Form>
        <hr />
      </FilterContainer>

      {parameters && (
        <DataTable
          source={nameSource}
          entity={nameEntity}
          format={{ orderBy: 'due_date' }}
          orderBySort="DESC"
          headers={headers}
          customHeaders={[
            {
              name: 'Contrato',
              field: 'contract.situation',
              sortable: false,
              element: item =>
                item.contract.situation === 'Aguardando pagamento'
                  ? 'Aguardando'
                  : item.contract.situation
            },
            {
              name: 'Liquidação',
              field: 'settlement_date',
              sortable: true,
              element: item =>
                item.settlement_date ? (
                  <span>
                    {moment(item.settlement_date, 'DD/MM/YYYY HH:mm:ss')
                      .add(3, 'h')
                      .format('DD/MM/YYYY')}
                  </span>
                ) : (
                  <span></span>
                )
            }
          ]}
          actions={[
            {
              title: 'Enviar Email',
              name: 'Enviar Email',
              onClick: item => {
                console.log(item)
                handleSendBankSlip({
                  financial_moviment_id: item.id,
                  status: item.status
                })
              },
              spanIcon: 'fa fa-envelope'
            },
            {
              title: 'Ver contrato',
              name: 'Ver contrato',
              linkTo: item => `/commercial/contracts/view/${item.contract_id}`,
              spanIcon: 'fa-solid fa-file-contract',
              style: { marginLeft: '10px' }
            }
          ]}
          searchParameters={parameters}
        />
      )}
    </Container>
  )
}

export default InstitutionList
