import { useState } from 'react'
import api from '../../../../../../services/api'
import Form, { Textarea } from '../../../../../../components/Form'
import { useToast } from '../../../../../../hooks/toast'
import { useLoading } from '../../../../../../hooks/loading'
import { apiSendMessage } from '../../domain/api'

type SendMessageProps = {
  id: number
  handleClickOnCloseModal: () => void
}

export const SendMessage = ({
  id,
  handleClickOnCloseModal
}: SendMessageProps): JSX.Element => {
  const { addToast } = useToast()
  const { activeLoading, disableLoading } = useLoading()
  const [loading, setLoading] = useState(false)

  const onSubmitForm = async (data: IClientData) => {
    activeLoading()
    setLoading(true)
    try {
      await api.post(apiSendMessage(), { ...data, id })
      handleClickOnCloseModal()
      disableLoading()
      setLoading(false)
      addToast({
        type: 'success',
        title: 'Mensagem enviada',
        description: 'Mensagem enviada com sucesso'
      })
    } catch (error: any) {
      addToast({
        type: 'error',
        title: 'Erro ao enviar mensagem para o cliente',
        description: 'Tente novamente'
      })
      handleClickOnCloseModal()
      disableLoading()
      setLoading(false)
    }
  }

  return (
    <Form onSubmit={onSubmitForm}>
      <>
        <div className="row mb-5">
          <Textarea
            name="message"
            className=" col-md-12"
            label="Mensagem"
            rows={3}
            rules={{ required: true }}
          />
        </div>
        <div className="card-footer d-flex justify-content-end py-6 px-9">
          <button type="submit" className="btn btn-primary" disabled={loading}>
            Enviar
          </button>
        </div>
      </>
    </Form>
  )
}
