import { DateInput } from 'components/Form/date'
import { useToast } from 'hooks/toast'
import api from 'services/api'
import {
  apiListMenuFoodDishesModal,
  apiListMenuFoodTypes,
  scheduleCreatePlan,
  scheduleUpdatePlan
} from '../../domain/api'
import { useEffect, useState } from 'react'
import { ButtonCircle, Card } from './styles'
import { BsCheckCircle, BsCircle } from 'react-icons/bs'
import { currencyMask } from 'utlis/mask'
import { FaEdit } from 'react-icons/fa'
import { AxiosResponse } from 'axios'
import Modal from 'components/Modal'
import ChooseDaysFrequencyForm from './ChooseDaysFrequencyForm'
import moment from 'moment'

interface IUpdatePlanForm {
  typeform: 'create' | 'update'
  contract: {
    id: string
    institution_id: number
    contractPlan?: IContractPlans
    plans: IContractPlans[]
    student: IStudent
  }
  isOpenInModal: {
    handleOnClose: () => void
  }
}

const UpdatePlanForm = ({
  contract,
  isOpenInModal,
  typeform
}: IUpdatePlanForm) => {
  const { addToast } = useToast()

  const [scheduleDate, setScheduleDate] = useState<Date>(
    moment().add(1, 'd').toDate()
  )

  const [oldPlansFrequency, setOldPlansFrequency] =
    useState<ISelectedPlanFrequency>(undefined)
  const [oldFrequencyDaysOfWeek, setOldFrequencyDaysOfWeek] = useState<
    string[]
  >([])

  const [plansFrequency, setPlansFrequency] =
    useState<IPlanFrequency[]>(undefined)
  const [selectedPlanFrequency, setSelectedPlanFrequency] =
    useState<ISelectedPlanFrequency>(undefined)
  const [selectedMenuFoodTypeId, setSelectedMenuFoodTypeId] = useState<string>()

  const [menuFood, setMenuFood] = useState<IMenuFood>()

  const [frequencyDaysOfWeek, setFrequencyDaysOfWeek] = useState<string[]>([])
  const [defaultValuesDaysOfWeek, setDefaultValuesDaysOfWeek] = useState<
    string[]
  >([])
  const [optionsMenuFoodType, setOptionsMenuFoodTypeData] = useState<
    { value: string; name: string }[]
  >([])

  const [isOpenModalFrequency, setIsOpenModalFrequency] =
    useState<boolean>(false)

  const onSubmitForm = async () => {
    if (typeform === 'create') {
      if (!selectedPlanFrequency || !menuFood) {
        return
      }
      try {
        await api.post(scheduleCreatePlan(contract.id), {
          date: moment(scheduleDate).format('YYYY-MM-DD'),
          modifications: {
            selectedPlanFrequency,
            frequencyDaysOfWeek
          }
        })
        addToast({
          type: 'success',
          title: 'Contrato atualizado com sucesso'
        })
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Error ao atualizar o contrato',
          description:
            'Houve um error ao atualizar o contrato, tente novamente mais tarde!'
        })
      }
    } else {
      try {
        if (!selectedPlanFrequency) {
          return
        }
        if (
          selectedPlanFrequency.id === oldPlansFrequency.id &&
          frequencyDaysOfWeek.sort().toString() ===
            oldFrequencyDaysOfWeek.sort().toString()
        ) {
          return
        }
        await api.put(scheduleUpdatePlan(contract.id), {
          contract_plan_id: contract.contractPlan.id,
          date: moment(scheduleDate).format('YYYY-MM-DD'),
          modifications: {
            selectedPlanFrequency,
            frequencyDaysOfWeek
          }
        })
        addToast({
          type: 'success',
          title: 'Contrato atualizado com sucesso'
        })
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Error ao atualizar o contrato',
          description:
            'Houve um error ao atualizar o contrato, tente novamente mais tarde!'
        })
      }
    }

    isOpenInModal.handleOnClose()
  }

  const getItemsMenuFood = (
    institution_id: string,
    menu_food_type_id: string
  ) => {
    api
      .get(apiListMenuFoodDishesModal(institution_id, menu_food_type_id))
      .then((res: AxiosResponse<IMenuFoodData[]>) => {
        const { data } = res
        setPlansFrequency(data?.[0]?.plans_frequency)
        setMenuFood(data?.[0])
      })
  }

  useEffect(() => {
    if (contract.contractPlan) {
      const { id, frequency, payament_form } =
        contract.contractPlan.plan_frequency
      const { periodicity, value } = JSON.parse(payament_form) as {
        periodicity: TPeriodicity
        value: string
      }
      setSelectedPlanFrequency({
        id,
        frequency,
        value,
        periodicity
      })
      setFrequencyDaysOfWeek(
        JSON.parse(contract.contractPlan.frequency_days_of_week)
          ?.frequency_days_of_week
      )

      setOldPlansFrequency({
        id,
        frequency,
        value,
        periodicity
      })
      setOldFrequencyDaysOfWeek(
        JSON.parse(contract.contractPlan.frequency_days_of_week)
          ?.frequency_days_of_week
      )

      getItemsMenuFood(
        String(contract?.institution_id),
        String(contract.contractPlan.plan_frequency.menu_food.menu_food_type_id)
      )
    }
  }, [contract.institution_id, contract])

  useEffect(() => {
    const menuFoodTypesByStudent: number[] = []
    contract.plans?.forEach(plan => {
      menuFoodTypesByStudent.push(
        plan?.plan_frequency?.menu_food?.menu_food_type_id
      )
    })
    api
      .get(
        apiListMenuFoodTypes(
          contract.student.school_period,
          contract.student.institution_id
        )
      )
      .then((res: AxiosResponse<IMenuFoodType[]>) => {
        const { data } = res
        const options = data
          .filter(
            ({ is_active, id }) =>
              is_active && !menuFoodTypesByStudent.includes(id)
          )
          .map(({ id, name }) => {
            return {
              value: id.toString(),
              name: name
            }
          })
          .sort((a, b) => {
            return a.name.localeCompare(b.name)
          })
        setOptionsMenuFoodTypeData(options)
      })
  }, [
    contract.plans,
    contract.student.institution_id,
    contract.student.school_period
  ])

  const handleClickOnConfirmModalFrequency = () => {
    setDefaultValuesDaysOfWeek([])
    setIsOpenModalFrequency(false)
  }

  const handleClickOnCloseModalFrequency = () => {
    if (!defaultValuesDaysOfWeek.length) {
      setSelectedPlanFrequency(undefined)
    }
    setFrequencyDaysOfWeek(defaultValuesDaysOfWeek)
    setIsOpenModalFrequency(false)
  }

  return (
    <div style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
      <DateInput
        label="Data da atualização"
        name="date"
        className="col-6"
        onChange={date => setScheduleDate(date)}
        selected={scheduleDate}
        minDate={moment().add(1, 'd').toDate()}
        controlled
      />

      <div className="row">
        <div>
          <div>
            <h3 className="col-form-label fw-bold fs-6 ">Tipo de refeição</h3>
          </div>
        </div>

        {typeform === 'update' && (
          <h3>
            {contract.contractPlan.plan_frequency.menu_food.menu_food_type.name}
          </h3>
        )}

        {typeform === 'create' &&
          optionsMenuFoodType
            .filter(omf => {
              const optionsSelected = contract.plans?.map(
                cp => cp.plan_frequency.menu_food.menu_food_type.name
              )
              return !optionsSelected.includes(omf.name)
            })
            .map(optionsMenuFoodType => (
              <div
                className="col-xl-3 col-sm-4 mb-5"
                key={optionsMenuFoodType.value}
              >
                <Card
                  isSelected={
                    optionsMenuFoodType.value === selectedMenuFoodTypeId
                  }
                  className="d-flex align-items-center flex-column gap-4"
                  height={80}
                  height_big_screen={90}
                >
                  <ButtonCircle
                    type="button"
                    onClick={() => {
                      setSelectedMenuFoodTypeId(optionsMenuFoodType.value)

                      setMenuFood(undefined)
                      setSelectedPlanFrequency(undefined)
                      getItemsMenuFood(
                        String(contract?.institution_id),
                        String(optionsMenuFoodType.value)
                      )
                    }}
                    style={{ width: '22px', height: '22px' }}
                  >
                    {optionsMenuFoodType.value === selectedMenuFoodTypeId ? (
                      <BsCheckCircle fontSize={22} color="#009EF7" />
                    ) : (
                      <BsCircle fontSize={20} color="#A1A5B7" />
                    )}
                  </ButtonCircle>
                  <h3 style={{ color: '#3F4254' }}>
                    {optionsMenuFoodType.name}
                  </h3>
                </Card>
              </div>
            ))}
        {optionsMenuFoodType.filter(omf => {
          const optionsSelected = contract.plans?.map(
            cp => cp.plan_frequency.menu_food.menu_food_type.name
          )
          return !optionsSelected.includes(omf.name)
        }).length === 0 && (
          <h4>
            Todas as refeições disponíveis para este estudante já foram
            selecionadas.
          </h4>
        )}
      </div>

      {plansFrequency &&
        Object.entries(
          plansFrequency
            ?.sort((a, b) => {
              const paymentFormA = JSON.parse(a.payament_form) as {
                periodicity: TPeriodicity
              }
              const paymentFormB = JSON.parse(b.payament_form) as {
                periodicity: TPeriodicity
              }
              if (paymentFormA.periodicity === paymentFormB.periodicity) {
                return (
                  Number(a.frequency.charAt(0)) - Number(b.frequency.charAt(0))
                )
              }
              return 0
            })
            .reduce(function (r, a) {
              const { periodicity } = JSON.parse(a.payament_form) as {
                periodicity: TPeriodicity
              }
              r[periodicity] = r[periodicity] || []
              r[periodicity].push(a)
              return r
            }, {} as { [key: string]: any[] })
        ).map(([key, values]) => {
          const hasItems = values.some(a => {
            const { payament_form } = a
            const { periodicity } = JSON.parse(payament_form) as {
              periodicity: TPeriodicity
              value: string
            }
            return contract.plans?.[0]?.periodicity_payament === periodicity
          })
          return (
            <>
              {hasItems && <p className="col-form-label fw-bold fs-5">{key}</p>}
              <div className="row d-flex flex-wrap">
                {values.map((i: any) => {
                  const { id, frequency, payament_form } = i
                  const { periodicity, value } = JSON.parse(payament_form) as {
                    periodicity: TPeriodicity
                    value: string
                  }
                  if (
                    contract.plans?.[0]?.periodicity_payament !== periodicity
                  ) {
                    return <></>
                  }
                  return (
                    <div className="col-3 mb-5" key={'plan_frequency' + id}>
                      <Card
                        isSelected={id === selectedPlanFrequency?.id}
                        className="d-flex align-items-center flex-column gap-4"
                      >
                        <ButtonCircle
                          type="button"
                          onClick={() => {
                            if (id !== selectedPlanFrequency?.id) {
                              setSelectedPlanFrequency({
                                id,
                                frequency,
                                value,
                                periodicity
                              })
                              setFrequencyDaysOfWeek([])
                              setDefaultValuesDaysOfWeek([])
                              if (Number(frequency.charAt(0)) === 5) {
                                setFrequencyDaysOfWeek([
                                  'Segunda-feira',
                                  'Terça-feira',
                                  'Quarta-feira',
                                  'Quinta-feira',
                                  'Sexta-feira'
                                ])
                                return
                              }
                              setIsOpenModalFrequency(true)
                            }
                          }}
                          style={{
                            width: '22px',
                            height: '22px'
                          }}
                        >
                          {id === selectedPlanFrequency?.id ? (
                            <BsCheckCircle fontSize={22} color="#009EF7" />
                          ) : (
                            <BsCircle fontSize={20} color="#A1A5B7" />
                          )}
                        </ButtonCircle>
                        <p className="fs-6 fw-bold text-center">{frequency}</p>
                        <p className="fs-6 fw-bold text-primary">
                          {currencyMask(value)}
                          <span className="">/{periodicity}</span>
                        </p>
                        {id === selectedPlanFrequency?.id && (
                          <p>
                            {frequencyDaysOfWeek.map(a => (
                              <span key={a} style={{ marginRight: '5px' }}>
                                {a}
                              </span>
                            ))}
                          </p>
                        )}
                        {id === selectedPlanFrequency?.id && (
                          <p
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              if (id === selectedPlanFrequency?.id) {
                                setSelectedPlanFrequency({
                                  id,
                                  frequency,
                                  value,
                                  periodicity
                                })
                                setFrequencyDaysOfWeek([])
                                setIsOpenModalFrequency(true)
                                setDefaultValuesDaysOfWeek(frequencyDaysOfWeek)
                              }
                            }}
                          >
                            <FaEdit style={{ marginRight: '5px' }} />
                            Editar dias da semana
                          </p>
                        )}
                      </Card>
                    </div>
                  )
                })}
              </div>
            </>
          )
        })}
      <div>
        <button
          className="btn btn-primary float-end"
          onClick={() => onSubmitForm()}
        >
          Confirmar
        </button>
      </div>
      <Modal
        onClickButtonCancel={handleClickOnCloseModalFrequency}
        isOpenModal={isOpenModalFrequency}
        pageTitle={'Escolha os dias da semana:'}
        modalWithClose={true}
        Children={
          <ChooseDaysFrequencyForm
            frequencyDaysOfWeek={frequencyDaysOfWeek}
            setFrequencyDaysOfWeek={setFrequencyDaysOfWeek}
            handleClickOnCloseModalFrequency={
              handleClickOnConfirmModalFrequency
            }
            selectedPlanFrequency={selectedPlanFrequency}
            defaultValues={defaultValuesDaysOfWeek}
          />
        }
      />
    </div>
  )
}

export default UpdatePlanForm
