import { Input } from 'components/Form'
import React from 'react'
import { ICheckedError, IComposition, TTypeOfError } from '../../../contexts'
import Select from 'react-select'
import { Error } from 'components/Form/styles'
import { FiAlertCircle } from 'react-icons/fi'

type SearchComponentProductParams = {
  index: number
  newComposition: IComposition[]
  setNewComposition: React.Dispatch<React.SetStateAction<IComposition[]>>
  dishTypeList: IDishType[]
  typeForm: 'create' | 'update'
  checkError: (id: string, typeOfError: TTypeOfError) => ICheckedError
  onChangeOfActualErrors: (id: string, typeOfError: TTypeOfError) => void
}

export function SearchComponentProduct({
  index,
  dishTypeList,
  newComposition,
  setNewComposition,
  checkError,
  typeForm,
  onChangeOfActualErrors
}: SearchComponentProductParams) {
  const dishList = dishTypeList?.find(
    typeDish => typeDish.id === newComposition[index].dish_type_id
  )?.dishes
  const dishOrderedList = dishList?.sort((a: IDishData, b: IDishData) =>
    a.name.localeCompare(b.name)
  )
  const dishOrderedListActive = dishOrderedList?.filter(dish => dish.is_active)
  return (
    <>
      {!dishOrderedList && (
        <Input
          className="form-select form-select-solid fw-boldl p-0 m-0"
          disabled
          errorMessage=""
          name={'item_disabled'}
          nestedError={checkError(newComposition[index].id, 'error_item')}
        />
      )}
      {dishOrderedList && (
        <div style={{ position: 'relative' }}>
          <Select
            name={`composition.${index}.dish`}
            options={
              typeForm === 'create'
                ? dishOrderedListActive.map(dish => ({
                    label: dish.name,
                    value: dish.id
                  }))
                : dishOrderedList.map(dish => ({
                    label: dish.name,
                    value: dish.id
                  }))
            }
            className="form-select form-select-solid fw-boldl p-0 m-0"
            onChange={(option: { label: string; value: number }) => {
              if (option) {
                setNewComposition(prev => {
                  const copy = [...prev]
                  copy[index].dish_id = option.value
                  copy[index].dish_name = option.label
                  return copy
                })
                onChangeOfActualErrors(newComposition[index].id, 'error_item')
                return option.value
              } else {
                setNewComposition(prev => {
                  const copy = [...prev]
                  copy[index].dish_id = undefined
                  copy[index].dish_name = undefined
                  return copy
                })
              }
            }}
            value={{
              value: newComposition[index].dish_id,
              label: newComposition[index].dish_name
            }}
            styles={{
              control: baseStyles => ({
                ...baseStyles,
                borderColor: checkError(newComposition[index].id, 'error_item')
                  .hasError
                  ? 'red'
                  : 'grey'
              })
            }}
            menuPosition={'fixed'}
            classNames={{
              control: state => {
                state.getValue()
                return 'border-red-300'
              }
            }}
            isSearchable={true}
            noOptionsMessage={() => 'Opção não encontrada.'}
          />
          {checkError(newComposition[index].id, 'error_item')?.hasError && (
            <Error
              title={
                checkError(newComposition[index].id, 'error_item').messageError
              }
              type="select"
            >
              <FiAlertCircle color="#c53030" size={20} />
            </Error>
          )}
        </div>
      )}
    </>
  )
}
