import IngredientsList from '../../pages/Commercial/MenuFoods/Ingredients/List'
import IngredientsCreate from '../../pages/Commercial/MenuFoods/Ingredients/Create'
import IngredientsView from '../../pages/Commercial/MenuFoods/Ingredients/View'
import IngredientsUpdate from '../../pages/Commercial/MenuFoods/Ingredients/Update'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const IngredientsRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/commercial/ingredients" exact component={IngredientsList} />
    <Route
      path="/commercial/ingredients/create"
      component={IngredientsCreate}
      exact
    />
    <Route
      path="/commercial/ingredients/view/:id"
      component={IngredientsView}
      exact
    />
    <Route
      path="/commercial/ingredients/update/:id"
      component={IngredientsUpdate}
      exact
    />
  </CustomSwitch>
)
