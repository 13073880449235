export const apiCreate = (): string => '/commercial/dishes/create'
export const apiList = (id?: string): string =>
  !id ? '/commercial/dishes/' : `/commercial/dishes/view/${id}`
export const apiUpdate = (id: string): string =>
  `/commercial/dishes/update/${id}`
export const apiDelete = (id: string): string =>
  `/commercial/dishes/delete/${id}`

export const apiDishesTypes = (): string => '/commercial/dishesTypes'

export const apiCreateDishesIngredients = (): string =>
  '/commercial/dishesIngredients/create'
export const apiUpdateDishesIngredients = (id: string): string =>
  `/commercial/dishesIngredients/update/${id}`
export const apiDeleteDishesIngredients = (id: string): string =>
  `/commercial/dishesIngredients/delete/${id}`
