import { useEffect, useState } from 'react'
import Form, {
  Input,
  Select,
  Textarea
} from '../../../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../../hooks/toast'
import { useLoading } from '../../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { INGREDIENT_TYPES_MAP, nameActions } from '../../domain/info'
import { FormContainer, InputContainer } from './styles'
import { deleteEmptyFields } from 'utlis/deleteEmptyFields'
import api from 'services/api'
import currency from 'currency.js'
import { genericMaskWithTwoZero } from 'utlis/mask'

const BRL = (value: string) =>
  currency(value, {
    decimal: ',',
    symbol: ''
  })

type TypesFormProps = {
  initialValues?: IIngredient & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}

export const FormIngredient = ({
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const [hasUpdated, setHasUpdated] = useState<boolean>(false)
  const [defaultValues, setDefaultValues] = useState<IIngredient>()
  const [correctionPercentage, setCorrectionPercentage] = useState('0,00')

  useEffect(() => {
    if (initialValues && !hasUpdated) {
      setDefaultValues({
        ...initialValues
      })
      setCorrectionPercentage(
        BRL(
          genericMaskWithTwoZero(initialValues.correction_percentage || '0,00')
        ).format()
      )
    }
  }, [initialValues, hasUpdated])

  const { activeLoading, disableLoading } = useLoading()

  const onSubmitForm = async (data: IIngredient) => {
    const id = initialValues?.idUpdate
    data = deleteEmptyFields(data)

    try {
      if (typeForm === 'create') {
        try {
          const dataCreate = {
            ...data,
            correction_percentage: BRL(correctionPercentage).value
          }

          activeLoading()
          await api.post(apiCreate(), dataCreate)
          updateDataTable()
          disableLoading()
          addToast({
            type: 'success',
            title: 'Registro criado',
            description: 'Registro criado com sucesso'
          })
          history.push(nameActions.read.to)
        } catch (err: any) {
          console.log(err.response.data, 'here')
          addToast({
            type: 'error',
            title: 'Erro ao adicionar o registro',
            description:
              'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
          })
          updateDataTable()
          disableLoading()
        }
      } else {
        const dataUpdate = {
          ...data,
          correction_percentage: BRL(correctionPercentage).value
        }
        try {
          activeLoading()
          const res = await api.put(apiUpdate(String(id)), dataUpdate)
          setDefaultValues(res.data)
          updateDataTable()
          setHasUpdated(true)
          disableLoading()

          history.push(nameActions.read.to)
          addToast({
            type: 'success',
            title: 'Registro atualizado',
            description: 'Registro alterado com sucesso'
          })
        } catch (err: any) {
          history.push(nameActions.read.to)
          console.log(err.response.data, 'here')

          addToast({
            type: 'error',
            title: 'Erro ao atualizar o registro',
            description:
              'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
          })
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
      }
    }
  }

  return (
    <Form
      onSubmit={onSubmitForm}
      setReset
      defaultValues={{
        ...defaultValues
      }}
    >
      <>
        <div className="card mb-5 mb-xl-10">
          <FormContainer className="form-body">
            <div className="row">
              <Input
                name="name"
                className="col-md-4"
                rules={{ required: true }}
                label="Nome"
              />
              <Select
                className=" col-md-4"
                name="unit_measure"
                label="Unidade de medida"
                options={Object.entries(INGREDIENT_TYPES_MAP).map(
                  ([key, value]) => ({
                    name: value,
                    value: key
                  })
                )}
                blank
                defaultValue={''}
                rules={{ required: true }}
              />
              <InputContainer className="col-md-4">
                <label
                  htmlFor={'correction_percentage'}
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Correção %
                </label>
                <div>
                  <input
                    className="form-control form-control-solid"
                    name="correction_percentage"
                    value={correctionPercentage}
                    onChange={e => {
                      const value = genericMaskWithTwoZero(e.target.value)
                      console.log(e.target.value, value, BRL(value).value)
                      if (BRL(value).value > 100) {
                        setCorrectionPercentage('0,00')
                        return
                      }
                      setCorrectionPercentage(value)
                    }}
                    onKeyPress={event => {
                      const regex = /^[0-9.]+$/
                      if (!regex.test(event.key)) event.preventDefault()
                    }}
                  />
                </div>
              </InputContainer>
              <Textarea
                name="description"
                className=" col-md-12"
                label="Descrição"
                rows={3}
                rules={{ required: true }}
              />
            </div>
          </FormContainer>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </div>
      </>
    </Form>
  )
}
