import styled from 'styled-components'

export const WrapperTablePortlet = styled.div`
  div.switchButton {
    border-radius: unset !important;
  }
`

export const LinkContainer = styled.div`
  display: flex;
  justify-content: center;

  > div {
    margin: 0px 0.4em;

    cursor: pointer;
  }

  a + a {
    margin-left: 5px;
  }
`
