import { useRef, useEffect, RefObject } from 'react'
import { Link } from 'react-router-dom'
import { Container, CloseIcon } from './styles'

type ParamsPush = {
  id: string
  value: string
}

interface ToolsContainerProps {
  name: string
  to: string
  icon: string
  hasParams?: false | ParamsPush
  color?: string
  handleOnClick?: <T>(currentValue: T | any) => void
}

type TypeModalProps = {
  isOpenModal: boolean
  pageTitle: string
  onClickButtonCancel: () => void
  Children: JSX.Element
  // refModal?: React.LegacyRef<HTMLElement>
  refModal?: RefObject<HTMLDivElement>
  style?: any
  tools?: ToolsContainerProps[]
  modalWithClose?: boolean
  withoutPadding?: boolean
}
const Modal = ({
  pageTitle,
  isOpenModal,
  Children,
  refModal,
  onClickButtonCancel,
  style,
  tools,
  withoutPadding,
  modalWithClose = true
}: TypeModalProps): JSX.Element => {
  const handleClickCloseModal = () => {
    onClickButtonCancel()
  }

  useEffect(() => {
    function handleOutClick(event: any) {
      let value
      if (refModal?.current) {
        value = refModal?.current as HTMLDivElement
      }
      if (value && !value.contains(event.target)) {
        handleClickCloseModal()
      }
    }
    if (modalWithClose) {
      if (isOpenModal) {
        document.addEventListener('mousedown', handleOutClick)

        return () => {
          document.removeEventListener('mousedown', handleOutClick)
        }
      }
    }
  }, [isOpenModal])

  return (
    <Container
      openModal={isOpenModal}
      style={style}
      withoutPadding={withoutPadding}
    >
      <section ref={refModal} style={style?.stylesModal}>
        <header>
          <h4 className="modal-title">{pageTitle}</h4>
          <div>
            {tools?.length ? (
              <div className="d-flex align-items-center py-1">
                <div>
                  {tools.map((tool, index) => (
                    <Link
                      key={tool.name}
                      to={tool.to}
                      className={`btn btn-sm btn ${
                        tool.color || 'btn-light-primary'
                      } fw-bolder`}
                      data-kt-menu-trigger="click"
                      data-kt-menu-placement="bottom-end"
                      data-kt-menu-flip="top-end"
                      style={{ marginLeft: index !== 0 ? '5px' : '0px' }}
                      onClick={tool.handleOnClick}
                    >
                      {tool.icon && <i className={tool.icon} />}
                      {tool.name}
                    </Link>
                  ))}
                </div>
                <CloseIcon
                  onClick={handleClickCloseModal}
                  className="ms-20"
                  cursor={'pointer'}
                />
              </div>
            ) : (
              <>
                {modalWithClose && (
                  <CloseIcon onClick={handleClickCloseModal} />
                )}
              </>
            )}
          </div>
        </header>
        <hr />
        <main>{isOpenModal && Children}</main>
      </section>
    </Container>
  )
}

export default Modal
