import FinancialMovimentsList from '../../pages/Financial/FinancialMoviments/List'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const FinancialMovimentsRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route
      path="/financial/financialMoviments"
      exact
      component={FinancialMovimentsList}
    />
  </CustomSwitch>
)
