import { useCallback, useEffect, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input, Select, Textarea } from '../../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import { FormContainer } from './styles'
import {
  cnpjMask,
  convertValueMaskInNumberWithTwoZero,
  genericMaskWithTwoZeroWithPoint,
  phoneMask,
  zipCodeMask
} from '../../../../../utlis/mask'
import { deleteEmptyFields } from 'utlis/deleteEmptyFields'
import { optionsColors, optionsStates } from 'commons/constants/commercial'
import jsonp from 'jsonp'
import { cnpj as CheckCNPJ } from 'cpf-cnpj-validator'
import axios from 'axios'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: IInstitutionData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}

export const FormInstitution = ({
  isOpenInModal,
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()

  const [defaultValues, setDefaultValues] = useState<IInstitutionData>()
  const [company, setCompany] = useState<Company>()
  const [address, setAddress] = useState<Address>()
  const [cnpj, setCnpj] = useState<string>()
  const [cnpjError, setCnpjError] = useState<{
    message?: string
    error: boolean
  }>({ message: '', error: false })
  const [zipCode, setZipCode] = useState<string>()
  const [phone1, setPhone1] = useState<string>()
  const [phone2, setPhone2] = useState<string>()
  const [hasComission, setHasComission] = useState<string>()
  const [comissionPercentage, setComissionPercentage] = useState<any>()
  const [hasUpdated, setHasUpdated] = useState<boolean>(false)

  useEffect(() => {
    if (initialValues && !hasUpdated) {
      setDefaultValues({
        ...initialValues,
        cnpj: cnpjMask(initialValues?.cnpj || ''),
        phone: phoneMask(initialValues?.phone || ''),
        phone_2: phoneMask(initialValues?.phone_2 || '')
      })
    }
  }, [initialValues, hasUpdated])

  const { activeLoading, disableLoading } = useLoading()

  const getDataCep = useCallback(
    async (zipCodeData: string) => {
      setZipCode(zipCodeMask(zipCodeData))
      const zipCodeSearch = zipCodeData?.replaceAll(/[.\-/]/g, '')
      if (zipCodeSearch.length === 8) {
        activeLoading()
        try {
          const response = await api.get(
            `https://viacep.com.br/ws/${zipCodeSearch}/json`,
            {
              timeout: 10000
            }
          )
          const { bairro, ibge, localidade, logradouro, uf } = response.data
          // Alguns CEPS não disponibilizam logradouro/bairro
          if (logradouro.length === 0) {
            setAddress({
              city: localidade,
              state: uf,
              ibge
            })
          } else {
            setAddress({
              city: localidade,
              district: bairro,
              street: logradouro,
              state: uf,
              ibge
            })
          }
        } catch (error) {
          addToast({
            title: 'Erro ao carregar os dados para o cep',
            description:
              'Houve um erro ao carregar os dados para o cep, digite os dados manualmente!',
            type: 'error'
          })
        }
        disableLoading()
      }
    },
    [activeLoading, addToast, disableLoading]
  )
  const getDataCnpj = useCallback(
    async (cnpjData: string) => {
      setCnpj(() => {
        if (cnpjData.length === 0) {
          return ' '
        } else {
          return cnpjMask(cnpjData)
        }
      })

      const cnpjSearch = cnpjData?.replaceAll(/[.\-/]/g, '')
      if (cnpjSearch.length === 14) {
        if (!CheckCNPJ.isValid(cnpjSearch)) {
          setCnpjError({
            error: true,
            message: 'CNPJ inválido'
          })
          return
        }
        setCnpjError({
          error: false
        })

        activeLoading()
        jsonp(
          `https://www.receitaws.com.br/v1/cnpj/${cnpjSearch}`,
          {
            timeout: 10000
          },
          (error: any, data: any) => {
            if (error) {
              addToast({
                title: 'Erro ao carregar os dados para o cnpj',
                description:
                  'Houve um erro ao carregar os dados para o cnpj, digite os dados manualmente!',
                type: 'error'
              })
            } else {
              setCompany({
                company_social_name: data.fantasia,
                company_name: data.nome,
                cnpj: cnpjSearch,
                complement: data.complemento,
                number: data.numero
              })
              if (data.cep) {
                getDataCep(data.cep)
              }
            }
            disableLoading()
          }
        )
      }
    },
    [activeLoading, addToast, disableLoading, getDataCep]
  )

  const onSubmitForm = async (data: IInstitutionData) => {
    const id = initialValues?.idUpdate
    if (!data.has_comission || hasComission === 'false') {
      data.percentage_comission = 0
      data.has_comission = false
    }
    if (data.percentage_comission !== 0) {
      data.percentage_comission = convertValueMaskInNumberWithTwoZero(
        data.percentage_comission
      )
    }
    data = deleteEmptyFields(data)
    data.cnpj = cnpjMask(data.cnpj)

    try {
      if (typeForm === 'create') {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          const dataCreate = {
            ...data,
            phone: data.phone?.replace(' ', ''),
            phone_2: data.phone_2?.replace(' ', ''),
            ibge: address?.ibge
          }
          activeLoading()
          try {
            await api.post(apiCreate(), dataCreate)
            handleOnClose()
            disableLoading()
            updateDataTable()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
          } catch (err) {
            if (axios.isAxiosError(err)) {
              addToast({
                type: 'error',
                title: 'Erro ao adicionar o registro',
                description: err.response.data.message
              })
              handleOnClose()
              disableLoading()
              updateDataTable()
            }
          }
        } else {
          try {
            const dataCreate = {
              ...data,
              phone: data.phone?.replace(' ', ''),
              phone_2: data.phone_2?.replace(' ', ''),
              ibge: address?.ibge
            }
            activeLoading()
            await api.post(apiCreate(), dataCreate)
            disableLoading()
            updateDataTable()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
            history.push(nameActions.read.to)
          } catch (err: any) {
            if (axios.isAxiosError(err)) {
              addToast({
                type: 'error',
                title: 'Erro ao adicionar o registro',
                description: err.response.data.message
              })
              disableLoading()
              updateDataTable()
            }
          }
        }
      } else {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          const dataUpdate = {
            ...data,
            phone: data.phone?.replace(' ', ''),
            phone_2: data.phone_2?.replace(' ', ''),
            ibge: address?.ibge
          }
          if (!dataUpdate.ibge) delete dataUpdate.ibge

          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), dataUpdate)
            updateDataTable()
            setHasUpdated(true)
            disableLoading()
            handleOnClose()
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (err: any) {
            disableLoading()
            handleOnClose()
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        } else {
          const dataUpdate = {
            ...data,
            phone: data.phone?.replace(' ', ''),
            phone_2: data.phone_2?.replace(' ', ''),
            ibge: address?.ibge
          }
          if (!dataUpdate.ibge) delete dataUpdate.ibge
          try {
            activeLoading()
            const res = await api.put(apiUpdate(String(id)), dataUpdate)
            setDefaultValues(res.data)
            updateDataTable()
            setHasUpdated(true)

            disableLoading()

            history.push(nameActions.read.to)
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (err: any) {
            // history.push(nameActions.read.to)
            console.log(err.response.data, 'here')

            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        if (axios.isAxiosError(err)) {
          addToast({
            type: 'error',
            title: 'Erro ao adicionar o registro',
            description: err.response.data.message
          })
        }
        if (isOpenInModal) isOpenInModal.handleOnClose()
      }
    }
  }

  return (
    <Form
      onSubmit={onSubmitForm}
      setReset
      defaultValues={{
        ...defaultValues,
        ...address,
        ...company
      }}
    >
      <>
        <div className="card mb-5 mb-xl-10">
          <FormContainer className="form-body">
            <div className="row mb-5">
              <Input
                name="cnpj"
                className=" col-md-3"
                label="CNPJ"
                maxLength={18}
                rules={{ required: true, position: 'left' }}
                value={cnpj}
                hasError={{
                  message: cnpjError.message,
                  error: cnpjError.error
                }}
                onChange={event => getDataCnpj(event.target.value)}
                controlled
              />
              <Input
                name="company_name"
                className=" col-md-6"
                label="Razão Social"
                value={company?.company_name}
                rules={{ required: true, position: 'left' }}
                onChange={event =>
                  setCompany({
                    ...company,
                    company_name: event.target.value
                  })
                }
                controlled
              />
              <Input
                name="company_social_name"
                className=" col-md-3"
                label="Nome Fantasia"
                value={company?.company_social_name}
                rules={{ required: true, position: 'left' }}
                onChange={event =>
                  setCompany({
                    ...company,
                    company_social_name: event.target.value
                  })
                }
              />
            </div>
            <div className="row mb-5">
              <Input
                name="identifier"
                className="col-md-4"
                label="Identificador"
              />
              <Input
                name="responsible"
                className=" col-md-3"
                label="Responsável"
                rules={{ required: true, position: 'left' }}
              />
            </div>
            <div className="separator my-5" />
            <div className="row mb-5">
              <Input
                name="zip_code"
                className=" col-md-3"
                label="CEP"
                rules={{ required: true, position: 'left' }}
                value={zipCode}
                onChange={event => getDataCep(event.target.value)}
                maxLength={9}
              />
              <Input
                name="street"
                className=" col-md-6"
                label="Logradouro"
                value={address?.street}
                onChange={event =>
                  setAddress({ ...address, street: event.target.value })
                }
                rules={{ required: true, position: 'left' }}
              />
              <Input
                name="number"
                className=" col-md-3"
                label="Número"
                value={company?.number}
                onChange={event =>
                  setCompany({
                    ...company,
                    number: event.target.value
                  })
                }
                rules={{ required: true, position: 'left' }}
              />
            </div>
            <div className="row mb-5">
              <Input
                name="complement"
                className=" col-md-3"
                label="Complemento"
                value={company?.complement}
                onChange={event =>
                  setCompany({
                    ...company,
                    complement: event.target.value
                  })
                }
              />
              <Input
                name="district"
                className=" col-md-3"
                label="Bairro"
                value={address?.district}
                onChange={event =>
                  setAddress({ ...address, district: event.target.value })
                }
                rules={{ required: true, position: 'left' }}
              />
              <Input
                name="city"
                className=" col-md-3"
                label="Cidade"
                value={address?.city}
                onChange={event =>
                  setAddress({ ...address, city: event.target.value })
                }
                rules={{ required: true, position: 'left' }}
              />
              <Select
                className="col-3"
                label="Estado"
                name="state"
                options={optionsStates}
                rules={{ required: true }}
                value={address?.state}
                onChange={event =>
                  setAddress({ ...address, state: event.target.value })
                }
                defaultValue=""
                blank
              />
            </div>
            <div className="separator my-5" />
            <div className="row mb-5">
              <Input
                name="phone"
                className=" col-md-3"
                label="Telefone 1"
                value={phone1}
                onChange={event => setPhone1(phoneMask(event.target.value))}
                maxLength={15}
                controlled
                rules={{
                  required: true,
                  position: 'left',
                  minLength: { message: 'Número Invalido', value: 14 }
                }}
              />
              <Input
                name="phone_2"
                className=" col-md-3"
                label="Telefone 2"
                value={phone2}
                onChange={event => setPhone2(phoneMask(event.target.value))}
                maxLength={15}
                controlled
                rules={{
                  minLength: { message: 'Número Invalido', value: 14 }
                }}
              />
              <Input
                name="email"
                className=" col-md-3"
                label="E-mail"
                rules={{
                  required: true,
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: 'Email inválido'
                  },
                  position: 'left'
                }}
              />
              <Input
                name="email_2"
                className=" col-md-3"
                label="E-mail 2"
                rules={{
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: 'Email inválido'
                  },
                  position: 'left'
                }}
              />
            </div>
            <div className="separator my-5" />
            <div className="row mb-5">
              {typeForm === 'update' && (
                <Select
                  className=" col-md-3"
                  name="is_active"
                  label="Ativo"
                  options={[
                    {
                      name: 'Sim',
                      value: 'true'
                    },
                    {
                      name: 'Não',
                      value: 'false'
                    }
                  ]}
                  blank
                  defaultValue={'true'}
                />
              )}
              <div className=" col-md-3" style={{ position: 'relative' }}>
                <Select
                  name="has_comission"
                  label="Comissão"
                  options={[
                    {
                      name: 'Sim',
                      value: 'true'
                    },
                    {
                      name: 'Não',
                      value: 'false'
                    }
                  ]}
                  blank
                  value={hasComission}
                  onChange={event => {
                    setHasComission(event.target.value)
                    if (event.target.value !== 'true') {
                      setComissionPercentage(0)
                    }
                  }}
                  titleTooltip="Caso não haja comissão, a porcentagem de comissão será automaticamente zerada."
                />
              </div>
              <Input
                name="percentage_comission"
                className="col-md-3"
                disabled={hasComission !== 'true' && hasComission !== undefined}
                label="Porcentagem da comissão"
                endMessage="%"
                defaultValue={'0'}
                value={comissionPercentage}
                onChange={event =>
                  setComissionPercentage(
                    genericMaskWithTwoZeroWithPoint(event.target.value)
                  )
                }
              />
            </div>
            <div className="separator my-5" />
            <div className="row">
              <Textarea
                name="observation"
                className=" col-md-12"
                label="Observações"
                style={{ minHeight: 120 }}
                defaultValue=""
              />
            </div>
            <div className="row">
              <Select
                className="col-3"
                label="Cor"
                name="color"
                options={optionsColors}
                defaultValue=""
                blank
                colored
              />
            </div>
          </FormContainer>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </div>
      </>
    </Form>
  )
}
