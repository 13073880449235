import { useCallback, useEffect, useRef, useState } from 'react'
import { breadcrumbList } from '../domain/breadcrumb'
import Container from '../../../../components/Container'
import { useToast } from 'hooks/toast'
import Form, { Input, Select } from '../../../../components/Form'
import { FilterContainer } from './styles'
import { useHistory, useLocation } from 'react-router-dom'
import api from 'services/api'
import { apiListInstitution } from 'pages/Commercial/Contracts/domain/api'
import moment from 'moment'
import Button from 'components/Button'
import { currencyMask } from 'utlis/mask'
import { useLoading } from 'hooks/loading'

const InstitutionList = (): JSX.Element => {
  const [institutionId, setInstitutionId] = useState<string>()
  const [period, setPeriod] = useState<string>()
  const [contractId, setContractId] = useState<string>()
  const [institutionOptions, setInstitutionOptions] = useState<
    IInstitutionData[]
  >([])
  const [parameters, setParameters] = useState<any[]>()
  const [periodOptions, setPeriodOptions] = useState<any[]>([])
  const [total, setTotal] = useState<any>()

  const { activeLoading, disableLoading, loading } = useLoading()
  const { addToast } = useToast()
  const history = useHistory()

  const getTotal = useCallback(({ institutionId, period, contractId }) => {
    api
      .get(
        `financial/renewPayments?institution_id=${institutionId}&period=${period}&contract_id=${
          contractId || ''
        }`
      )
      .then(response => {
        setTotal(response.data)
      })
  }, [])

  useEffect(() => {
    api
      .get(apiListInstitution())
      .then(res => {
        const orderedData = res.data.sort(
          (a: IInstitutionData, b: IInstitutionData) =>
            a.company_social_name.localeCompare(b.company_social_name)
        )
        setInstitutionOptions(orderedData)
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: 'Erro ao carregar as instituições',
          description:
            'Houve um erro ao carregar as instituições disponíveis no banco de dados.'
        })
      })

    const period1 = `${String(moment().subtract(1, 'M').month() + 1).padStart(
      2,
      '0'
    )}/${moment().year()}`
    const period2 = `${String(moment().month() + 1).padStart(
      2,
      '0'
    )}/${moment().year()}`
    const period3 = `${String(moment().add(1, 'M').month() + 1).padStart(
      2,
      '0'
    )}/${moment().year()}`
    const period4 = `${String(moment().add(2, 'M').month() + 1).padStart(
      2,
      '0'
    )}/${moment().year()}`

    setPeriodOptions([
      {
        name: period1,
        value: period1.split('/').reverse().join('-')
      },
      {
        name: period2,
        value: period2.split('/').reverse().join('-')
      },
      {
        name: period3,
        value: period3.split('/').reverse().join('-')
      },
      {
        name: period4,
        value: period4.split('/').reverse().join('-')
      }
    ])
  }, [])

  const onSearch = async () => {
    const query = []
    if (institutionId) {
      query.push(`institution_id=${institutionId}`)
    }
    if (period) {
      query.push(`period=${period}`)
    }
    if (contractId) {
      query.push(`contract_id=${contractId}`)
    }

    history.replace(`/financial/renewPayments?${query.join('&')}`)
    if (institutionId || period) {
      setParameters([
        {
          institutionId: institutionId || undefined,
          period: period || undefined
        }
      ])
      getTotal({ institutionId, period, contractId })
    } else {
      setParameters(undefined)
    }
  }

  const location = new URLSearchParams(useLocation().search)

  useEffect(() => {
    const institutionId = location.get('institution_id')
    const period = location.get('period')

    if (institutionId || period) {
      if (institutionId) {
        setInstitutionId(institutionId)
      }
      if (period) {
        setPeriod(period)
      }
      setParameters([
        {
          institutionId: institutionId || undefined,
          period: period || undefined
        }
      ])
      getTotal({ institutionId, period, contractId })
    } else {
      setParameters(undefined)
    }
  }, [getTotal])

  const handleRenewPaymentsButton = useCallback(async () => {
    activeLoading()
    try {
      await api.post('financial/renewPayments/generate', {
        institution_id: institutionId,
        period,
        contract_id: contractId || undefined
      })
      addToast({
        title: 'Pagamentos gerados',
        type: 'success'
      })
      disableLoading()
    } catch (error) {
      addToast({
        title: 'Erro ao gerar',
        type: 'error'
      })
      disableLoading()
    }
  }, [
    institutionId,
    period,
    contractId,
    addToast,
    activeLoading,
    disableLoading
  ])

  return (
    <Container
      pageTitle="Emissão de cobrança"
      breadcrumb={breadcrumbList}
      tools={[]}
    >
      <FilterContainer>
        <Form onSubmit={onSearch} defaultValues={{}} className="col-md-12">
          <div className="row">
            <div className="col-md-6 pe-8">
              <Select
                label="Instituição"
                name="institution_id"
                options={institutionOptions?.map(institution => {
                  return {
                    name: institution.company_social_name,
                    value: institution.id
                  }
                })}
                onChange={e => setInstitutionId(e.target.value)}
                value={institutionId}
                rules={{ required: true }}
                blank
                controlled
                defaultValue={''}
              />
            </div>
            <div className="col-md-3">
              <Select
                label="Periodo"
                name="period"
                options={periodOptions}
                onChange={e => setPeriod(e.target.value)}
                value={period}
                rules={{ required: true }}
                controlled
                blank
                defaultValue={''}
              />
            </div>
            <div className="col-md-3">
              <Input
                label="Contrato"
                name="contract"
                value={contractId}
                onChange={e => setContractId(e.target.value)}
                controlled
              />
            </div>
          </div>
          <footer className="col-md-12 d-flex justify-content-end pb-5 gap-5">
            <button
              type="reset"
              className="btn btn-secondary btn-sm"
              onClick={() => {
                setInstitutionId(undefined)
                setPeriod(undefined)
                setContractId(undefined)
              }}
            >
              LIMPAR
            </button>
            <button type="submit" className="btn btn-primary btn-sm">
              BUSCAR
            </button>
          </footer>
        </Form>
        <hr />
      </FilterContainer>
      {parameters && (
        <div>
          <h3 className="mt-5">Resultado:</h3>
          <p style={{ marginBottom: '15px', fontSize: '18px' }}>
            {total?.count} cobranças para emitr no valor{' '}
            {currencyMask(total?.total)} no boleto
          </p>
          <Button
            className="btn btn-primary btn-sm"
            onClick={handleRenewPaymentsButton}
            disabled={loading}
          >
            Gerar Cobrança
          </Button>
          <h3 className="mt-5">Movimentações</h3>
          {(total?.moviments as any[])?.map(payment => (
            <div key={payment.id} className="row">
              <div className="col-md-2">contrato: {payment.contract_id}</div>
              <div className="col-md-2">movimentação: {payment.id}</div>
              <div className="col-md-2">valor: {payment.value}</div>
              <div className="col-md-2">desconto: {payment.discount_value}</div>
              <div className="col-md-2">cobrança: {payment.credits_value}</div>
            </div>
          ))}
        </div>
      )}
    </Container>
  )
}

export default InstitutionList
