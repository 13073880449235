import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Container, IconRemove } from './style'

import { Input, Select as CustomSelect, Select } from 'components/Form'
import { useToast } from 'hooks/toast'
import moment from 'moment'
import api from 'services/api'
import { DateInput as DatePicker } from '../../../../../../components/Form/date'
import { IComposition, useMenuFoodContext } from '../../contexts'
import { apiListDishTypes } from '../../domain/api'
import { SearchComponentProduct } from './SearchComponentProduct'
interface ICompositionByDate {
  date: string | Date
  composition: IComposition[]
}
export const FormAddNewItem = (typeForm: 'create' | 'update'): JSX.Element => {
  const {
    composition,
    setComposition,
    newComposition,
    setNewComposition,
    checkError,
    onChangeOfActualErrors,
    hasItemsSelect
  } = useMenuFoodContext()
  const { addToast } = useToast()
  const [newDate, setNewDate] = useState<string | Date>()
  const [dishTypeList, setDishTypeList] = useState<IDishType[]>([])
  useEffect(() => {
    if (dishTypeList.length === 0) {
      api
        .get(apiListDishTypes())
        .then(res => {
          const orderedData: IDishType[] = res.data.sort(
            (a: IDishType, b: IDishType) => a.name.localeCompare(b.name)
          )
          setDishTypeList(orderedData)
        })
        .catch(() => {
          addToast({
            type: 'error',
            title: 'Erro ao carregar as instituições',
            description:
              'Houve um erro ao carregar as instituições disponíveis no banco de dados.'
          })
        })
    }
  }, [addToast, dishTypeList])

  useEffect(() => {
    if (typeForm === 'update' && newComposition && newComposition.length > 0) {
      const date = moment(newComposition[0].date, 'DD/MM/YYYY').toDate()
      setNewDate(date)
    }
  }, [])

  const activeDishTypeList = dishTypeList.filter(dishType => dishType.is_active)

  return (
    <Container className="table-responsive">
      <td style={{ width: '150px' }}>
        <DatePicker
          name="date"
          label="Data"
          rules={{ required: true }}
          value={newDate as string}
          selected={(newDate as Date) || undefined}
          // excludeDates={dates}
          // daysToRefused={dates}
          onChange={(date: any) => {
            setNewDate(date)
            setNewComposition(prev => {
              const copy = [...prev]
              for (let i = 0; i < copy.length; i++) {
                copy[i].date = date
              }
              return copy
            })
            //   onChangeOfActualErrors(item.date as string, 'error_date')
          }}
          controlled
        />
      </td>
      <div>
        <div className="table-content-relative">
          <table className="table table-bordered mb-0">
            <tr>
              <th className="fw-bold fs-6">Tipo</th>
              <th className="fw-bold fs-6 ps-3">Item</th>
              {hasItemsSelect === 'true' && (
                <th className="fw-bold fs-6 ps-3">Padrão</th>
              )}
              <th className="fw-bold fs-6">Quantidade</th>
              <th className="fw-bold fs-6 text-center">Ações</th>
            </tr>
            <tbody>
              {newComposition?.map((item, index: number) => {
                return (
                  <tr key={index}>
                    <td className="col-md-3">
                      <CustomSelect
                        name={`composition.${index}.dish_type`}
                        options={
                          typeForm === 'create'
                            ? activeDishTypeList.map(dishType => ({
                                name: dishType.name,
                                value: dishType.id
                              }))
                            : dishTypeList.map(dishType => ({
                                name: dishType.name,
                                value: dishType.id
                              }))
                        }
                        rules={{ required: true }}
                        onChange={event => {
                          setNewComposition(prev => {
                            const copy = [...prev]
                            copy[index].dish_type_id = Number(
                              event.target.value
                            )
                            const dishTypeName = dishTypeList.find(
                              dishType => dishType.id === +event.target.value
                            ).name
                            copy[index].dish_type_name = dishTypeName
                            copy[index].dish_name = undefined
                            copy[index].dish_id = undefined
                            return copy
                          })
                          onChangeOfActualErrors(item.id, 'error_type')
                        }}
                        value={newComposition[index].dish_type_id}
                        blank
                        nestedError={checkError(item.id, 'error_type')}
                        controlled
                        defaultValue={''}
                      />
                    </td>

                    <td>
                      {SearchComponentProduct({
                        index,
                        newComposition,
                        setNewComposition,
                        dishTypeList,
                        typeForm,
                        checkError,
                        onChangeOfActualErrors
                      })}
                    </td>
                    {hasItemsSelect === 'true' && (
                      <td style={{ width: '150px' }}>
                        <Select
                          name={`composition.${index}.is_default_dish`}
                          options={[
                            {
                              name: 'Sim',
                              value: 'true'
                            },
                            {
                              name: 'Não',
                              value: 'false'
                            }
                          ]}
                          blank
                          onChange={event => {
                            setNewComposition(prev => {
                              const copy = [...prev]
                              copy[index].is_default_dish =
                                event.target.value === 'true'
                              return copy
                            })
                            onChangeOfActualErrors(item.id, 'error_is_default')
                          }}
                          // rules={{ position: 'left' }}
                          controlled
                          nestedError={checkError(item.id, 'error_is_default')}
                          value={
                            newComposition[index].is_default_dish === undefined
                              ? undefined
                              : newComposition[index].is_default_dish
                              ? 'true'
                              : 'false'
                          }
                        />
                      </td>
                    )}

                    <td style={{ width: '70px' }}>
                      <Input
                        name={`composition.${index}.dish_amount`}
                        type="number"
                        min={0}
                        onChange={event => {
                          setNewComposition(prev => {
                            const copy = [...prev]
                            copy[index].dish_amount = Number(event.target.value)
                            return copy
                          })
                          onChangeOfActualErrors(item.id, 'error_amount')
                        }}
                        rules={{ position: 'left', required: true }}
                        controlled
                        nestedError={checkError(item.id, 'error_amount')}
                        value={newComposition[index].dish_amount}
                      />
                    </td>
                    <td className="actions" style={{ width: '70px' }}>
                      <div className="d-flex  justify-content-center align-items-center pt-4 pe-4">
                        <IconRemove
                          onClick={() =>
                            setNewComposition(prev => {
                              const copy = [...prev]
                              copy.splice(index, 1)
                              return copy
                            })
                          }
                        />
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      <button
        type="button"
        className="btn btn-dark btn-sm mb-5"
        onClick={() =>
          setNewComposition(prev => {
            const copy = [...prev]
            const final = [
              ...copy,
              {
                id: uuidv4(),
                dish_id: undefined,
                dish_type_id: undefined,
                dish_amount: undefined,
                dish_name: undefined,
                date: newDate || undefined,
                is_default_dish: false,
                dish_type_name: undefined
              }
            ]
            return final
          })
        }
      >
        + Item
      </button>
      {newComposition.length < 4 && <div style={{ height: '200px' }}></div>}
    </Container>
  )
}
