export const headers = [
  { name: 'Nº Contrato', field: 'contract.id', sortable: true },
  {
    name: 'Instituição',
    field: 'contract.institution.company_social_name',
    sortable: true
  },
  { name: 'Cliente', field: 'contract.client.name', sortable: true },
  {
    name: 'Aluno',
    field: 'contract.student.name',
    sortable: true
  },
  { name: 'Vencimento', field: 'due_date', sortable: true },
  {
    name: 'Liquidação',
    field: 'settlement_date',
    sortable: true,
    custom: true
  },
  { name: 'Valor', field: 'value', sortable: false },
  {
    name: 'Enviado em',
    field: 'banknote_send_at',
    sortable: false
  },
  { name: 'Situação', field: 'situation', sortable: false },
  {
    name: 'Contrato',
    field: 'contract.situation',
    sortable: false,
    custom: true
  },
  {
    name: 'Manual',
    field: 'manual',
    sortable: false
  },
  { name: 'Ações', field: 'actions', sortable: false }
]
