import Form, { Input } from 'components/Form'
import { useToast } from 'hooks/toast'
import api from 'services/api'
import { updateFavoriteDay } from '../../domain/api'
import { useEffect, useState } from 'react'

interface IFavoriteDayForm {
  contract: {
    id: string
    favorite_day: string
  }
  isOpenInModal: {
    handleOnClose: () => void
  }
}

interface IFavoriteDayData {
  favorite_day: string
}

const FavoriteDayForm = ({ contract, isOpenInModal }: IFavoriteDayForm) => {
  const { addToast } = useToast()
  const [defaultValues, setDefaultValues] = useState<any>()

  useEffect(() => {
    if (contract.favorite_day) {
      setDefaultValues({
        favorite_day: contract.favorite_day
      })
    }
  }, [contract])

  const onSubmitForm = async (data: IFavoriteDayData) => {
    try {
      await api.put(updateFavoriteDay(contract.id), data)
      addToast({
        type: 'success',
        title: 'Contrato atualizado com sucesso'
      })
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Error ao cancelar o contrato',
        description:
          'Houve um error ao atualizar o contrato, tente novamente mais tarde!'
      })
    }

    isOpenInModal.handleOnClose()
  }

  return (
    <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
      <Input
        name="favorite_day"
        className=" col-md-6"
        label="Dia de pagamento"
        rules={{ required: true }}
      />
      <button type="submit" className="btn btn-primary float-end">
        Confirmar
      </button>
    </Form>
  )
}

export default FavoriteDayForm
