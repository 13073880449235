export const headers = [
  { name: 'Nome', field: 'name', sortable: true },
  { name: 'Cadastrado em', field: 'created_at', sortable: false },
  { name: 'Ativo', field: 'is_active', sortable: true },
  { name: 'Quantidade de opçoes', field: 'options_quantity', sortable: true },
  { name: 'Ações', field: 'actions', sortable: false }
]

export const headersDish = [
  { name: 'Nome', field: 'name', sortable: true },
  { name: 'Cadastrado em', field: 'created_at', sortable: false },
  { name: 'Ativo', field: 'is_active', sortable: true },
  { name: 'Ações', field: 'actions', sortable: false }
]
