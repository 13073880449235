import { GENDERS, SCHOOL_PERIODS } from 'commons/constants/commercial'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { deleteEmptyFields } from 'utlis/deleteEmptyFields'
import Form, {
  Input,
  Select,
  Textarea
} from '../../../../../../components/Form'
import { DateInput as DatePicker } from '../../../../../../components/Form/date'
import { useUpdateDataTable } from '../../../../../../hooks/dataTable'
import { useLoading } from '../../../../../../hooks/loading'
import { useToast } from '../../../../../../hooks/toast'
import api from '../../../../../../services/api'
import { apiCreate, apiListInstitution, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import { FormContainer, IconRemove } from './styles'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: IStudent & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
  clientInfo: {
    id: number
    name: string
  }
  updateOnSubmit: () => void
}

export const FormStudent = ({
  isOpenInModal,
  initialValues,
  typeForm,
  updateOnSubmit,
  clientInfo
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()

  const [defaultValues, setDefaultValues] = useState<IStudent>()
  const [institutions, setInstitutions] = useState<IInstitutionData[]>([])
  const [hasUpdated, setHasUpdated] = useState<boolean>(false)
  const [birthDate, setBirthDate] = useState<Date | string>()
  const [serieClass, setSerieClass] = useState<number>()
  const [actualInstitution, setActualInstitution] = useState<number>()
  const [actualSeriesOptions, setActualSeriesOptions] = useState<any[]>([])
  const [studentFoodRestrictions, setStudentFoodRestrictions] = useState<
    Omit<IStudentFoodRestriction, 'created_at' | 'updated_at'>[]
  >([
    {
      id: 0,
      description: '',
      student_id: undefined
    }
  ])

  const addStudentFoodRestriction = (student_id: number) => {
    setStudentFoodRestrictions(old => [
      ...old,
      {
        id: old[old.length - 1]?.id + 1 || 1,
        description: '',
        student_id
      }
    ])
  }
  const removeOneStudentFoodRestriction = (idToDelete: number) => {
    setStudentFoodRestrictions(old => {
      return old.filter(({ id }) => id !== idToDelete)
    })
  }
  useEffect(() => {
    api
      .get(apiListInstitution())
      .then(res =>
        setInstitutions(
          res.data.sort((a: IInstitutionData, b: IInstitutionData) =>
            a.company_social_name.localeCompare(b.company_social_name)
          )
        )
      )
      .catch(() => {
        addToast({
          type: 'error',
          title: 'Erro ao carregar as instituições',
          description:
            'Houve um erro ao carregar as instituições disponíveis no banco de dados.'
        })
      })
  }, [addToast])

  useEffect(() => {
    if (initialValues && !hasUpdated) {
      if (initialValues?.birth_date) {
        // moment to date ver tipos de data
        initialValues.birth_date = moment(
          initialValues?.birth_date,
          'DD/MM/YYYY'
        ).toDate()
      }
      setStudentFoodRestrictions(initialValues.student_food_restrictions)
      setActualInstitution(initialValues?.institution_id)
      const actualSeriesOptions = institutions.find(
        ({ id }) => Number(initialValues?.institution_id) === id
      )?.series_classes
      setActualSeriesOptions(actualSeriesOptions)
      setSerieClass(initialValues.serie_class_id)
      setDefaultValues({
        ...initialValues
      })
    }
  }, [initialValues, hasUpdated, institutions])

  const institutionsOptions =
    typeForm === 'create'
      ? institutions
          .filter(institution => institution.is_active)
          .map(institution => ({
            name: institution.company_social_name,
            value: institution.id
          }))
      : institutions.map(institution => ({
          name: institution.company_social_name,
          value: institution.id
        }))
  const { activeLoading, disableLoading } = useLoading()
  const onSubmitForm = async (dataForm: IStudent) => {
    const data = deleteEmptyFields(dataForm)
    data.client_id = clientInfo.id
    // remove restrictionFood input names
    for (const key in data) {
      if (key.includes('input_food_restriction')) {
        delete data[key]
      }
    }

    data.student_food_restrictions = studentFoodRestrictions.map(
      ({ description }) => ({
        description
      })
    )

    const id = initialValues?.idUpdate
    try {
      if (typeForm === 'create') {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          const dataCreate = {
            ...data
          }
          activeLoading()

          try {
            await api.post(apiCreate(), dataCreate)
            updateDataTable()
            updateOnSubmit()
            handleOnClose()
            disableLoading()
            setHasUpdated(true)

            addToast({
              type: 'success',
              title: 'Estudante criado',
              description: 'Estudante criado com sucesso'
            })
          } catch (error: any) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o estudante',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            console.log(error.response.data)

            handleOnClose()
            disableLoading()
            updateDataTable()
            updateOnSubmit()

            setHasUpdated(true)
          }
        } else {
          try {
            const dataCreate = {
              ...data
            }
            activeLoading()
            await api.post(apiCreate(), dataCreate)
            updateDataTable()
            updateOnSubmit()

            disableLoading()
            addToast({
              type: 'success',
              title: 'Estudante criado',
              description: 'Estudante criado com sucesso'
            })
            history.push(nameActions.read.to)
          } catch (err: any) {
            console.log(err.response)
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o estudante',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            disableLoading()
          }
        }
      } else {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          const dataUpdate = {
            ...data
          }

          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), dataUpdate)
            updateDataTable()
            updateOnSubmit()

            disableLoading()
            handleOnClose()
            addToast({
              type: 'success',
              title: 'Estudante atualizado',
              description: 'Estudante alterado com sucesso'
            })
          } catch (error: any) {
            console.log(error.response.data)
            disableLoading()
            handleOnClose()
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o estudante',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        } else {
          const dataUpdate = {
            ...data
          }
          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), dataUpdate)
            disableLoading()
            history.push(nameActions.read.to)
            addToast({
              type: 'success',
              title: 'Estudante atualizado',
              description: 'Estudante alterado com sucesso'
            })
          } catch (err: any) {
            console.log(err.response.data)

            history.push(nameActions.read.to)
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o estudante',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        if (isOpenInModal) isOpenInModal.handleOnClose()
      }
    }
  }
  return (
    <Form
      onSubmit={onSubmitForm}
      setReset
      defaultValues={{
        ...defaultValues
      }}
    >
      <>
        <div className="mb-5 mb-xl-10">
          <FormContainer className="form-body">
            <div className="row mb-5">
              <Select
                name="institution_id"
                className=" col-md-6"
                label="Instituição"
                rules={{ required: true }}
                value={actualInstitution}
                onChange={e => setActualInstitution(Number(e.target.value))}
                options={institutionsOptions}
                controlled
                blank
                disabled
              />
              <Input
                name="name"
                className=" col-md-6"
                label="Nome"
                rules={{ required: true, position: 'left' }}
              />
            </div>
            <div className="row mb-5">
              <Select
                name="gender"
                className="col-md-3"
                label="Sexo"
                rules={{ required: true }}
                options={GENDERS}
                blank
              />
              <DatePicker
                label="Data de nascimento"
                className="col-md-3"
                name="birth_date"
                rules={{ required: true }}
                value={birthDate as string}
                selected={
                  (birthDate as Date) ||
                  (defaultValues?.birth_date as Date) ||
                  undefined
                }
                onChange={date => setBirthDate(date)}
                maxDate={new Date()}
                refuseDateAfterToday
                controlled
              />
              <Select
                name="serie_class_id"
                className="col-md-6"
                label="Ano/Turma"
                options={
                  actualSeriesOptions?.map(isc => ({
                    name: isc.name,
                    value: Number(isc.id)
                  })) || []
                }
                value={serieClass}
                onChange={e => setSerieClass(Number(e.target.value))}
                rules={{ required: true }}
                blank
                defaultValue={''}
                controlled
              />
            </div>
            <div className="row mb-5">
              <Select
                name="school_period"
                className="col-md-3"
                label="Turno"
                rules={{ required: true }}
                options={SCHOOL_PERIODS}
                blank
              />

              {typeForm === 'update' && (
                <Select
                  className=" col-md-2"
                  name="is_active"
                  label="Ativo"
                  options={[
                    {
                      name: 'Sim',
                      value: 'true'
                    },
                    {
                      name: 'Não',
                      value: 'false'
                    }
                  ]}
                  blank
                  defaultValue={'true'}
                  rules={{ required: true }}
                />
              )}
            </div>

            <div className="separator my-5" />
            <div className="row">
              <Textarea
                name="observation"
                className=" col-md-12"
                label="Observações"
                style={{ minHeight: 120 }}
                defaultValue=""
              />
            </div>
            <div>
              <div style={{ margin: '0 -1rem' }}>
                <h3
                  className="col-form-label fw-bold fs-6 bg-secondary"
                  style={{ padding: '0.7rem 1rem' }}
                >
                  Restrições alimentares
                </h3>
              </div>
              {studentFoodRestrictions?.map((foodRestriction, index) => (
                <div className="row" key={foodRestriction.id}>
                  <Input
                    defaultValue={foodRestriction.description}
                    name={`input_food_restriction_${foodRestriction.id}`}
                    label="Restrição alimentar"
                    className="col-11"
                    value={studentFoodRestrictions[index].description}
                    onChange={event => {
                      setStudentFoodRestrictions(old => {
                        const newFoodRestrictions = [...old]
                        newFoodRestrictions[index].description =
                          event.target.value
                        return newFoodRestrictions
                      })
                    }}
                  />
                  <div className="col-1 align-self-center pt-7 ">
                    <IconRemove
                      onClick={() =>
                        removeOneStudentFoodRestriction(foodRestriction.id)
                      }
                    />
                  </div>
                </div>
              ))}

              <div className="separator my-5" />
              {console.log(initialValues?.id)}
              <button
                type="button"
                className="btn btn-dark btn-sm mb-5"
                onClick={() => addStudentFoodRestriction(initialValues?.id)}
              >
                + Restrição alimentar
              </button>
            </div>
          </FormContainer>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </div>
      </>
    </Form>
  )
}
