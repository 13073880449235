export const apiCreate = (): string => '/commercial/institutions/create/'
export const apiList = (id?: string): string =>
  !id ? '/commercial/institutions/' : `/commercial/institutions/view/${id}`
export const apiUpdate = (id: string): string =>
  `/commercial/institutions/update/${id}`
export const apiDelete = (id: string): string =>
  `/commercial/institutions/delete/${id}`

export const apiCreateSerieClass = (): string =>
  '/commercial/seriesClasses/create/'
export const apiUpdateSerieClass = (id: string): string =>
  `/commercial/seriesClasses/update/${id}`
export const apiDeleteSerieClass = (id: string): string =>
  `/commercial/seriesClasses/delete/${id}`

export const apiSendMessageByMenuFoods = (): string =>
  '/commercial/institutions/sendMessageByMenuFoods'
