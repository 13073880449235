export const headers = [
  { name: 'Nome', field: 'name', sortable: true },
  {
    name: 'Unidade de medida',
    field: 'unit_measure',
    sortable: true,
    custom: true
  },
  { name: 'Descrição', field: 'description', sortable: true },
  { name: 'Correção %', field: 'correction_percentage', sortable: true },
  { name: 'Cadastrado em', field: 'created_at', sortable: false },
  { name: 'Ações', field: 'actions', sortable: false }
]
