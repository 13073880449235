/* eslint-disable @typescript-eslint/no-empty-function */
import { useCallback, useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Container from '../../../../../components/Container'
import api from '../../../../../services/api'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { Alert } from '../../../../../components/Alert'
import { INGREDIENT_TYPES_MAP, nameActions } from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'

import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewUpdate,
  toolsViewList
} from '../domain/tools'

const IngredientView = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const [ingredient, setIngredient] = useState<IIngredient | null>(null)
  const { addToast } = useToast()

  const { disableLoading, activeLoading } = useLoading()
  const loadIngredient = useCallback(async () => {
    activeLoading()
    try {
      const response = await api.get<IIngredient>(apiList(id))
      const { data } = response
      setIngredient(data)
      disableLoading()
    } catch (err) {
      disableLoading()
      addToast({
        type: 'error',
        title: 'Error ao carregar o ingrediente',
        description:
          'Houve um error ao carregar o ingrediente, tente novamente mais tarde!'
      })
    }
  }, [activeLoading, addToast, disableLoading, id])

  useEffect(() => {
    loadIngredient()
  }, [loadIngredient])

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Ingrediente removido com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title: 'Não é possível remover esse ingrediente.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }
  return (
    <>
      <Container
        pageTitle={nameActions.read.name}
        breadcrumb={breadcrumbView}
        tools={[
          toolsViewUpdate(String(id)),
          toolsViewDelete(() => {
            handleOnClickRemoveParent()
          }),
          toolsViewCreate(),
          toolsViewList()
        ]}
      >
        <div className="form-body">
          <div className="row mb-5">
            <div className="col-md-3">
              <label htmlFor="name" className="col-form-label">
                Cód
              </label>
              <p className="fw-bolder fs-6 text-gray-800">{ingredient?.id}</p>
            </div>
            <div className="col-md-3">
              <label htmlFor="name" className="col-form-label">
                Nome
              </label>
              <p className="fw-bolder fs-6 text-gray-800">{ingredient?.name}</p>
            </div>
            <div className="col-md-3">
              <label htmlFor="name" className="col-form-label">
                Unidade de medida
              </label>
              <p className="fw-bolder fs-6 text-gray-800">
                {INGREDIENT_TYPES_MAP[ingredient?.unit_measure]}
              </p>
            </div>
            <div className="col-md-3">
              <label htmlFor="name" className="col-form-label">
                Cadastrado em:
              </label>
              <p className="fw-bolder fs-6 text-gray-800">
                {ingredient?.created_at}
              </p>
            </div>
            <div className="col-md-3">
              <label htmlFor="name" className="col-form-label">
                Atualizado em:
              </label>
              <p className="fw-bolder fs-6 text-gray-800">
                {ingredient?.updated_at}
              </p>
            </div>
          </div>
        </div>
      </Container>
      <Alert
        message={`Tem certeza que deseja excluir o registro ${ingredient?.name} ?`}
        onClickCancelButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(Number(ingredient?.id))
        }
        isActive={alertRemoveParent}
      />
    </>
  )
}

export default IngredientView
