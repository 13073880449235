import { FaArrowDown, FaArrowUp } from 'react-icons/fa'
import { MultiSelectContainer } from './styles'
import { useEffect, useRef, useState } from 'react'
import { ReportQuery } from '../../ListTotalItemsByMenuFood'

type MenuFoodTypesMultiSelectProps = {
  menuFoodTypesOptions: IMenuFoodType[]
  report: ReportQuery
  reportQuery: ReportQuery[]
  setReportQuery: React.Dispatch<React.SetStateAction<ReportQuery[]>>
}

export const MenuFoodTypesMultiSelect = ({
  menuFoodTypesOptions,
  report,
  reportQuery,
  setReportQuery
}: MenuFoodTypesMultiSelectProps): JSX.Element => {
  const [open, setOpen] = useState(false)
  const [menuFoodTypesNames, setMenuFoodTypesNames] = useState<string[]>([])

  const addMenuFoodTypeId = (menuFoodTypeId: number) => {
    report.menuFoodTypesIds.push(menuFoodTypeId)
    const copy = reportQuery.map(b => ({
      ...b
    }))
    setReportQuery(copy)
  }

  const removeMenuFoodTypeId = (menuFoodTypeIdToDelete: number) => {
    report.menuFoodTypesIds = report.menuFoodTypesIds.filter(
      institutionId => institutionId !== menuFoodTypeIdToDelete
    )
    const copy = reportQuery.map(b => ({
      ...b
    }))
    setReportQuery(copy)
  }

  const ref = useRef<any>()

  const handleClickOutside = (e: MouseEvent) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setOpen(false)
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [ref])

  useEffect(() => {
    const names = report.menuFoodTypesIds.map(
      id => menuFoodTypesOptions.find(option => option.id === id).name
    )
    setMenuFoodTypesNames(names)
  }, [menuFoodTypesOptions, report])

  return (
    <MultiSelectContainer className="row mb-6" ref={ref}>
      <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
        Selecione os cardápios
      </label>
      <div
        style={{ position: 'relative' }}
        onClick={() => setOpen(prev => !prev)}
      >
        <textarea
          rows={2}
          className="form-control form-control-solid pl-2"
          value={menuFoodTypesNames.join(', ')}
        />
        <div className="arraw-container">
          <div style={{ display: open ? 'none' : 'block' }}>
            <FaArrowDown />
          </div>
          <div style={{ display: !open ? 'none' : 'block' }}>
            <FaArrowUp />
          </div>
        </div>
      </div>
      <div className="items-container">
        <div
          style={{ display: open ? 'block' : 'none' }}
          className="items-container-inner"
        >
          {menuFoodTypesOptions?.map(menuFoodType => (
            <div className="list-item" key={menuFoodType.id}>
              <input
                type="checkbox"
                name={`menu_foods_ids${menuFoodType.id}${report.id}`}
                id={`menu_foods_ids${menuFoodType.id}${report.id}`}
                value={menuFoodType.id}
                onChange={e =>
                  e.target.checked
                    ? addMenuFoodTypeId(Number(e.target.value))
                    : removeMenuFoodTypeId(Number(e.target.value))
                }
              />
              <label
                htmlFor={`menu_foods_ids${menuFoodType.id}${report.id}`}
                className="mx-2"
              >
                {menuFoodType.name}
              </label>
            </div>
          ))}
        </div>
      </div>
    </MultiSelectContainer>
  )
}
