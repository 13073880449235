import Form from 'components/Form'
import { useUpdateDataTable } from 'hooks/dataTable'
import { useLoading } from 'hooks/loading'
import { useToast } from 'hooks/toast'
import moment from 'moment'
import { useState } from 'react'
import api from 'services/api'
import { deleteEmptyFields } from 'utlis/deleteEmptyFields'
import { v4 as uuidv4 } from 'uuid'
import { IComposition, useMenuFoodContext } from '../../contexts'
import {
  apiCreateMenuFoodDishes,
  apiUpdateMenuFoodDishes
} from '../../domain/api'
import { FormAddNewItem } from '../FormAddNewItem'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: IMenuFoodData & {
    idUpdate: number
  }
  menuFoodId?: number
  actualMenuFoodDish?: number
  typeForm: 'create' | 'update'
}

export const FormAddItem = ({
  isOpenInModal,
  typeForm,
  menuFoodId,
  actualMenuFoodDish
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const [defaultValues, setDefaultValues] = useState<any>()
  const [hasUpdated, setHasUpdated] = useState(false)
  const {
    checkActualErrors,
    newComposition,
    setNewComposition,
    composition: Composition
  } = useMenuFoodContext()
  const { updateDataTable } = useUpdateDataTable()
  const formAddNewItem = FormAddNewItem(typeForm)
  console.log(actualMenuFoodDish, 'actualMenuFoodDishId')

  const { activeLoading, disableLoading, loading } = useLoading()

  const onSubmitForm = async (data: {
    composition: IComposition[]
    date: string | Date
  }) => {
    const hasItemError = checkActualErrors()

    if (hasItemError) {
      return
    }
    const hasDate = Composition.flat().some(comp => {
      const dateComp = moment(comp.date, 'DD/MM/YYYY')
      return moment(dateComp).isSame(data.date, 'day')
    })

    if (hasDate && typeForm === 'create') {
      addToast({
        type: 'error',
        title: 'Essa data já está cadastrada no sistema',
        description:
          'Por favor escolha outra data ou edite a data na tabela anterior.'
      })
      return
    }

    data = deleteEmptyFields(data)

    const dishes_info = newComposition.map(dish => {
      return {
        dish_id: dish.dish_id,
        dish_amount: dish.dish_amount,
        is_default_dish: dish.is_default_dish,
        dish_name: dish.dish_name,
        dish_type_name: dish.dish_type_name,
        dish_type_id: dish.dish_type_id
      }
    })

    if (newComposition.some(({ dish_name }) => dish_name === undefined)) {
      addToast({
        type: 'error',
        title: 'Por favor preencha todos os campos',
        description:
          'Por favor preencha todos os campos. A seleção de um item é obrigatória.'
      })
      return
    }
    if (
      newComposition.some(({ dish_name, dish_type_name }, index) => {
        return newComposition.some(
          (newComp, actualIndex) =>
            newComp.dish_name === dish_name &&
            newComp.dish_type_name === dish_type_name &&
            index !== actualIndex
        )
      })
    ) {
      addToast({
        type: 'error',
        title: 'Por favor preencha todos os campos',
        description:
          'Não é possível o registro do mesmo item duplicado, por favor, remova o item duplicado.'
      })
      return
    }

    if (isOpenInModal) {
      const { handleOnClose } = isOpenInModal
      if (typeForm === 'update') {
        const dataUpdate = {
          dishes_info: JSON.stringify(dishes_info)
        }
        try {
          activeLoading()
          await api.put(
            apiUpdateMenuFoodDishes(String(actualMenuFoodDish)),
            dataUpdate
          )
          updateDataTable()
          setHasUpdated(true)
          disableLoading()
          setNewComposition([
            {
              id: uuidv4(),
              dish_id: undefined,
              dish_type_id: undefined,
              dish_amount: undefined,
              dish_name: undefined,
              is_default_dish: undefined,
              date: undefined,
              dish_type_name: undefined
            }
          ])
          handleOnClose()
          addToast({
            type: 'success',
            title: 'Registro atualizado',
            description: 'Registro alterado com sucesso'
          })
        } catch (err: any) {
          disableLoading()

          console.log(err.response.data)
          handleOnClose()
          addToast({
            type: 'error',
            title: 'Erro ao atualizar o registro',
            description:
              'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
          })
        }
      } else {
        const newMenuDish = {
          menu_food_id: +menuFoodId,
          dishes_info: JSON.stringify(dishes_info),
          date: data.date
        }

        try {
          activeLoading()
          await api.post(apiCreateMenuFoodDishes(), newMenuDish)
          updateDataTable()
          setHasUpdated(true)
          disableLoading()
          handleOnClose()
          addToast({
            type: 'success',
            title: 'Registro atualizado',
            description: 'Registro criado com sucesso'
          })
        } catch (err: any) {
          disableLoading()
          handleOnClose()
          addToast({
            type: 'error',
            title: 'Erro ao atualizar o registro',
            description:
              'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
          })
        }
      }

      //   isOpenInModal.handleOnClose()
    }
  }
  return (
    <Form
      onSubmit={onSubmitForm}
      setReset
      defaultValues={{
        ...defaultValues
      }}
    >
      <div>{formAddNewItem}</div>
      <div className="d-flex justify-content-end">
        <button className="btn btn-primary" type="submit" disabled={loading}>
          Salvar
        </button>
      </div>
    </Form>
  )
}
