/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Form, { Select, Textarea } from '../../../../../../components/Form'
import { useUpdateDataTable } from '../../../../../../hooks/dataTable'
import { useLoading } from '../../../../../../hooks/loading'
import { useToast } from '../../../../../../hooks/toast'

import api from 'services/api'
import { deleteEmptyFields } from 'utlis/deleteEmptyFields'
import { v4 as uuidv4 } from 'uuid'
import {
  DateInput,
  DateInput as DatePicker
} from '../../../../../../components/Form/date'
import {
  apiCreate,
  apiListInstitutions,
  apiListMenuFoodTypes,
  apiUpdate
} from '../../domain/api'
import { nameActions } from '../../domain/info'
import { FormContainer } from './styles'

import axios from 'axios'
import moment from 'moment'
import { ISelectFormInstitution, useMenuFoodContext } from '../../contexts'
import { IconRemove } from '../HasComposition/Table/style'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: IMenuFoodData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
  isChild?: boolean
  original_menu_food_id?: number
  setMenuFood?: React.Dispatch<React.SetStateAction<IMenuFoodData>>
}

export const FormMenuFood = ({
  isOpenInModal,
  initialValues,
  typeForm,
  isChild,
  original_menu_food_id,
  setMenuFood
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const [defaultValues, setDefaultValues] = useState<IMenuFoodData>(undefined)
  const [menuFoodTypes, setMenuFoodTypes] = useState<IMenuFoodType[]>([])
  const [allInstitutions, setAllInstitutions] = useState<IInstitutionData[]>([])
  // const [hasItems, setHasItems] = useState<string>()

  const [menuFoodParents, setMenuFoodParents] = useState<
    { id: number; name: string }[]
  >([])

  const {
    addInstitutionForm,
    removeOneInstitution,
    selectFormsInstitutions,
    composition,
    updateSelectFormsInstitutions,
    setHasItemsSelect,
    hasItemsSelect,
    clearInstitutionsAndItems,
    setComposition,
    insertDefaultInstitutions,
    isItemsParent,
    setIsItemsParent
  } = useMenuFoodContext()

  // useEffect(() => {
  //   clearInstitutionsAndItems()
  // }, [])

  const [institutionsIndexDuplicatedId, setInstitutionsIndexDuplicatedId] =
    useState<ISelectFormInstitution[]>([])

  const [hasUpdated, setHasUpdated] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<Date | string>()
  const [endDate, setEndDate] = useState<Date | string>()

  const filterParentMenuFood = (data: IMenuFoodType[]) => {
    const allMenuFoods: { id: number; name: string }[] = []
    data.forEach(type => {
      type.menu_foods
        .filter(menuFood => menuFood.is_items_parent && menuFood.has_items)
        .forEach(menuFood => {
          const a = {
            id: menuFood.id,
            name: type.name
          }
          allMenuFoods.push(a)
        })
    })

    const uniqMenuFoods = allMenuFoods.filter(
      (value, index, self) =>
        index === self.findIndex(t => t.name === value.name)
    )

    setMenuFoodParents(uniqMenuFoods)
  }

  useEffect(() => {
    api
      .get(apiListMenuFoodTypes())
      .then(res => {
        const orderedData = res.data.sort(
          (a: IMenuFoodType, b: IMenuFoodType) => a.name.localeCompare(b.name)
        )
        setMenuFoodTypes(orderedData)
        filterParentMenuFood(orderedData)
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: 'Erro ao carregar as refeições',
          description:
            'Houve um erro ao carregar as refeições disponíveis no banco de dados.'
        })
      })

    api
      .get(apiListInstitutions())
      .then(res => {
        const orderedData = res.data.sort(
          (a: IInstitutionData, b: IInstitutionData) =>
            a.company_name.localeCompare(b.company_name)
        )
        setAllInstitutions(orderedData)
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: 'Erro ao carregar as instituições',
          description:
            'Houve um erro ao carregar as instituições disponíveis no banco de dados.'
        })
      })
  }, [addToast])

  const minDate = new Date()
  minDate.setDate(minDate.getDate() - 61)

  const last30Days = new Date()
  last30Days.setDate(last30Days.getDate() - 31)

  useEffect(() => {
    if (initialValues?.id && !hasUpdated) {
      if (
        initialValues?.start_validity &&
        typeof initialValues.start_validity === 'string'
      ) {
        const date = moment(
          initialValues?.start_validity,
          'DD/MM/YYYY'
        ).toDate()
        initialValues.start_validity = date
      }
      if (
        initialValues?.end_validity &&
        typeof initialValues.end_validity === 'string'
      ) {
        const date = moment(initialValues?.end_validity, 'DD/MM/YYYY').toDate()
        initialValues.end_validity = date
      }
      setDefaultValues({
        ...initialValues,
        contracts_active: initialValues.contracts_active ? 'true' : 'false',
        institutions_id: initialValues.menu_institutions.map(
          menuInstitution => ({
            current_year_start: menuInstitution.current_year_start
              ? moment(
                  menuInstitution.current_year_start,
                  'DD/MM/YYYY HH:mm:ss'
                )
                  .add(3, 'h')
                  .toDate()
              : undefined,
            current_year_end: menuInstitution.current_year_end
              ? moment(menuInstitution.current_year_end, 'DD/MM/YYYY HH:mm:ss')
                  .add(3, 'h')
                  .toDate()
              : undefined
          })
        )
      })
    }
  }, [initialValues, hasUpdated])

  useEffect(() => {
    initialValues?.has_items
      ? setHasItemsSelect('true')
      : setHasItemsSelect('false')
    initialValues?.is_items_parent
      ? setIsItemsParent('true')
      : setIsItemsParent('false')
    if (!initialValues?.has_items) {
      setComposition([
        {
          id: uuidv4(),
          dish_id: undefined,
          dish_type_id: undefined,
          dish_amount: undefined,
          dish_name: undefined,
          is_default_dish: undefined,
          date: undefined,
          dish_type_name: undefined
        }
      ])
    }
  }, [initialValues?.has_items])
  const { activeLoading, disableLoading } = useLoading()

  const onSubmitForm = async (data: IMenuFoodDataCreate) => {
    const id = initialValues?.idUpdate
    if (
      data.has_items === 'true' ||
      (typeof data.has_items === 'boolean' && data.has_items)
    ) {
      data.has_items = true
    } else {
      data.has_items = false
    }
    if (
      data.is_items_parent === 'true' ||
      (typeof data.is_items_parent === 'boolean' && data.is_items_parent)
    ) {
      data.is_items_parent = true
    } else {
      data.is_items_parent = false
    }
    data.contracts_active = data.contracts_active === 'true'

    data.name = 'R'
    data = deleteEmptyFields(data)

    data.menu_food_type_id = +data.menu_food_type_id
    data.institutions_id = data.institutions_id.filter(has => has)

    if (data.is_items_parent === true) {
      data.items_parent_id = null
    }
    try {
      if (typeForm === 'create') {
        if (isOpenInModal) {
          let dataCreate
          const { handleOnClose } = isOpenInModal

          if (isChild) {
            dataCreate = {
              ...data,
              original_menu_food_id
            }
          } else {
            dataCreate = {
              ...data
            }
          }

          activeLoading()

          try {
            const createdMenuFood = await api.post<IMenuFoodData>(
              apiCreate(),
              dataCreate
            )
            updateDataTable()
            handleOnClose()
            disableLoading()
            if (isChild) {
              setMenuFood(old => {
                const newMenuFood = { ...old }
                const menuFoodRelatedWithDateThreated = {
                  ...createdMenuFood.data,
                  start_validity: moment(
                    createdMenuFood.data.start_validity,
                    'YYYY-MM-DD'
                  ).format('DD/MM/YYYY'),
                  end_validity: moment(
                    createdMenuFood.data.end_validity,
                    'YYYY-MM-DD'
                  ).format('DD/MM/YYYY')
                }
                newMenuFood.menu_foods_related = [
                  ...newMenuFood.menu_foods_related,
                  menuFoodRelatedWithDateThreated
                ]
                return newMenuFood
              })
            }
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
          } catch (error) {
            if (axios.isAxiosError(error)) {
              addToast({
                type: 'error',
                title: 'Erro ao adicionar o registro',
                description: error.response.data.message
              })
            }
            updateDataTable()
            handleOnClose()
            disableLoading()
          }
        } else {
          try {
            const dataCreate = {
              ...data
            }

            activeLoading()
            await api.post(apiCreate(), dataCreate)
            updateDataTable()
            disableLoading()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
            history.push(nameActions.read.to)
          } catch (err: any) {
            console.log(err.response.data, 'here')
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            disableLoading()
            updateDataTable()
          }
        }
      } else {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal

          const dataUpdate = {
            ...data
          }

          try {
            activeLoading()

            const updatedMenuFood = await api.put<IMenuFoodData>(
              apiUpdate(String(id)),
              dataUpdate
            )

            if (isChild) {
              setMenuFood(old => {
                const newMenuFood = { ...old }
                const menuFoodRelatedWithDateThreated = {
                  ...updatedMenuFood.data,
                  start_validity: updatedMenuFood.data.start_validity,
                  end_validity: updatedMenuFood.data.end_validity
                }
                const indexMenuFoodRelated =
                  newMenuFood.menu_foods_related.findIndex(
                    element => element.id === menuFoodRelatedWithDateThreated.id
                  )

                newMenuFood.menu_foods_related[indexMenuFoodRelated] =
                  menuFoodRelatedWithDateThreated
                return newMenuFood
              })
            }

            updateDataTable()
            setHasUpdated(true)
            disableLoading()
            handleOnClose()
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (err: any) {
            disableLoading()
            handleOnClose()
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        } else {
          const dataUpdate = {
            ...data
          }
          try {
            activeLoading()
            const res = await api.put(apiUpdate(String(id)), dataUpdate)
            // setDefaultValues(res.data)
            updateDataTable()
            setHasUpdated(true)
            disableLoading()

            history.push(nameActions.read.to)
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (err: any) {
            history.push(nameActions.read.to)
            console.log(err.response.data, 'here')

            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        if (isOpenInModal) isOpenInModal.handleOnClose()
      }
    }
  }
  return (
    <Form
      onSubmit={onSubmitForm}
      setReset
      defaultValues={{
        ...defaultValues
      }}
    >
      <div className="card mb-5 mb-xl-10">
        <FormContainer className="form-body">
          <div className="row">
            <Select
              className="col-md-3"
              name="menu_food_type_id"
              label="Refeição"
              options={
                typeForm === 'create'
                  ? menuFoodTypes
                      .filter(menuFoodType => menuFoodType.is_active)
                      .map(menuFoodType => ({
                        name: menuFoodType.name,
                        value: menuFoodType.id
                      }))
                  : menuFoodTypes.map(menuFoodType => ({
                      name: menuFoodType.name,
                      value: menuFoodType.id
                    }))
              }
              blank
              rules={{ required: true }}
            />
            <DatePicker
              label="Vigência inicial"
              className="col-md-3"
              name="start_validity"
              rules={{ required: true }}
              value={startDate as string}
              selected={
                (startDate as Date) ||
                (defaultValues?.start_validity as Date) ||
                undefined
              }
              onChange={date => setStartDate(date)}
              controlled
            />
            <DatePicker
              label="Vigência final"
              className="col-md-3"
              name="end_validity"
              rules={{ required: true }}
              value={endDate as string}
              selected={
                (endDate as Date) ||
                (defaultValues?.end_validity as Date) ||
                undefined
              }
              onChange={date => setEndDate(date)}
              minDate={
                (startDate as Date) ||
                (defaultValues?.start_validity as Date) ||
                undefined
              }
              controlled
            />
            <Select
              className=" col-md-3"
              name="has_items"
              label="Possui itens?"
              value={hasItemsSelect}
              onChange={event => setHasItemsSelect(event.target.value)}
              options={[
                {
                  name: 'Sim',
                  value: 'true'
                },
                {
                  name: 'Não',
                  value: 'false'
                }
              ]}
              blank
            />
            {hasItemsSelect === 'true' && (
              <>
                <Select
                  className=" col-md-3"
                  name="is_items_parent"
                  label="É cardápio principal?"
                  value={isItemsParent}
                  onChange={event => setIsItemsParent(event.target.value)}
                  options={[
                    {
                      name: 'Sim',
                      value: 'true'
                    },
                    {
                      name: 'Não',
                      value: 'false'
                    }
                  ]}
                  blank
                />
                {isItemsParent === 'false' && (
                  <Select
                    className=" col-md-3"
                    name="items_parent_id"
                    label="Cardápio pai"
                    options={menuFoodParents.map(item => ({
                      name: item.name,
                      value: item.id
                    }))}
                    blank
                  />
                )}
              </>
            )}
            <Select
              className=" col-md-3"
              name="contracts_active"
              label="Contratação ativa?"
              options={[
                {
                  name: 'Sim',
                  value: 'true'
                },
                {
                  name: 'Não',
                  value: 'false'
                }
              ]}
              blank
            />
          </div>

          <div className="row">
            {typeForm === 'update' && (
              <Select
                className=" col-md-3"
                name="is_active"
                label="Ativo"
                options={[
                  {
                    name: 'Sim',
                    value: 'true'
                  },
                  {
                    name: 'Não',
                    value: 'false'
                  }
                ]}
                blank
                defaultValue={'true'}
                rules={{ required: true }}
              />
            )}
          </div>
          <div className="row">
            <Textarea
              name="description"
              className=" col-md-12"
              label="Descrição"
              style={{ minHeight: 120 }}
              rules={{ required: true, position: 'left' }}
            />
          </div>
          <div className="separator my-5" />
          <div style={{ margin: '0 -1rem' }}>
            <h3
              className="col-form-label fw-bold fs-6 bg-secondary"
              style={{ padding: '0.7rem 1rem' }}
            >
              Instituições
            </h3>
          </div>
          {selectFormsInstitutions.map(
            (
              {
                id,
                institution_id,
                current_year_start,
                current_year_end,
                deleted
              },
              index
            ) => {
              return (
                !deleted && (
                  <div className="d-flex align-items-center gap-5" key={id}>
                    <Select
                      className=" col-md-3"
                      name={`institutions_id.${index}.institution_id`}
                      label="Instituição"
                      options={
                        typeForm === 'create'
                          ? allInstitutions
                              .filter(institution => institution.is_active)
                              .map(institution => ({
                                name: institution.company_name,
                                value: institution.id
                              }))
                          : allInstitutions.map(institution => ({
                              name: institution.company_name,
                              value: institution.id
                            }))
                      }
                      defaultValue={''}
                      blank
                      value={institution_id}
                      onChange={event => {
                        updateSelectFormsInstitutions(
                          id,
                          'institution_id',
                          Number(event.target.value)
                        )
                        setInstitutionsIndexDuplicatedId(old => {
                          return old.filter(element => element.id !== id)
                        })
                      }}
                      customizedError={
                        institutionsIndexDuplicatedId?.some(
                          duplicatedId => duplicatedId.id === id
                        )
                          ? 'Essa instituição já foi selecionada!'
                          : ''
                      }
                      controlled
                    />

                    {selectFormsInstitutions.length > 1 && (
                      <div className="pt-6">
                        <IconRemove onClick={() => removeOneInstitution(id)} />
                      </div>
                    )}
                    <DateInput
                      name={`institutions_id.${index}.current_year_start`}
                      label="Inicio ano letivo"
                      onChange={date => {
                        updateSelectFormsInstitutions(
                          id,
                          'current_year_start',
                          date
                        )
                      }}
                      selected={current_year_start}
                      controlled
                    />
                    <DateInput
                      name={`institutions_id.${index}.current_year_end`}
                      label="Final ano letivo"
                      onChange={date => {
                        updateSelectFormsInstitutions(
                          id,
                          'current_year_end',
                          date
                        )
                      }}
                      selected={current_year_end}
                      controlled
                    />
                  </div>
                )
              )
            }
          )}
          <div className="separator my-5" />
          <button
            type="button"
            className="btn btn-dark btn-sm mb-5"
            onClick={() => addInstitutionForm()}
          >
            + Instituição
          </button>
        </FormContainer>
        <div className="card-footer d-flex justify-content-end py-6 px-9">
          <button type="submit" className="btn btn-primary">
            Salvar
          </button>
        </div>
      </div>
    </Form>
  )
}
