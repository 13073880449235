import React, { useEffect, useState } from 'react'
import {
  FaExclamationTriangle,
  FaFileContract,
  FaFileInvoiceDollar,
  FaFileSignature,
  FaMoneyCheck,
  FaPlayCircle
} from 'react-icons/fa'
import { PageTitle } from '../../assets/layout/core'
import { Card } from './styles'

import dollar from './dollar.svg'
import sackXMark from './sack-xmark.svg'
import moment from 'moment'
import api from 'services/api'

interface IResponseDashboard {
  contract: {
    today: number
    startToday: number
    waiting: number
    active: number
  }
  payment: {
    overdue: number
    open: number
    today: number
    month: number
  }
}

export const Dashboard: React.FC = () => {
  const today = moment().format('YYYY-MM-DD')
  const minMonth = moment().startOf('month').format('YYYY-MM-DD')
  const maxMonth = moment().endOf('month').format('YYYY-MM-DD')

  const [dashboard, setDashboard] = useState<IResponseDashboard>()

  useEffect(() => {
    ;(async () => {
      const { data } = await api.get<IResponseDashboard>('dashboard')
      setDashboard(data)
    })()
  }, [])
  return (
    <div className="container-fluid">
      <PageTitle>Informações Gerenciais</PageTitle>
      <h2>Contratos</h2>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Card
          color="#0095D9"
          href={`/commercial/contracts?created_at=${today}`}
        >
          <FaFileSignature />
          <p>{dashboard?.contract?.today}</p>
          <p>Contratos do dia</p>
        </Card>
        <Card
          color="#ffc000"
          href={`/commercial/contracts?start_date=${today}`}
        >
          <FaPlayCircle />
          <p>{dashboard?.contract?.startToday}</p>
          <p>Contratos iniciando hoje</p>
        </Card>
        <Card
          color="#F5811E"
          href="/commercial/contracts?situation=aguardando-pagamento"
        >
          <FaFileInvoiceDollar />
          <p>{dashboard?.contract?.waiting}</p>
          <p>Contratos aguardando pagamento</p>
        </Card>
        <Card color="#01A54F" href="/commercial/contracts?situation=ativo">
          <FaFileContract />
          <p>{dashboard?.contract?.active}</p>
          <p>Contratos ativos</p>
        </Card>
      </div>
      <h2>Pagamentos</h2>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Card color="#ED1B24" href={'/financial/financialMoviments?overdue=1'}>
          <img src={sackXMark} alt="" />
          <p>{dashboard?.payment?.overdue}</p>
          <p>Pagamentos vencidos</p>
        </Card>
        <Card color="#F5811E" href={'/financial/financialMoviments?open=1'}>
          <FaExclamationTriangle />
          <p>{dashboard?.payment?.open}</p>
          <p>Pagamentos a vencer</p>
        </Card>
        <Card
          color="#0095D9"
          href={`/financial/financialMoviments?date_column=settlement_date&start_date=${today}&end_date=${today}&situation=1`}
        >
          <FaMoneyCheck />
          <p>{dashboard?.payment?.today}</p>
          <p>Pagamentos do dia</p>
        </Card>
        <Card
          color="#00A54F"
          href={`/financial/financialMoviments?date_column=settlement_date&start_date=${minMonth}&end_date=${maxMonth}&situation=1`}
        >
          <img src={dollar} alt="" />
          <p>{dashboard?.payment?.month}</p>
          <p>Pagamentos do mês</p>
        </Card>
      </div>
    </div>
  )
}

export default Dashboard
