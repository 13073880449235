import React, { useCallback, useState, useEffect } from 'react'
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect
} from 'react-router-dom'

import DefaultLayout from '../pages/_layouts/default'

import { useAuth } from '../hooks/auth'

import { useToast } from '../hooks/toast'
import { Loading } from '../components/Loading'
import { UnauthenticatedRouteError } from '../components/UnauthenticatedRouteError'
import api from '../services/api'

interface RouteProps extends ReactDOMRouteProps {
  component: React.ComponentType
}

const PrivateRoutes: React.FC<RouteProps> = ({
  path,
  component: Component,
  ...rest
}) => {
  const [permissions, setPermissions] = useState(true)
  const [loading, setLoading] = useState(false)
  const { userLogged, user, setHistory } = useAuth()
  const { addToast } = useToast()
  const Layout = DefaultLayout

  const handleSetPermissions = useCallback(async () => {
    if (path) {
      const [, baseUrl, pathUrl] = path.toString().split('/')
      if (path.includes('create')) {
        const response = await api.post('users/checkPermission', {
          method: 'POST',
          originalUrl: `/${baseUrl}/${pathUrl}`,
          role_id: user.role_id,
          user_id: user.id,
          userType: 'User'
        })
        setPermissions(response.data.approved)
        setLoading(false)
        return
      }
      if (path.includes('update')) {
        const response = await api.post('users/checkPermission', {
          method: 'PUT',
          originalUrl: `/${baseUrl}/${pathUrl}`,
          role_id: user.role_id,
          user_id: user.id,
          userType: 'User'
        })
        setPermissions(response.data.approved)
        setLoading(false)
        return
      }
      if (path.includes('view')) {
        const response = await api.post('users/checkPermission', {
          method: 'POST',
          originalUrl: `/${baseUrl}/${pathUrl}`,
          role_id: user.role_id,
          user_id: user.id,
          userType: 'User'
        })
        setPermissions(response.data.approved)
        setLoading(false)
        return
      }
      const response = await api.post('users/checkPermission', {
        method: 'POST',
        originalUrl: `/${baseUrl}/${pathUrl}`,
        role_id: user.role_id,
        user_id: user.id,
        userType: 'User'
      })

      setPermissions(response.data.approved)
      setLoading(false)
    } else {
      setPermissions(true)
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path])

  useEffect(() => {
    if (userLogged()) {
      handleSetPermissions()
    } else {
      setHistory(path.toString())
    }
  }, [handleSetPermissions, path, setHistory, user, userLogged])

  if (!userLogged()) {
    return (
      <ReactDOMRoute
        {...rest}
        render={() => {
          addToast({
            title: 'Pagina não autorizada',
            description:
              'O usuario não tem autorização para acessar esta pagina',
            type: 'error'
          })
          return <Redirect to="/" />
        }}
      />
    )
  }

  if (!loading) {
    if (permissions) {
      return (
        <ReactDOMRoute
          {...rest}
          render={() => {
            return (
              <Layout>
                <Component {...rest} />
              </Layout>
            )
          }}
        />
      )
    } else {
      return (
        <ReactDOMRoute
          {...rest}
          render={() => {
            return <UnauthenticatedRouteError />
          }}
        />
      )
    }
  }
  return <Loading isActive={loading} />
}

export default PrivateRoutes
