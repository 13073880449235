export const headers = [
  { name: 'Tipo', field: 'dish_type.name', sortable: true },
  { name: 'Nome', field: 'name', sortable: true },
  { name: 'Identificador', field: 'identifier', sortable: true },
  { name: 'Ativo', field: 'is_active', sortable: true },
  { name: 'Cadastrado em', field: 'created_at', sortable: false },
  { name: 'Ações', field: 'actions', sortable: false }
]

export const headersIngredients = [
  { name: 'Nome', field: 'ingredient.name', sortable: true },
  {
    name: 'Unidade de medida',
    field: 'ingredient.unit_measure',
    sortable: true,
    custom: true
  },
  {
    name: 'Medida',
    field: 'measure',
    sortable: true,
    custom: true
  },
  {
    name: 'Quantidade',
    field: 'quantity',
    sortable: true
  },
  {
    name: 'Descrição',
    field: 'description',
    sortable: true
  },
  { name: 'Ações', field: 'actions', sortable: false }
]
