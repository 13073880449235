import styled from 'styled-components'

export const FilterContainer = styled.div`
  & > form {
    width: 100%;
  }
`

export const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    margin-top: 1em;
    background-color: #f3f3f3;
    svg {
      margin-right: 8px;
    }
    &:hover {
      background-color: #d3d3d3;
    }
  }
`
