import Form, { Textarea } from 'components/Form'

import { useToast } from 'hooks/toast'
import api from 'services/api'
import { apiCancelFinancialMoviment } from '../../domain/api'

interface ICancelFinancialMovimentForm {
  id: string
  isOpenInModal: {
    handleOnClose: () => void
  }
}

interface ICancelFinancialMovimentData {
  cancellation_reason: string
  cancellation_description?: string
}

const CancelFinancialMovimentForm = ({
  id,
  isOpenInModal
}: ICancelFinancialMovimentForm) => {
  const { addToast } = useToast()

  const onSubmitForm = (data: ICancelFinancialMovimentData) => {
    const dataToCancel = {
      ...data
    }
    ;(async () => {
      await api
        .post(apiCancelFinancialMoviment(id), dataToCancel)
        .then(() => {
          const { handleOnClose } = isOpenInModal
          handleOnClose()
          addToast({
            type: 'success',
            title: 'Pagamento cancelado com sucesso'
          })
        })
        .catch(err => {
          console.log(err.response.data)
          addToast({
            type: 'error',
            title: 'Error ao cancelar o pagamento',
            description:
              'Houve um error ao cancelar o pagamento, tente novamente mais tarde!'
          })
        })
    })()
  }

  return (
    <Form onSubmit={onSubmitForm}>
      <Textarea
        name="cancellation_description"
        className=" col-md-12"
        label="Descrição"
        style={{ minHeight: 120 }}
        defaultValue=""
      />
      <button type="submit" className="btn btn-primary float-end">
        Confirmar
      </button>
    </Form>
  )
}

export default CancelFinancialMovimentForm
