import { useEffect, useState } from 'react'
import Form, {
  Input,
  Select,
  Textarea
} from '../../../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../../hooks/toast'
import { useLoading } from '../../../../../../hooks/loading'
import {
  apiCreateDishesIngredients,
  apiUpdateDishesIngredients
} from '../../domain/api'
import { nameActions } from '../../domain/info'
import { FormContainer } from './styles'
import api from 'services/api'
import { SearchComponentIngredient } from './SearchComponentIngredient'
import { genericMaskWithTwoZeroWithPoint } from 'utlis/mask'

type TypesFormProps = {
  handleClickOnCloseModal: () => void
  initialValues?: {
    dish_id: number
    dish_ingredient?: IDishIngredient
  }
  typeForm: 'create' | 'update'
  ingredients: IIngredient[]
}

export const FormAddIngredient = ({
  handleClickOnCloseModal,
  initialValues,
  typeForm,
  ingredients
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const { activeLoading, disableLoading } = useLoading()

  const [defaultValues, setDefaultValues] = useState<IDishIngredient>()
  const [ingredient, setIngredient] = useState<number>()
  const [ingredientName, setIngredientName] = useState<string>()
  const [unitMeasure, setUnitMeasure] = useState<INGREDIENT_TYPES>()
  const [measure, setMeasure] = useState<string>('')

  useEffect(() => {
    if (initialValues.dish_ingredient) {
      setDefaultValues({
        ...initialValues.dish_ingredient,
        quantity: genericMaskWithTwoZeroWithPoint(
          initialValues.dish_ingredient.quantity
        )
      })
      setUnitMeasure(initialValues.dish_ingredient.ingredient.unit_measure)
      setMeasure(initialValues.dish_ingredient.measure)
      setIngredientName(initialValues.dish_ingredient.ingredient.name)
    }
  }, [initialValues.dish_ingredient])
  const onSubmitForm = async (data: any) => {
    if (!ingredient && typeForm === 'create') {
      addToast({
        type: 'info',
        title: 'Selecione um ingrediente'
      })
      return
    }
    try {
      if (typeForm === 'create') {
        try {
          const dataCreate = {
            ...data,
            dish_id: initialValues.dish_id,
            ingredient_id: ingredient,
            measure,
            quantity: data.quantity.replace('.', '').replace(',', '.')
          }

          activeLoading()
          await api.post(apiCreateDishesIngredients(), dataCreate)
          disableLoading()
          addToast({
            type: 'success',
            title: 'Registro criado',
            description: 'Registro criado com sucesso'
          })
          handleClickOnCloseModal()
        } catch (err: any) {
          addToast({
            type: 'error',
            title: 'Erro ao adicionar o registro',
            description:
              'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
          })
          disableLoading()
        }
      } else {
        const dataUpdate = {
          measure,
          quantity: data.quantity.replace('.', '').replace(',', '.'),
          description: data.description
        }
        try {
          if (!initialValues.dish_ingredient) {
            return
          }
          const id = initialValues.dish_ingredient.id
          activeLoading()
          await api.put(apiUpdateDishesIngredients(String(id)), dataUpdate)
          disableLoading()
          addToast({
            type: 'success',
            title: 'Item atualizado',
            description: 'Item alterado com sucesso'
          })
          handleClickOnCloseModal()
        } catch (err: any) {
          history.push(nameActions.read.to)
          console.log(err.response.data, 'here')

          addToast({
            type: 'error',
            title: 'Erro ao atualizar o item',
            description:
              'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
          })
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
      }
    }
  }

  const handleSelectIngredient = (
    id?: number,
    unitMeasure?: INGREDIENT_TYPES
  ) => {
    setIngredient(id)
    setUnitMeasure(unitMeasure)
    setMeasure('')
  }

  return (
    <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
      <>
        <div className="card mb-5 mb-xl-10">
          <FormContainer className="form-body">
            <div className="row">
              <div className="col-md-7">
                <SearchComponentIngredient
                  update={typeForm === 'update'}
                  ingredients={ingredients}
                  ingredient={ingredient}
                  ingredientName={ingredientName}
                  handleSelectIngredient={handleSelectIngredient}
                />
              </div>
              {unitMeasure && ['weight', 'volume'].includes(unitMeasure) && (
                <Select
                  name="measure"
                  label="Medida"
                  options={
                    unitMeasure === 'weight'
                      ? [
                          { name: 'Kilo', value: 'Kilo' },
                          { name: 'Grama', value: 'Grama' },
                          { name: 'Miligrama', value: 'Miligrama' }
                        ]
                      : unitMeasure === 'volume'
                      ? [
                          { name: 'Litro', value: 'Litro' },
                          { name: 'Mililitro', value: 'Mililitro' }
                        ]
                      : []
                  }
                  blank
                  value={measure}
                  onChange={({ target }) => setMeasure(target.value)}
                  defaultValue={''}
                  className="col-md-2"
                  rules={{ required: true }}
                />
              )}
              <Input
                name="quantity"
                label="Qtd."
                price
                className="col-md-3"
                rules={{ required: true }}
              />
            </div>
            <div className="row">
              <Textarea
                name="description"
                className=" col-md-12"
                label="Descrição"
                style={{ minHeight: 120 }}
                defaultValue=""
              />
            </div>
          </FormContainer>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button type="submit" className="btn btn-primary">
              Salvar
            </button>
          </div>
        </div>
      </>
    </Form>
  )
}
