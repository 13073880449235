import styled from 'styled-components'

export const MultiSelectContainer = styled.div`
  position: relative;
  .arraw-container {
    position: absolute;
    right: 15px;
    bottom: 10px;
    padding: 3px 5px;

    background-color: #f5f8fa;

    cursor: pointer;
    opacity: 0.9;
  }

  .items-container {
    position: absolute;
    top: 110px;

    z-index: 1;
  }

  .items-container-inner {
    background-color: #f5f8fa;
  }

  .list-item {
    display: flex;
    align-items: start;
  }
`
