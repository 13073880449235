import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Form from 'components/Form'
import DatePicker from 'react-datepicker'
import { FilterContainer, TableHeader } from './styles'
import api from 'services/api'
import Container from 'components/Container'
import { FaDownload } from 'react-icons/fa'
import { useLoading } from 'hooks/loading'
import moment from 'moment'

const ListRevenueByMenuFood = (): JSX.Element => {
  const [date, setDate] = useState<Date>()
  const [parameters, setParameters] = useState<any>()
  const history = useHistory()
  const { activeLoading, disableLoading } = useLoading()

  const onSearch = async () => {
    const query = []
    if (date) {
      query.push(`date=${moment(date).format('YYYY-MM')}`)
    }

    history.replace(
      `/commercial/contractsMenuFoods/revenueByMenuFood?${query.join('&')}`
    )
    if (date) {
      setParameters([
        {
          date: moment(date).format('YYYY-MM-DD')
        }
      ])
    }
  }

  const location = new URLSearchParams(useLocation().search)

  useEffect(() => {
    const date = location.get('date')

    if (date) {
      setDate(moment(date, 'YYYY-MM-DD').toDate())
      setParameters([
        {
          date
        }
      ])
    }
  }, [])

  return (
    <Container
      pageTitle="Listagem"
      breadcrumb={breadcrumbList}
      tools={toolsList}
    >
      <FilterContainer>
        <Form onSubmit={onSearch} className="col-md-12">
          <div className="col-md-12 d-flex">
            <div className="col-md-2 pe-8">
              <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                Mês
              </label>
              <DatePicker
                locale="ptBR"
                className="form-control form-control-solid"
                name="start_validity"
                onChange={date => setDate(date)}
                selected={date}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                showFullMonthYearPicker
              />
            </div>
          </div>
          <footer className="col-md-12 d-flex justify-content-end pb-5 gap-5">
            <button
              type="reset"
              className="btn btn-secondary btn-sm"
              onClick={() => {
                setDate(undefined)
              }}
            >
              LIMPAR
            </button>
            <button type="submit" className="btn btn-primary btn-sm">
              BUSCAR
            </button>
          </footer>
        </Form>
        <hr />
      </FilterContainer>
      {parameters && (
        <>
          <TableHeader>
            <div>
              <button
                type="button"
                className="btn"
                onClick={async () => {
                  activeLoading()
                  api
                    .post(
                      'commercial/contractsMenuFoods/revenueByMenuFoodPdf',
                      {
                        date: moment(date).format('YYYY-MM')
                      },
                      {
                        responseType: 'arraybuffer'
                      }
                    )
                    .then(res => {
                      const a = document.createElement('a')
                      const blob = new Blob([res.data], {
                        type: 'application/pdf'
                      })
                      const url = window.URL.createObjectURL(blob)
                      a.href = url
                      const date = new Date()
                      const today = `${date.getDate()}-${String(
                        date.getMonth() + 1
                      ).padStart(2, '0')}-${date.getFullYear()}`
                      const fileName = `Relatório de receita por cardápio - ${today}.pdf`
                      a.download = fileName
                      a.click()
                      disableLoading()
                    })
                    .catch(() => {
                      disableLoading()
                    })
                }}
              >
                <FaDownload />
                Baixar relatório
              </button>
            </div>
          </TableHeader>
        </>
      )}
    </Container>
  )
}
export default ListRevenueByMenuFood
