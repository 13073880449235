import styled from 'styled-components'
interface IContainerProps {
  error?: boolean
}

export const FormContainer = styled.div``

export const List = styled.ul`
  max-height: 490px;
  width: 400px;
`
export const InputContainer = styled.div<IContainerProps>`
  border-radius: 10px;
  margin-bottom: 1em;

  > label {
    font-weight: normal;
    color: ${({ error }) => error && '#c53030'} !important;
  }

  & > div {
    position: relative;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    display: none;
  }

  input {
    flex: 1;
    background: transparent;
    border: 1px solid #c2cad8;
    padding-right: 30px;
    border-color: ${({ error }) => error && '#c53030'} !important;

    &::placeholder {
      color: #666360;
    }
  }

  svg {
    margin-right: 16px;
  }
`
