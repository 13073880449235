import { useCallback, useState } from 'react'
import Form from 'components/Form'
import { DateInput } from 'components/Form/date'
import { useToast } from 'hooks/toast'
import api from 'services/api'
import { ChangeDueDateFinancial } from '../../View'
import { useLoading } from 'hooks/loading'
import { Loading } from 'components/Loading'

interface IChangeDueDate {
  isOpenInModal: {
    handleOnClose: () => void
  }
  financialMoviment: {
    financialMoviment: ChangeDueDateFinancial
    contract: any
  }
}

interface IChangeDueDateData {
  due_date: string
}

const ChangeDueDate = ({
  isOpenInModal,
  financialMoviment
}: IChangeDueDate) => {
  const { addToast } = useToast()

  const [loading, setIsloading] = useState(false)
  const [dueDate, setDueDate] = useState<Date>()
  const onSubmitForm = useCallback(
    async (data: IChangeDueDateData) => {
      setIsloading(true)
      try {
        const response = await api.put(
          `/financial/financialMoviments/changeDueDate/${financialMoviment?.financialMoviment?.id}`,
          {
            ...data,
            hasCancellation:
              financialMoviment?.financialMoviment?.hasCancellation
          }
        )
        const responseFinancialMoviment = await api.get(
          `/financial/financialMoviments/view/${financialMoviment?.financialMoviment?.id}`
        )
        const linkSource = `data:application/pdf;base64,${response.data}`
        const financialMovimentPayament =
          responseFinancialMoviment.data.financialMovimentsPayments
        const downloadLink = document.createElement('a')
        const fileName = `Cliente - ${
          financialMoviment?.contract.client.name
        } Numero -${
          financialMovimentPayament[financialMovimentPayament.length - 1]
            .document_number
        }.pdf`
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
        isOpenInModal?.handleOnClose?.()
        addToast({
          type: 'success',
          title: 'Boleto emitido com sucesso'
        })
        setIsloading(false)
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Error ao emitir o boleto',
          description:
            'Houve um error ao emitir o boleto, tente novamente mais tarde!'
        })
        setIsloading(false)
      }
    },
    [
      addToast,
      financialMoviment?.contract?.client?.name,
      financialMoviment?.financialMoviment?.hasCancellation,
      financialMoviment?.financialMoviment?.id,
      isOpenInModal
    ]
  )

  return (
    <>
      <Loading isActive={loading} />
      <Form onSubmit={onSubmitForm}>
        <div>
          <DateInput
            label="Data de Vencimento"
            name="due_date"
            className="col-6"
            onChange={date => setDueDate(date)}
            selected={dueDate}
            controlled
          />
          <button type="submit" className="btn btn-primary float-end">
            Confirmar
          </button>
        </div>
      </Form>
    </>
  )
}

export default ChangeDueDate
