import styled from 'styled-components'

export const Card = styled.a<{ color: string }>`
  position: relative;
  width: 320px;
  height: 110px;
  margin: 0px 15px 15px 0px;
  padding: 15px 20px;
  overflow: hidden;
  background-color: ${props => props.color};
  svg {
    position: absolute;
    opacity: 0.4;
    color: #f8f8f8;
    scale: 6;
    left: 18px;
    bottom: 25px;
  }
  img {
    position: absolute;
    opacity: 0.4;
    color: #f8f8f8;
    width: 80px;
    left: -10px;
    bottom: -10px;
  }
  p {
    color: #f8f8f8;
    font-size: 16px;
    font-weight: 300;
    text-align: right;
    :first-of-type {
      color: #f2f2f2;
      font-size: 32px;
      margin-bottom: 0px;
      line-height: 30px;
    }
  }
`
