import React, { useState } from 'react'
import { Input } from '../../../../../../../components/Form'
import { SearchContainer } from './styles'

type SearchComponentIngredientParams = {
  update: boolean
  ingredients: IIngredient[]
  ingredient: number
  ingredientName: string
  handleSelectIngredient: (id?: number, unitMeasure?: INGREDIENT_TYPES) => void
}

export function SearchComponentIngredient({
  update,
  ingredients,
  ingredient,
  ingredientName,
  handleSelectIngredient
}: SearchComponentIngredientParams) {
  const [search, setSearch] = useState('')
  const [selected, setSelected] = useState(false)

  const handleBlur = () => {
    setTimeout(() => {
      if (!selected) {
        setSearch('')
      }
    }, 500)
  }

  return (
    <SearchContainer>
      <div>
        <Input
          label="Ingrediente"
          name={'ingredient_name'}
          id="option"
          onChange={({ target }) => {
            setSearch(target.value)
            setSelected(false)
          }}
          rules={{ required: true }}
          value={search || ingredientName}
          fullControlled
          disabled={update}
        />
        <ul>
          {search !== '' &&
            !selected &&
            ingredients
              .filter(f => f.name?.toLowerCase().includes(search))
              .map(item => (
                <li
                  key={item.id}
                  onClick={() => {
                    handleSelectIngredient(item.id, item.unit_measure)
                    setSearch(item.name)
                    setSelected(true)
                  }}
                >
                  {item.name}
                </li>
              ))}
        </ul>
      </div>
    </SearchContainer>
  )
}
