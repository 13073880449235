export const apiCreate = (): string => '/commercial/contracts/create/'

export const apiGetStudent = (id: string): string =>
  `/commercial/students/view/${id}`

export const apiListMenuFoodTypes = (
  turn: string,
  institution_id: number
): string =>
  `/commercial/menuFoodTypes?period=${turn}&institution_id=${institution_id}`

export const apiListInstitution = (): string => '/commercial/institutions/'

export const apiGetContract = (id: string): string =>
  `/commercial/contracts/view/${id}`

export const apiListMenuFoodDishes = (id: string): string =>
  `/commercial/menuFoods/view/${id}`

export const apiListMenuFoodDishesModal = (
  institution_id: string,
  menu_food_type_id: string
): string =>
  `/commercial/menuFoods?institution_id=${institution_id}&menu_food_type_id=${menu_food_type_id}`

export const apiListPlans = (): string => '/commercial/plans'

export const apiUpdate = (id: string): string =>
  `/commercial/institutions/update/${id}`
export const apiDelete = (id: string): string =>
  `/commercial/institutions/delete/${id}`

export const apiGetPdfContract = (id: string): string =>
  `/commercial/contracts/generatePdf/${id}`

export const apiGetPdfPreviewContract = (): string =>
  '/commercial/contracts/generatePdfPreview'

export const apiCreateFinancialMoviment = () => '/financial/financialMoviments'

export const apiListFinancialMoviment = () => '/financial/financialMoviments'

export const apiGetPdfFinancialMoviment = (id: string) =>
  `/financial/financialMovimentsPayments/${id}`

export const apiCancelFinancialMoviment = (id: string) =>
  `/financial/financialMoviments/cancel/${id}`

export const cancelContract = (id: string) =>
  `/commercial/contracts/cancel/${id}`

export const addContractDiscount = () => '/commercial/contractsDiscounts/create'
export const updateContractDiscount = (id: string) =>
  `/commercial/contractsDiscounts/update/${id}`
export const deleteContractDiscount = (id: string) =>
  `/commercial/contractsDiscounts/delete/${id}`

export const updateFavoriteDay = (id: string) =>
  `/commercial/contracts/updateFavoriteDay/${id}`

export const scheduleCreatePlan = (id: string) =>
  `/commercial/contracts/scheduleCreatePlan/${id}`

export const scheduleUpdatePlan = (id: string) =>
  `/commercial/contracts/scheduleUpdatePlan/${id}`

export const scheduleDeletePlan = (id: string) =>
  `/commercial/contracts/scheduleDeletePlan/${id}`
