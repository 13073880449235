import { useForm } from 'react-hook-form'

interface IChooseItemsForm {
  menuDishes: IMenuDish[]
  selectedDishes: ISelectedDish[]
  setSelectedDishes: React.Dispatch<React.SetStateAction<ISelectedDish[]>>
  handleClickOnClose: () => void
}

interface IDishesByType {
  dish_type_id: number
  dish_type_name: string
  dishes: {
    id: number
    name: string
    is_default_dish: boolean
  }[]
}

const ChooseItemsForm = ({
  menuDishes,
  selectedDishes,
  setSelectedDishes,
  handleClickOnClose
}: IChooseItemsForm) => {
  // [{dish_type: 'Fruta', dish_type_id:1 dishes: [{name: '', id: , is_default_dish: }] }]
  const { register, handleSubmit } = useForm()

  const dishesByType: IDishesByType[] = []
  console.log(menuDishes)
  // menuDishes?.forEach(menu_dish => {
  //   const indexOfDishType = dishesByType.findIndex(
  //     dish => dish.dish_type_id === menu_dish.dish.dish_type_id
  //   )
  //   const actualDish = {
  //     id: menu_dish.dish_id,
  //     name: menu_dish.dish.name,
  //     is_default_dish: menu_dish.is_default_dish
  //   }
  //   if (indexOfDishType === -1) {
  //     const newDishByType = {
  //       dish_type_id: menu_dish.dish.dish_type_id,
  //       dish_type_name: menu_dish.dish.dish_type.name,
  //       dishes: [actualDish]
  //     }
  //     dishesByType.push(newDishByType)
  //   } else {
  //     dishesByType[indexOfDishType].dishes.push(actualDish)
  //   }
  // })

  const onSuccessSubmit = (data: any) => {
    const newSelectedDishes = []
    for (const dishTypeName in data) {
      const actualDishType = dishesByType.find(
        dishType => dishType.dish_type_name === dishTypeName
      )

      let actualDish
      if (data[dishTypeName]) {
        actualDish = actualDishType.dishes.find(dish => {
          return dish.id === +data[dishTypeName]
        })
      } else {
        actualDish = actualDishType.dishes.find(dish => {
          return dish.is_default_dish
        })
      }

      const selectedDish: ISelectedDish = {
        dish_id: actualDish.id,
        dish_type_name: actualDishType.dish_type_name,
        dish_name: actualDish.name,
        dish_type_id: actualDishType.dish_type_id
      }
      newSelectedDishes.push(selectedDish)
    }
    setSelectedDishes(newSelectedDishes)
    handleClickOnClose()
  }
  console.log(selectedDishes, 'asasd')
  return (
    <div>
      <form
        onSubmit={handleSubmit(onSuccessSubmit)}
        className="d-flex gap-4 flex-column"
      >
        {dishesByType.map(dishByType => {
          return (
            <div
              key={dishByType.dish_type_id}
              className="d-flex gap-2 flex-column"
            >
              <h3 className="fs-5">{dishByType.dish_type_name}</h3>
              {dishByType.dishes.map(dish => {
                return (
                  <div key={dish.id} className="d-flex gap-3">
                    <input
                      id={`dish_${dish.id}`}
                      type="radio"
                      {...register(dishByType.dish_type_name)}
                      name={dishByType.dish_type_name}
                      value={dish.id}
                      defaultChecked={selectedDishes?.some(
                        selectedDish => selectedDish.dish_id === +dish.id
                      )}
                    />
                    <label htmlFor={`dish_${dish.id}`}>
                      {dish.name}
                      {dish.is_default_dish}
                    </label>
                  </div>
                )
              })}
            </div>
          )
        })}
        <button className="btn btn-sm btn-primary float-end">Confirmar</button>
      </form>
    </div>
  )
}

export default ChooseItemsForm
