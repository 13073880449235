import { useCallback, useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Container from '../../../../components/Container'
import DataTable from '../../../../components/DataTable'
import Tabs from '../../../../components/Tabs'
import Tab from '../../../../components/Tabs/Tab'
import { useLoading } from '../../../../hooks/loading'
import { useToast } from '../../../../hooks/toast'
import api from '../../../../services/api'
import {
  apiGetContract,
  apiGetPdfContract,
  apiGetPdfFinancialMoviment,
  deleteContractDiscount
} from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import { nameActions } from '../domain/info'

import ViewInfoLabel from 'components/ViewInfoLabel'
import { useUpdateDataTable } from 'hooks/dataTable'
import { makeStringWithAnd } from 'utlis/makeAStringWithAnd'
import { currencyMask, percentualMask } from 'utlis/mask'
import { sortWeek } from 'utlis/sortWeek'
import { cancelContract, toolsPrintPdf } from '../domain/tools/view'
import { LinkContainer, MenuTableRow } from './styles'
import Modal from 'components/Modal'
import CancelContractForm from '../components/CancelContractForm'
import ChangeDueDate from '../components/ChangeDueDate'
import DownloadFinancialMovimentManual from '../components/DownloadFinancialMovimentManual'
import AddDiscountForm from '../components/AddDiscountForm'
import moment from 'moment'
import FavoriteDayForm from '../components/FavoriteDayForm'
import UpdatePlanForm from '../components/UpdatePlanForm'
import DeletePlanForm from '../components/DeletePlanForm'
import CancelFinancialMovimentForm from '../components/CancelFinancialMovimentForm'
import AddFinancialMovimentForm from '../components/AddFinancialMovimentForm'
import { Alert } from 'components/Alert'

export interface ChangeDueDateFinancial {
  id: number
  hasCancellation: boolean
  payment_gateway_id?: number
  financialMovimentsPayments?: { document_number: string }[]
}

const CONTRACT_UPDATES_TYPES_MAP = {
  cancel: 'Cancelar contrato',
  update_plan: 'Atualizar plano',
  create_plan: 'Adicionar plano',
  delete_plan: 'Exluir plano'
}

const ContractView = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const { updateDataTable } = useUpdateDataTable()
  const { addToast } = useToast()
  const [contract, setContract] = useState<IContractData>(undefined)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpenChangeDueDate, setIsModalOpenChangeDueDate] =
    useState(false)
  const [
    isModalOpenDownloadFinancialMovimentManual,
    setIsModalOpenDownloadFinancialMovimentManual
  ] = useState(false)
  const [modalAddDiscount, setModalAddDiscount] = useState<{
    open: boolean
    charge_type?: 'debt' | 'discount'
  }>({
    open: false
  })
  const [modalAddFinancialMoviment, setModalAddFinancialMoviment] = useState<{
    open: boolean
    client_id: number
    contract_id: number
  }>({
    open: false,
    client_id: 0,
    contract_id: 0
  })
  const [selectedContractDiscount, setSelectedContractDiscount] =
    useState<IContractDiscount>()
  const [financialMoviment, setFinancialMoviment] = useState<
    ChangeDueDateFinancial | undefined
  >()

  const [modalFavoriteDay, setModalFavoriteDay] = useState({
    open: false,
    id: '',
    day: ''
  })

  const [modalCancelFinancialMoviment, setModalCancelFinancialMoviment] =
    useState({
      open: false,
      id: ''
    })

  const [modalUpdatePlan, setModalUpdatePlan] = useState<{
    typeForm?: 'create' | 'update'
    open: boolean
    id: string
    contractPlan?: IContractPlans
  }>({
    typeForm: 'create',
    open: false,
    id: ''
  })

  const [modalDeletePlan, setModalDeletePlan] = useState<{
    open: boolean
    id: string
    contractPlan?: IContractPlans
  }>({
    open: false,
    id: ''
  })

  const [alertDeleteSchedule, setAlertDeleteSchedule] = useState<{
    open: boolean
    id: string
    contractPlan?: IContractPlans
  }>({
    open: false,
    id: ''
  })

  const [alertDeleteDiscount, setAlertDeleteDiscount] = useState<{
    open: boolean
    id: string
    type: string
  }>({
    open: false,
    id: '',
    type: 'Desconto'
  })

  const searchParametersAuditLog = useRef([
    { entity: 'Contract', entity_id: id }
  ])

  const refModal = useRef(null)
  const { disableLoading, activeLoading } = useLoading()

  const loadContract = useCallback(async () => {
    activeLoading()
    try {
      const response = await api.get<IContractData>(apiGetContract(id))
      const { data } = response
      setContract(data)
      disableLoading()
    } catch (err) {
      disableLoading()
    }
  }, [activeLoading, disableLoading, id])

  const handleClickOnClose = () => {
    loadContract()
    setIsModalOpen(false)
  }
  const onSuccessModalAddDiscount = () => {
    loadContract()
    setModalAddDiscount({
      open: false
    })
  }
  const onSuccessModalAddFinancialMoviment = () => {
    loadContract()
    setModalAddFinancialMoviment({
      open: false,
      client_id: 0,
      contract_id: 0
    })
  }
  useEffect(() => {
    loadContract()
  }, [loadContract])

  const getPdfContract = () => {
    activeLoading()

    api
      .get(apiGetPdfContract(id.toString()), { responseType: 'blob' })
      .then(res => {
        window.open(URL.createObjectURL(res.data))
        disableLoading()
      })
  }

  const getPdfBoleto = (
    financialMovimentPaymentId: number,
    actualIndex: number
  ) => {
    activeLoading()
    api
      .get(apiGetPdfFinancialMoviment(financialMovimentPaymentId.toString()))
      .then(res => {
        const linkSource = `data:application/pdf;base64,${res.data.pdf}`
        const financialMovimentPayament =
          contract.financial_moviments[actualIndex].financialMovimentsPayments
        const downloadLink = document.createElement('a')
        const replaceClientNameWithTrace = contract.client.name?.replaceAll(
          ' ',
          '-'
        )
        const fileName = `Cliente-${replaceClientNameWithTrace}-Numero-${financialMovimentPayament[0].document_number}.pdf`
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()

        disableLoading()
      })
  }

  const isActiveOrAwaitingPayment =
    contract?.situation === 'Aguardando pagamento' ||
    contract?.situation === 'Ativo'

  const isScheduleCancel = contract?.contractUpdates?.some(
    update => update.type === 'cancel'
  )

  const isPendingUpdates = contract?.contractUpdates?.some(
    update => !update.implementation_date
  )

  const handleClickOnOpenModal = () => {
    setIsModalOpen(true)
  }
  const handleClickOnOpenModalChangeDueDate = (
    financialMoviment: ChangeDueDateFinancial
  ) => {
    setFinancialMoviment(financialMoviment)
    setIsModalOpenChangeDueDate(true)
  }
  const handleClickOnCloseModalChangeDueDate = () => {
    updateDataTable()
    setIsModalOpenChangeDueDate(false)
  }
  const handleClickOnOpenModalDownloadFinancialMovimentManual = (
    financialMoviment: any
  ) => {
    setFinancialMoviment(financialMoviment)
    setIsModalOpenDownloadFinancialMovimentManual(true)
  }
  const handleClickOnCloseModalDownloadFinancialMovimentManual = () => {
    updateDataTable()
    setIsModalOpenDownloadFinancialMovimentManual(false)
  }

  const handleClickOnCloseModalFavoriteDay = () => {
    loadContract()
    setModalFavoriteDay({
      open: false,
      day: '',
      id: ''
    })
  }

  const handleClickOnCloseModalCancelFinancialMoviment = () => {
    loadContract()
    setModalCancelFinancialMoviment({
      open: false,
      id: ''
    })
  }

  const handleClickOnCloseModalUpdatePlan = () => {
    loadContract()
    setModalUpdatePlan({
      open: false,
      id: ''
    })
  }

  const handleClickOnCloseModalDeletePlan = () => {
    loadContract()
    setModalDeletePlan({
      open: false,
      id: ''
    })
  }

  const handlerClickButtonCancellAlertDeleteSchedule = () => {
    setAlertDeleteSchedule({
      open: false,
      id: '0'
    })
  }

  const handlerClickButtonCancellAlertDeleteDiscount = () => {
    setAlertDeleteDiscount({
      open: false,
      id: '0',
      type: ''
    })
  }

  const handlerClickButtonConfirmAlertDeleteSchedule = async (
    schedule_id: string
  ) => {
    activeLoading()
    try {
      await api.delete(`/commercial/contractsUpdates/delete/${schedule_id}`)
      disableLoading()
      addToast({
        type: 'success',
        title: 'Agendamento exluído com sucesso',
        description: 'Agendamento exluído com sucesso para cliente!'
      })
    } catch (error) {
      disableLoading()
      addToast({
        type: 'error',
        title: 'Error ao excluir agendamento',
        description:
          'Houve um error ao excluir agendamento, tente novamente mais tarde!'
      })
    }
    setAlertDeleteSchedule({
      open: false,
      id: ''
    })
    loadContract()
  }

  const handlerClickButtonConfirmAlertDeleteDiscount = async (
    discount_id: string
  ) => {
    activeLoading()
    try {
      await api.delete(deleteContractDiscount(discount_id))
      disableLoading()
      addToast({
        type: 'success',
        title: 'exluído com sucesso',
        description: 'exluído com sucesso para cliente!'
      })
    } catch (error) {
      disableLoading()
      addToast({
        type: 'error',
        title: 'Error ao excluir',
        description: 'Houve um error ao excluir, tente novamente mais tarde!'
      })
    }
    setAlertDeleteDiscount({
      open: false,
      id: '',
      type: ''
    })
    loadContract()
  }

  const handleSendBankSlip = useCallback(
    async ({ financial_moviment_id }) => {
      activeLoading()
      try {
        await api.put(
          `/financial/financialMoviments/sendBankSlip/${financial_moviment_id}`
        )
        disableLoading()
        addToast({
          type: 'success',
          title: 'Boleto enviado com sucesso',
          description: 'Boleto enviado com sucesso para cliente!'
        })
      } catch (error) {
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao enviar o boleto',
          description:
            'Houve um error ao enviar o boleto, tente novamente mais tarde!'
        })
      }
      updateDataTable()
    },
    [activeLoading, addToast, disableLoading, updateDataTable]
  )

  return (
    <>
      <Container
        pageTitle={nameActions.read.name}
        breadcrumb={breadcrumbView}
        tools={[
          toolsPrintPdf(id, getPdfContract),
          isActiveOrAwaitingPayment &&
            !isScheduleCancel &&
            cancelContract(id, handleClickOnOpenModal)
        ]}
      >
        <div className="form-body">
          <div className="row mb-5">
            <ViewInfoLabel col={3} description={contract?.id} label="Cód" />
            <ViewInfoLabel
              col={3}
              descriptionWithLink={
                <Link to={`/commercial/clients/view/${contract?.client.id}`}>
                  {contract?.client.name}
                </Link>
              }
              label="Cliente"
            />
            <ViewInfoLabel
              col={3}
              description={contract?.client.cpf}
              label="CPF (cliente)"
            />
            <ViewInfoLabel
              col={3}
              descriptionWithLink={
                <Link to={`/commercial/students/view/${contract?.student.id}`}>
                  {contract?.student.name}
                </Link>
              }
              label="Estudante"
            />
            <ViewInfoLabel
              col={3}
              description={contract?.institution.company_social_name}
              label="Instituição"
            />
            <ViewInfoLabel
              col={3}
              description={contract?.student?.serie_class?.name}
              label="Turma"
            />
          </div>
          <div className="separator my-3" />

          <div className="row mb-5">
            <ViewInfoLabel
              col={3}
              description={contract?.contract_plans[0]?.periodicity_payament}
              label="Periodicidade"
            />
            <ViewInfoLabel
              col={3}
              description={contract?.form_payament}
              label="Forma de pagamento"
            />
            <ViewInfoLabel
              col={3}
              description={contract?.start_date?.substring(0, 10)}
              label="Início do contrato"
            />
            <div className="form-group col-md-3">
              <label className="col-form-label fw-bold form-label fw-bold fs-6">
                Dia de pagamento
              </label>
              <p className="fw-bolder fs-6 text-gray-800 cursor-pointer">
                {contract?.favorite_day}
                <span
                  className="fa fa-edit mx-2"
                  onClick={() => {
                    setModalFavoriteDay({
                      open: true,
                      id,
                      day: contract.favorite_day
                    })
                  }}
                ></span>
              </p>
            </div>
          </div>
          <div className="separator my-3" />
          <div className="row mb-5">
            <ViewInfoLabel
              col={3}
              description={currencyMask(contract?.value_without_discount)}
              label="Valor dos planos"
            />
            <ViewInfoLabel
              col={3}
              description={percentualMask(contract?.discount)}
              label="Percentual do desconto"
            />
            <ViewInfoLabel
              col={3}
              description={currencyMask(
                (+contract?.discount * contract?.value_without_discount) / 100
              )}
              label="Valor do desconto"
            />
            <ViewInfoLabel
              col={3}
              description={currencyMask(contract?.value_payament)}
              label="Valor total"
            />
          </div>
          <div className="separator my-3" />

          <div className="row mb-5">
            <ViewInfoLabel
              col={3}
              description={contract?.situation}
              label="Situação"
            />
            <ViewInfoLabel
              col={3}
              description={contract?.created_at}
              label="Cadastrado em"
            />
          </div>

          {isPendingUpdates && (
            <>
              <b>Atualizações pendentes</b>
              <div className="row mb-5" style={{ color: 'red' }}>
                {contract?.contractUpdates.map(
                  update =>
                    !update.implementation_date && (
                      <p key={update.id}>
                        {
                          CONTRACT_UPDATES_TYPES_MAP[
                            update.type as keyof typeof CONTRACT_UPDATES_TYPES_MAP
                          ]
                        }{' '}
                        {
                          contract.contract_plans.find(
                            cp => cp.id === update.contract_plan_id
                          )?.plan_frequency?.menu_food?.menu_food_type?.name
                        }{' '}
                        {update.type === 'update_plan' && (
                          <span>
                            R${' '}
                            {
                              JSON.parse(update.modifications)
                                ?.selectedPlanFrequency?.value
                            }{' '}
                          </span>
                        )}
                        {update.type === 'update_plan' &&
                          JSON.parse(
                            update.modifications
                          )?.frequencyDaysOfWeek?.map((day: string) => (
                            <span className="mx-2" key={day}>
                              {day}
                            </span>
                          ))}{' '}
                        {moment(update.date, 'DD/MM/YYYY HH:mm:ss')
                          .add(3, 'h')
                          .format('DD/MM/YYYY')}
                        <span
                          className="fa fa-trash mx-2 cursor-pointer"
                          onClick={() => {
                            setAlertDeleteSchedule({
                              open: true,
                              id: String(update.id)
                            })
                          }}
                        />
                      </p>
                    )
                )}
              </div>
            </>
          )}

          <div className="separator my-5" />
          <div className="row">
            <div className="col-md-12">
              <Tabs>
                <Tab title="Cardápios" key={0}>
                  <div>
                    <div className="card-header p-0 d-flex - align-items-center">
                      <div className="card-title m-0 fw-bolder fs-6 text-gray-800">
                        Listagem
                      </div>
                      <div
                        className="card-title m-0 fw-bolder fs-6 text-gray-800 cursor-pointer"
                        onClick={() => {
                          setModalUpdatePlan({
                            typeForm: 'create',
                            open: true,
                            id
                          })
                        }}
                      >
                        + Adicionar plano
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table
                        id="kt_datatable"
                        className="table table-striped table-row-bordered gs-3 border table-hover dataTable"
                      >
                        <thead className="border-bottom border-end">
                          <tr>
                            <th>Cardápio</th>
                            <th>Frequência</th>
                            <th>Dias contratados</th>
                            <th>Itens selecionados</th>
                            <th>Periodicidade</th>
                            <th>Valor sem desconto</th>
                            <th>Desconto</th>
                            <th>Valor final</th>
                            <th>Início</th>
                            <th>Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {contract?.contract_plans?.length === 0 && (
                            <tr>
                              <td colSpan={8} className="text-center">
                                Nenhum registro encontrado
                              </td>
                            </tr>
                          )}
                          {contract?.contract_plans?.map(contractPlan => {
                            const itemsSelected = JSON.parse(
                              contractPlan?.items_selected
                            )?.map((item: ISelectedDish) => item.dish_name)
                            return (
                              <MenuTableRow key={contractPlan.id}>
                                <td>
                                  {
                                    contractPlan.plan_frequency.menu_food
                                      ?.menu_food_type?.name
                                  }
                                </td>
                                <td>{contractPlan.plan_frequency.frequency}</td>
                                <td>
                                  {makeStringWithAnd(
                                    sortWeek(
                                      JSON.parse(
                                        contractPlan?.frequency_days_of_week
                                      )?.frequency_days_of_week
                                    )
                                  )}
                                </td>
                                <td>
                                  {itemsSelected?.length > 0
                                    ? makeStringWithAnd(itemsSelected)
                                    : 'Refeição não possui itens de escolha.'}
                                </td>
                                <td>
                                  {
                                    JSON.parse(
                                      contractPlan.plan_frequency.payament_form
                                    ).periodicity
                                  }
                                </td>
                                <td>
                                  {currencyMask(
                                    JSON.parse(
                                      contractPlan.plan_frequency.payament_form
                                    ).value
                                  )}
                                </td>
                                <td>{percentualMask(contractPlan.discount)}</td>
                                <td>
                                  {currencyMask(
                                    contractPlan.value_with_discount
                                  )}
                                </td>
                                <td>
                                  {moment(
                                    contractPlan.created_at,
                                    'DD/MM/YYYY HH:mm:ss'
                                  ).isSame(
                                    moment(
                                      contract.created_at,
                                      'DD/MM/YYYY HH:mm:ss'
                                    ),
                                    'day'
                                  )
                                    ? moment(
                                        contract.start_date,
                                        'DD/MM/YYYY HH:mm:ss'
                                      ).format('DD/MM/YYYY')
                                    : moment(
                                        contractPlan.created_at,
                                        'DD/MM/YYYY HH:mm:ss'
                                      ).format('DD/MM/YYYY')}
                                </td>
                                <td>
                                  <span
                                    className="fa fa-edit cursor-pointer"
                                    onClick={() => {
                                      setModalUpdatePlan({
                                        typeForm: 'update',
                                        open: true,
                                        id,
                                        contractPlan: contractPlan
                                      })
                                    }}
                                  />
                                  <span
                                    className="fa fa-trash mx-2 cursor-pointer"
                                    onClick={() => {
                                      setModalDeletePlan({
                                        open: true,
                                        id,
                                        contractPlan: contractPlan
                                      })
                                    }}
                                  />
                                </td>
                              </MenuTableRow>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Tab>
                <Tab title="Movimentações financeiras" key={1}>
                  <div>
                    <div className="card-header p-0 d-flex - align-items-center">
                      <div className="card-title m-0 fw-bolder fs-6 text-gray-800">
                        Listagem
                      </div>
                      <div>
                        <a
                          href="#"
                          onClick={() => {
                            setModalAddFinancialMoviment({
                              open: true,
                              client_id: contract.client_id,
                              contract_id: contract.id
                            })
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="fa fa-plus" /> Adicionar
                        </a>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table
                        id="kt_datatable"
                        className="table table-striped table-row-bordered gs-3 border table-hover dataTable"
                      >
                        <thead className="border-bottom border-end">
                          <tr>
                            <th>Nº do boleto</th>
                            <th>Data de vencimento</th>
                            <th>Forma de pagamento</th>
                            <th>Valor</th>
                            <th>Status</th>
                            <th>Manual</th>
                            <th>Liquidação</th>
                            <th>Data de envio</th>
                            <th style={{ textAlign: 'center' }}>Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {contract?.financial_moviments?.length === 0 && (
                            <tr>
                              <td colSpan={8} className="text-center">
                                Nenhum registro encontrado
                              </td>
                            </tr>
                          )}
                          {contract?.financial_moviments?.map(
                            (financial_moviment, actualIndex) => {
                              const slipPayment =
                                financial_moviment.financialMovimentsPayments?.find(
                                  fmp => fmp.payment_method === 'B'
                                )
                              const slipPayments =
                                financial_moviment.financialMovimentsPayments?.filter(
                                  fmp => fmp.payment_method === 'B'
                                )
                              const pixPayment =
                                financial_moviment.financialMovimentsPayments?.find(
                                  fmp => fmp.payment_method === 'P'
                                )
                              let situation
                              if (
                                financial_moviment.financialMovimentsPayments?.some(
                                  fmp => fmp.situation === 'Pago'
                                )
                              ) {
                                situation = 'Pago'
                              } else if (
                                slipPayment?.situation === 'Cancelado' ||
                                pixPayment?.situation === 'Cancelado'
                              ) {
                                situation = 'Cancelado'
                              } else if (
                                slipPayment?.situation ===
                                  'Esperando pagamento' ||
                                pixPayment?.situation === 'Esperando pagamento'
                              ) {
                                situation = 'Esperando pagamento'
                              }

                              if (
                                slipPayments.some(
                                  sp => sp.situation === 'Esperando pagamento'
                                )
                              ) {
                                situation = 'Esperando pagamento'
                              }

                              return (
                                <MenuTableRow key={financial_moviment.id}>
                                  <td>{slipPayment?.document_number}</td>
                                  <td>
                                    {financial_moviment.due_date.slice(0, 10)}
                                  </td>
                                  <td>{contract.form_payament}</td>
                                  <td>
                                    {currencyMask(financial_moviment.value)}
                                  </td>
                                  <td>{situation || 'Boleto não emitido'}</td>
                                  <td>{financial_moviment.manual && 'Sim'}</td>
                                  <td>
                                    {financial_moviment.settlement_date &&
                                      moment(
                                        financial_moviment.settlement_date,
                                        'DD/MM/YYYY HH:mm:ss'
                                      )
                                        .add(3, 'h')
                                        .format('DD/MM/YYYY')}
                                  </td>
                                  <td>
                                    {financial_moviment.banknote_send_at &&
                                      moment(
                                        financial_moviment.banknote_send_at,
                                        'DD/MM/YYYY HH:mm:ss'
                                      )
                                        .add(3, 'h')
                                        .format('DD/MM/YYYY')}
                                  </td>
                                  <td className="d-flex align-items-center justify-content-center">
                                    {((financial_moviment
                                      .financialMovimentsPayments?.length ===
                                      0 &&
                                      !financial_moviment.description.includes(
                                        'Cancelado'
                                      )) ||
                                      situation === 'Esperando pagamento') && (
                                      <LinkContainer className="gap-2 ">
                                        <div>
                                          <span
                                            title="Cancelar pagamento"
                                            className="fa"
                                            style={{
                                              border: 'none',
                                              color: '#009EF7'
                                            }}
                                            onClick={() =>
                                              setModalCancelFinancialMoviment({
                                                open: true,
                                                id: String(
                                                  financial_moviment.id
                                                )
                                              })
                                            }
                                          >
                                            x
                                          </span>
                                        </div>
                                      </LinkContainer>
                                    )}
                                    {situation !== 'Cancelado' && (
                                      <LinkContainer className="gap-2 ">
                                        <div>
                                          {slipPayment && (
                                            <span
                                              title="Imprimir boleto"
                                              className="fa fa-print"
                                              style={{
                                                border: 'none',
                                                color: '#009EF7'
                                              }}
                                              onClick={() =>
                                                getPdfBoleto(
                                                  slipPayment?.id,
                                                  actualIndex
                                                )
                                              }
                                            />
                                          )}
                                        </div>
                                      </LinkContainer>
                                    )}
                                    {situation !== 'Pago' && (
                                      <LinkContainer className="gap-2 ms-1">
                                        <div>
                                          <span
                                            title="Emitir boleto"
                                            className="fa fa-calendar"
                                            style={{
                                              border: 'none',
                                              color: '#009EF7'
                                            }}
                                            onClick={() =>
                                              handleClickOnOpenModalChangeDueDate(
                                                {
                                                  ...financial_moviment,
                                                  hasCancellation:
                                                    !!financial_moviment
                                                      .financialMovimentsPayments
                                                      ?.length,
                                                  id: financial_moviment.id
                                                }
                                              )
                                            }
                                          />
                                        </div>
                                      </LinkContainer>
                                    )}
                                    {(!situation ||
                                      situation === 'Esperando pagamento') && (
                                      <LinkContainer className="gap-2 ms-1">
                                        <div>
                                          <span
                                            title="Baixa manual"
                                            className="fa fa-dollar"
                                            style={{
                                              border: 'none',
                                              color: '#009EF7'
                                            }}
                                            onClick={() =>
                                              handleClickOnOpenModalDownloadFinancialMovimentManual(
                                                {
                                                  ...financial_moviment,

                                                  id: financial_moviment.id
                                                }
                                              )
                                            }
                                          />
                                        </div>
                                      </LinkContainer>
                                    )}
                                    {situation !== 'Pago' && (
                                      <LinkContainer className="gap-2 ms-1">
                                        <div>
                                          <span
                                            title="Enviar boleto por email"
                                            className="fa fa-envelope"
                                            style={{
                                              border: 'none',
                                              color: '#009EF7'
                                            }}
                                            onClick={() =>
                                              handleSendBankSlip({
                                                financial_moviment_id:
                                                  financial_moviment.id
                                              })
                                            }
                                          />
                                        </div>
                                      </LinkContainer>
                                    )}
                                  </td>
                                </MenuTableRow>
                              )
                            }
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Tab>
                <Tab title="Descontos" key={2}>
                  <div>
                    <div className="card-header p-0 d-flex - align-items-center">
                      <div className="card-title m-0 fw-bolder fs-6 text-gray-800">
                        Listagem
                      </div>
                      <div>
                        <a
                          href="#"
                          onClick={() => {
                            setSelectedContractDiscount(undefined)
                            setModalAddDiscount({
                              open: true,
                              charge_type: 'discount'
                            })
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="fa fa-plus" /> Adicionar
                        </a>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table
                        id="kt_datatable"
                        className="table table-striped table-row-bordered gs-3 border table-hover dataTable"
                      >
                        <thead className="border-bottom border-end">
                          <tr>
                            <th>Tipo</th>
                            <th>Valor</th>
                            <th>Vencimento</th>
                            <th>Descrição</th>
                            <th style={{ textAlign: 'center' }}>Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {contract?.contractDiscounts?.filter(
                            discount => discount.charge_type !== 'debt'
                          ).length === 0 && (
                            <tr>
                              <td colSpan={8} className="text-center">
                                Nenhum registro encontrado
                              </td>
                            </tr>
                          )}
                          {contract?.contractDiscounts
                            ?.filter(
                              discount => discount.charge_type !== 'debt'
                            )
                            .map(contractDiscount => {
                              return (
                                <MenuTableRow key={contractDiscount.id}>
                                  <td>{contractDiscount.type}</td>
                                  <td>
                                    {contractDiscount.type === 'valor'
                                      ? `R$ ${contractDiscount.value.replace(
                                          '.',
                                          ','
                                        )}`
                                      : `${contractDiscount.value.replace(
                                          '.',
                                          ','
                                        )}%`}
                                  </td>
                                  <td>
                                    {String(contractDiscount.due_date).slice(
                                      0,
                                      10
                                    )}
                                  </td>
                                  <td>{contractDiscount.description}</td>
                                  <td className="d-flex align-items-center justify-content-center">
                                    <span
                                      className="fa fa-edit cursor-pointer"
                                      onClick={() => {
                                        setSelectedContractDiscount(
                                          contractDiscount
                                        )
                                        setModalAddDiscount({
                                          open: true,
                                          charge_type: 'discount'
                                        })
                                      }}
                                    ></span>
                                    <span
                                      title="Excluir desconto"
                                      className="fa fa-trash mx-2 cursor-pointer"
                                      onClick={() => {
                                        setAlertDeleteDiscount({
                                          open: true,
                                          id: String(contractDiscount.id),
                                          type: 'o desconto'
                                        })
                                      }}
                                    ></span>
                                  </td>
                                </MenuTableRow>
                              )
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Tab>
                <Tab title="Cobranças" key={3}>
                  <div>
                    <div className="card-header p-0 d-flex - align-items-center">
                      <div className="card-title m-0 fw-bolder fs-6 text-gray-800">
                        Listagem
                      </div>
                      <div>
                        <a
                          href="#"
                          onClick={() => {
                            setSelectedContractDiscount(undefined)
                            setModalAddDiscount({
                              open: true,
                              charge_type: 'debt'
                            })
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          <span className="fa fa-plus" /> Adicionar
                        </a>
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table
                        id="kt_datatable"
                        className="table table-striped table-row-bordered gs-3 border table-hover dataTable"
                      >
                        <thead className="border-bottom border-end">
                          <tr>
                            <th>Tipo</th>
                            <th>Valor</th>
                            <th>Vencimento</th>
                            <th>Descrição</th>
                            <th style={{ textAlign: 'center' }}>Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {contract?.contractDiscounts?.length === 0 && (
                            <tr>
                              <td colSpan={8} className="text-center">
                                Nenhum registro encontrado
                              </td>
                            </tr>
                          )}
                          {contract?.contractDiscounts
                            ?.filter(
                              discount => discount.charge_type === 'debt'
                            )
                            .map(contractDiscount => {
                              return (
                                <MenuTableRow key={contractDiscount.id}>
                                  <td>{contractDiscount.type}</td>
                                  <td>
                                    {contractDiscount.type === 'valor'
                                      ? `R$ ${contractDiscount.value.replace(
                                          '.',
                                          ','
                                        )}`
                                      : `${contractDiscount.value.replace(
                                          '.',
                                          ','
                                        )}%`}
                                  </td>
                                  <td>
                                    {String(contractDiscount.due_date).slice(
                                      0,
                                      10
                                    )}
                                  </td>
                                  <td>{contractDiscount.description}</td>
                                  <td className="d-flex align-items-center justify-content-center">
                                    <span
                                      className="fa fa-edit cursor-pointer"
                                      onClick={() => {
                                        setSelectedContractDiscount(
                                          contractDiscount
                                        )
                                        setModalAddDiscount({
                                          open: true,
                                          charge_type: 'debt'
                                        })
                                      }}
                                    ></span>
                                    <span
                                      title="Excluir cobrança"
                                      className="fa fa-trash mx-2 cursor-pointer"
                                      onClick={() => {
                                        setAlertDeleteDiscount({
                                          open: true,
                                          id: String(contractDiscount.id),
                                          type: 'a cobrança'
                                        })
                                      }}
                                    ></span>
                                  </td>
                                </MenuTableRow>
                              )
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Tab>
                <Tab title="Histórico" key={4}>
                  <div className="portlet light">
                    <div className="card-header p-0">
                      <div className="card-title m-0 fw-bolder fs-6 text-gray-800">
                        Listagem
                      </div>
                    </div>
                    <div className="portlet-body form">
                      <DataTable
                        entityId={id}
                        format={{ orderBy: '' }}
                        source="auditLogs"
                        entity="AuditLog"
                        searchParameters={searchParametersAuditLog.current}
                      />
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </Container>
      <Modal
        refModal={refModal}
        onClickButtonCancel={handleClickOnClose}
        isOpenModal={isModalOpen}
        pageTitle={'Cancelar contrato'}
        Children={
          <CancelContractForm
            contract={{ id }}
            isOpenInModal={{
              handleOnClose: handleClickOnClose
            }}
            setContract={setContract}
          />
        }
      />
      <Modal
        onClickButtonCancel={handleClickOnCloseModalCancelFinancialMoviment}
        isOpenModal={modalCancelFinancialMoviment.open}
        pageTitle={'Cancelar pagamento'}
        Children={
          <CancelFinancialMovimentForm
            id={modalCancelFinancialMoviment.id}
            isOpenInModal={{
              handleOnClose: handleClickOnCloseModalCancelFinancialMoviment
            }}
          />
        }
      />
      <Modal
        onClickButtonCancel={() =>
          setModalAddDiscount({
            open: false
          })
        }
        isOpenModal={modalAddDiscount.open}
        pageTitle={
          modalAddDiscount.charge_type === 'discount'
            ? 'Adicionar desconto'
            : 'Adicionar cobrança'
        }
        Children={
          <AddDiscountForm
            charge_type={modalAddDiscount.charge_type}
            contract={{ id }}
            isOpenInModal={{
              contractDiscount: selectedContractDiscount,
              handleOnClose: onSuccessModalAddDiscount
            }}
          />
        }
      />
      <Modal
        onClickButtonCancel={() =>
          setModalAddFinancialMoviment({
            open: false,
            client_id: 0,
            contract_id: 0
          })
        }
        isOpenModal={modalAddFinancialMoviment.open}
        pageTitle={'Adicionar boleto'}
        Children={
          <AddFinancialMovimentForm
            client_id={modalAddFinancialMoviment.client_id}
            contract_id={modalAddFinancialMoviment.contract_id}
            isOpenInModal={{
              handleOnClose: onSuccessModalAddFinancialMoviment
            }}
          />
        }
      />
      <Modal
        onClickButtonCancel={handleClickOnCloseModalChangeDueDate}
        isOpenModal={isModalOpenChangeDueDate}
        pageTitle={'Emissão de Boleto'}
        Children={
          <ChangeDueDate
            isOpenInModal={{
              handleOnClose: handleClickOnCloseModalChangeDueDate
            }}
            financialMoviment={{
              financialMoviment,
              contract
            }}
          />
        }
      />
      <Modal
        onClickButtonCancel={
          handleClickOnCloseModalDownloadFinancialMovimentManual
        }
        isOpenModal={isModalOpenDownloadFinancialMovimentManual}
        pageTitle={'Baixa Manual de Pagamento'}
        Children={
          <DownloadFinancialMovimentManual
            isOpenInModal={{
              handleOnClose:
                handleClickOnCloseModalDownloadFinancialMovimentManual
            }}
            financialMoviment={{
              financialMoviment,
              contract
            }}
          />
        }
      />
      <Modal
        onClickButtonCancel={() => {
          setModalFavoriteDay({
            open: false,
            day: '',
            id: ''
          })
        }}
        isOpenModal={modalFavoriteDay.open}
        pageTitle={'Dia de pagamento'}
        Children={
          <FavoriteDayForm
            isOpenInModal={{
              handleOnClose: handleClickOnCloseModalFavoriteDay
            }}
            contract={{
              id: modalFavoriteDay.id,
              favorite_day: modalFavoriteDay.day
            }}
          />
        }
      />
      <Modal
        onClickButtonCancel={() => {
          setModalUpdatePlan({
            open: false,
            id: ''
          })
        }}
        isOpenModal={modalUpdatePlan.open}
        pageTitle={'Atualizar Plano'}
        style={{
          stylesModal: {
            minWidth: '100%'
          }
        }}
        Children={
          <UpdatePlanForm
            typeform={modalUpdatePlan.typeForm}
            isOpenInModal={{
              handleOnClose: handleClickOnCloseModalUpdatePlan
            }}
            contract={{
              id: modalUpdatePlan.id,
              institution_id: contract?.institution_id,
              contractPlan: modalUpdatePlan.contractPlan,
              plans: contract?.contract_plans,
              student: contract?.student
            }}
          />
        }
      />
      <Modal
        onClickButtonCancel={() => {
          setModalDeletePlan({
            open: false,
            id: ''
          })
        }}
        isOpenModal={modalDeletePlan.open}
        pageTitle={'Excluir Plano'}
        Children={
          <DeletePlanForm
            isOpenInModal={{
              handleOnClose: handleClickOnCloseModalDeletePlan
            }}
            contract={{
              id: modalDeletePlan.id,
              institution_id: contract?.institution_id,
              contractPlan: modalDeletePlan.contractPlan
            }}
          />
        }
      />
      <Alert
        message={'Tem certeza que deseja excluir o agendamento?'}
        onClickCancelButton={handlerClickButtonCancellAlertDeleteSchedule}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlertDeleteSchedule(alertDeleteSchedule.id)
        }}
        isActive={alertDeleteSchedule.open}
      />
      <Alert
        message={`Tem certeza que deseja excluir ${alertDeleteDiscount.type}?`}
        onClickCancelButton={handlerClickButtonCancellAlertDeleteDiscount}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlertDeleteDiscount(alertDeleteDiscount.id)
        }}
        isActive={alertDeleteDiscount.open}
      />
    </>
  )
}

export default ContractView
