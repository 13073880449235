import { Redirect, Route, Switch } from 'react-router-dom'
import { NotFoundPage } from '../pages/NotFound'
import SignUp from '../pages/Signup'
import Dashboard from '../pages/Dashboard'
import PrivateRoutes from './Route'
import SignIn from '../pages/Signin'
import { MenusRouter } from './menus/MenusRouter'
import { useAuth } from '../hooks/auth'
import { ClientsRouter } from './commercial/ClientsRouter'
import { InstitutionsRouter } from './commercial/InstitutionsRouter'
import { UsersRouter } from './users/UsersRouter'
import { PermissionsRouter } from './users/PermissionsRouter'
import { RolesRouter } from './config/RolesRouter'
import { ForgotPassword } from '../pages/ForgotPassword'
import { DishesRouter } from './commercial/DishesRouter'
import { MenuFoodsRouter } from './commercial/MenuFoodsRouter'
import { DishesTypesRouter } from './commercial/DishesTypesRouter'
import { IngredientsRouter } from './commercial/IngredientsRouter'
import { MenuFoodTypesRouter } from './commercial/MenuFoodTypesRouter'
import { PermissionsAssociatedRouter } from './users/PermissionsAssociatedRouter'
import { StudentsRouter } from './commercial/StudentsRouter'
import { LabelsRouter } from './labels/LabelRouter'
import { ContractsRouter } from './commercial/ContractsRouter'
import { ContractsMenuFoodsRouter } from './commercial/ContractsMenuFoodsRouter'

import { FinancialMovimentsRouter } from './financial/FinancialMovimentsRouter'
import { RenewPaymentsRouter } from './financial/RenewPaymentsRouter'

const Routes: React.FC = () => {
  const { userLogged, backupHistory } = useAuth()
  return (
    <Switch>
      <Route path="/" exact component={SignIn}>
        {!backupHistory.length && userLogged() && <Redirect to="/dashboard" />}
      </Route>
      <Route path="/signup" component={SignUp} />
      <PrivateRoutes path="/dashboard" component={Dashboard} />
      <Route path="/menus" component={MenusRouter} />
      <Route path="/users/permissions" component={PermissionsRouter} />
      <Route path="/users/roles" component={RolesRouter} />
      <Route
        path="/users/permissionsAssociated"
        component={PermissionsAssociatedRouter}
      />
      <Route path="/users" component={UsersRouter} />
      <Route path="/labels" component={LabelsRouter} />
      <Route path="/commercial/clients" component={ClientsRouter} />
      <Route path="/commercial/students" component={StudentsRouter} />
      <Route path="/commercial/institutions" component={InstitutionsRouter} />
      <Route path="/commercial/dishes" component={DishesRouter} />
      <Route path="/commercial/dishesTypes" component={DishesTypesRouter} />
      <Route path="/commercial/ingredients" component={IngredientsRouter} />
      <Route path="/commercial/menuFoodTypes" component={MenuFoodTypesRouter} />
      <Route path="/commercial/menuFoods" component={MenuFoodsRouter} />
      <Route path="/commercial/contracts" component={ContractsRouter} />
      <Route
        path="/commercial/contractsMenuFoods"
        component={ContractsMenuFoodsRouter}
      />

      <Route
        path="/financial/financialMoviments"
        component={FinancialMovimentsRouter}
      />
      <Route path="/financial/renewPayments" component={RenewPaymentsRouter} />

      <Route path="/forgot" component={ForgotPassword} />
      <Route path="*" component={NotFoundPage} />
    </Switch>
  )
}
export default Routes
