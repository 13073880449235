export const headers = [
  { name: 'Nome Fantasia', field: 'company_social_name', sortable: true },
  { name: 'Identificador', field: 'identifier', sortable: true },
  { name: 'CNPJ', field: 'cnpj', sortable: false },
  { name: 'Telefone', field: 'phone', sortable: false },
  {
    name: 'Ativo',
    field: 'is_active',
    sortable: false
  },
  { name: 'Cadastrado em', field: 'created_at', sortable: false },
  { name: 'Ações', field: 'actions', sortable: false }
]

export const headersStudentQueryApi = [
  { name: 'Estudante', field: 'name', sortable: true },
  { name: 'Responsável', field: 'client.name', sortable: false },
  { name: 'Gênero', field: 'gender', sortable: false },
  { name: 'Turma/Sala', field: 'serie_class.name', sortable: false },
  { name: 'Período', field: 'school_period', sortable: false },
  {
    name: 'Ativo',
    field: 'is_active',
    sortable: false
  },
  { name: 'Cadastrado em', field: 'created_at', sortable: false },
  { name: 'Ações', field: 'actions', sortable: false }
]

export const headersMenuQueryApi = [
  { name: 'Tipo', field: 'menu_food.menu_food_type.name', sortable: true },
  { name: 'Período', field: 'menu_food.period', sortable: false },
  {
    name: 'Ativo',
    field: 'menu_food.is_active',
    sortable: false
  },
  { name: 'Cadastrado em', field: 'menu_food.created_at', sortable: false },
  { name: 'Ações', field: 'actions', sortable: false }
]
