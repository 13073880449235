export const headers = [
  {
    name: 'Turma',
    field: 'student.serie_class.name',
    sortable: true
  },
  {
    name: 'Aluno',
    field: 'student.name',
    sortable: true
  },
  {
    name: 'Segunda',
    field: 'monday',
    sortable: false
  },
  { name: 'Terça', field: 'tuesday', sortable: false },
  { name: 'Quarta', field: 'wednesday', sortable: false },
  { name: 'Quinta', field: 'thursday', sortable: false },
  { name: 'Sexta', field: 'friday', sortable: false }
]
export const headersDaily = [
  {
    name: 'Turma',
    field: 'student.serie_class.name',
    sortable: true
  },
  {
    name: 'Aluno',
    field: 'student.name',
    sortable: true
  },
  {
    name: 'Cardápio',
    field: 'menu_dish',
    sortable: false
  }
]
