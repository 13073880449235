import { DateInput } from 'components/Form/date'
import { useToast } from 'hooks/toast'
import api from 'services/api'
import { scheduleDeletePlan } from '../../domain/api'
import { useState } from 'react'
import moment from 'moment'

interface IDeletePlanForm {
  contract: {
    id: string
    institution_id: number
    contractPlan: IContractPlans
  }
  isOpenInModal: {
    handleOnClose: () => void
  }
}

const DeletePlanForm = ({ contract, isOpenInModal }: IDeletePlanForm) => {
  const { addToast } = useToast()

  const [scheduleDate, setScheduleDate] = useState<Date>(
    moment().endOf('M').add(1, 'd').toDate()
  )

  const onSubmitForm = async () => {
    try {
      await api.post(scheduleDeletePlan(contract.id), {
        contract_plan_id: contract.contractPlan.id,
        date: moment(scheduleDate).format('YYYY-MM-DD')
      })
      addToast({
        type: 'success',
        title: 'Contrato atualizado com sucesso'
      })
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Error ao cancelar o contrato',
        description:
          'Houve um error ao atualizar o contrato, tente novamente mais tarde!'
      })
    }

    isOpenInModal.handleOnClose()
  }

  return (
    <div style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
      <DateInput
        label="Data da exclusão"
        name="date"
        className="col-6"
        onChange={date => setScheduleDate(date)}
        selected={scheduleDate}
        minDate={moment().add(1, 'd').toDate()}
        controlled
      />

      <div>
        <button
          className="btn btn-primary float-end"
          onClick={() => onSubmitForm()}
        >
          Confirmar
        </button>
      </div>
    </div>
  )
}

export default DeletePlanForm
