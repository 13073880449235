import { useEffect, useState } from 'react'
import api from '../../../../../services/api'
import Form from '../../../../../components/Form'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'

type InstitutionsFormProps = {
  handleClickOnCloseModal: () => void
  menu_food_id?: string
  institution_id?: any
  situation?: string[]
  date?: string
  institutionOptions: IInstitutionData[]
}

export const InstitutionsForm = ({
  menu_food_id,
  institution_id,
  situation,
  date,
  institutionOptions,
  handleClickOnCloseModal
}: InstitutionsFormProps): JSX.Element => {
  const { addToast } = useToast()
  const { activeLoading, disableLoading } = useLoading()
  const [loading, setLoading] = useState(false)

  const [institutionsIds, setInstitutionsIds] = useState<number[]>([])

  const [institutionsByMenuFood, setInstitutionsByMenuFood] = useState<
    IInstitutionData[]
  >([])

  useEffect(() => {
    if (institutionOptions?.length && menu_food_id) {
      const institutionsByMenuFood = institutionOptions.filter(institution =>
        institution.menu_institution.some(
          menuInstitution =>
            menuInstitution.menu_food_id === Number(menu_food_id)
        )
      )
      setInstitutionsByMenuFood(institutionsByMenuFood)
    }
  }, [institutionOptions, menu_food_id])
  const addinstitutionId = (institutionId: number) => {
    setInstitutionsIds(old => [...old, institutionId])
  }

  const removeinstitutionId = (institutionIdToDelete: number) => {
    setInstitutionsIds(old => {
      return old.filter(
        institutionId => institutionId !== institutionIdToDelete
      )
    })
  }

  const onSubmitForm = async () => {
    if (!institutionsIds.length) {
      addToast({
        type: 'error',
        title: 'Selecione pelo menos uma instituição'
      })
      return
    }
    activeLoading()
    setLoading(true)
    try {
      api
        .post(
          'commercial/contractsMenuFoods/labelsPdf',
          {
            menu_food_id: menu_food_id,
            institution_ids: institutionsIds,
            situation: situation,
            date
          },
          {
            responseType: 'arraybuffer'
          }
        )
        .then(res => {
          const a = document.createElement('a')
          const blob = new Blob([res.data], {
            type: 'application/pdf'
          })
          const url = window.URL.createObjectURL(blob)
          a.href = url
          const institutionName = institutionOptions.find(
            a => a.id === Number(institution_id)
          )?.company_social_name
          const date = new Date()
          const today = `${date.getDate()}-${String(
            date.getMonth() + 1
          ).padStart(2, '0')}-${date.getFullYear()}`
          const fileName = `Etiquetas - ${institutionName} ${today}.pdf`
          a.download = fileName
          a.click()
          disableLoading()
        })
        .catch(() => {
          disableLoading()
        })
      handleClickOnCloseModal()
      disableLoading()
      setLoading(false)
      addToast({
        type: 'success',
        title: 'Relatório gerado com sucesso'
      })
    } catch (error: any) {
      addToast({
        type: 'error',
        title: 'Erro ao gerar relatório',
        description: 'Tente novamente'
      })
      handleClickOnCloseModal()
      disableLoading()
      setLoading(false)
    }
  }

  return (
    <Form onSubmit={onSubmitForm}>
      <>
        <div className="row mb-6">
          <p className="mb-2">Selecione as instituições: </p>
          {institutionsByMenuFood?.map(institutinons => (
            <div key={institutinons.id}>
              <input
                type="checkbox"
                name={`menu_foods_ids${institutinons.id}`}
                id={`menu_foods_ids${institutinons.id}`}
                value={institutinons.id}
                onChange={e =>
                  e.target.checked
                    ? addinstitutionId(Number(e.target.value))
                    : removeinstitutionId(Number(e.target.value))
                }
              />
              <label
                htmlFor={`menu_foods_ids${institutinons.id}`}
                className="mx-2"
              >
                {institutinons?.company_social_name}
              </label>
            </div>
          ))}
        </div>
        <div className="card-footer d-flex justify-content-end py-6 px-9">
          <button type="submit" className="btn btn-primary" disabled={loading}>
            Baixar
          </button>
        </div>
      </>
    </Form>
  )
}
