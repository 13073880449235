import React, { useEffect, useState } from 'react'
import api from '../../../../../services/api'
import Form, { Input, Select } from '../../../../../components/Form'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreateSerieClass, apiUpdateSerieClass } from '../../domain/api'
import { nameActions, schoolPeriods, schoolYears } from '../../domain/info'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: ISeriesClass & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
  class_serie?: { id: number; name?: string }
  institution_id: number
  setInstitution: React.Dispatch<React.SetStateAction<IInstitutionData>>
}

export const FormSerieClass = ({
  isOpenInModal,
  initialValues,
  typeForm,
  class_serie,
  institution_id,
  setInstitution
}: TypesFormProps): JSX.Element => {
  const { addToast } = useToast()
  const history = useHistory()
  const [defaultValues, setDefaultValues] = useState<any>()
  const { updateDataTable } = useUpdateDataTable()
  const [hasUpdated, setHasUpdated] = useState(false)

  useEffect(() => {
    if (initialValues && !hasUpdated) {
      setDefaultValues({
        ...initialValues
      })
    }
  }, [initialValues, hasUpdated])

  const { activeLoading, disableLoading } = useLoading()
  const onSubmitForm = async (data: ISeriesClass) => {
    data.institution_id = institution_id
    const id = initialValues?.idUpdate

    try {
      if (typeForm === 'create') {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          const dataCreate = {
            ...data
          }
          activeLoading()

          try {
            const res = await api.post<ISeriesClass>(
              apiCreateSerieClass(),
              dataCreate
            )
            handleOnClose()
            updateDataTable()
            disableLoading()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
            setInstitution(old => {
              const newInstitution = { ...old }
              const newInstitutionCreated = res.data
              newInstitution.series_classes = [
                ...old.series_classes,
                newInstitutionCreated
              ]
              return newInstitution
            })
          } catch (error: any) {
            console.log(error.response.data)

            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            handleOnClose()
            updateDataTable()
            disableLoading()
          }
        } else {
          try {
            const dataCreate = {
              ...data
            }
            console.log(dataCreate)

            activeLoading()
            await api.post(apiCreateSerieClass(), dataCreate)
            updateDataTable()
            disableLoading()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
            history.push(nameActions.read.to)
          } catch (err: any) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            updateDataTable()
            disableLoading()
          }
        }
      } else {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          const dataUpdate = {
            ...data
          }

          try {
            activeLoading()
            const res = await api.put<ISeriesClass>(
              apiUpdateSerieClass(String(id)),
              dataUpdate
            )

            setInstitution(old => {
              const newInstitution = { ...old }
              const newInstitutionCreated = res.data
              const actualSerieClassIndex = old.series_classes.findIndex(
                serie_class => {
                  return serie_class.id === id
                }
              )
              newInstitution.series_classes = [...old.series_classes]
              newInstitution.series_classes[actualSerieClassIndex] =
                newInstitutionCreated

              return newInstitution
            })
            // setHasUpdated(true)
            disableLoading()
            handleOnClose()
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (err: any) {
            disableLoading()
            handleOnClose()
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        } else {
          const dataUpdate = {
            ...data
          }
          try {
            activeLoading()
            const res = await api.put(
              apiUpdateSerieClass(String(id)),
              dataUpdate
            )
            setDefaultValues(res.data)
            updateDataTable()
            setHasUpdated(true)

            disableLoading()

            history.push(nameActions.read.to)
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (err: any) {
            // history.push(nameActions.read.to)
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        if (isOpenInModal) isOpenInModal.handleOnClose()
      }
    }
  }
  return (
    <Form
      onSubmit={onSubmitForm}
      setReset
      defaultValues={{
        ...defaultValues
      }}
    >
      <div className="card">
        <div className="pe-4 ps-4 row">
          <Select
            className="col-md-6"
            name="school_year"
            label="Ano/Turma"
            options={schoolYears.map(year => ({
              name: year,
              value: year
            }))}
            blank
            defaultValue={''}
          />
          <Select
            className="col-md-6"
            name="school_period"
            label="Turno"
            options={schoolPeriods.map(year => ({
              name: year,
              value: year
            }))}
            blank
            defaultValue={''}
          />
          <Input
            name="name"
            className={typeForm === 'update' ? 'col-md-8' : 'col-md-12'}
            label="Nome"
            rules={{ required: true, position: 'left' }}
          />
          {typeForm === 'update' && (
            <Select
              className="col-md-4"
              name="is_active"
              label="Ativo"
              options={[
                {
                  name: 'Sim',
                  value: 'true'
                },
                {
                  name: 'Não',
                  value: 'false'
                }
              ]}
              blank
              defaultValue={'true'}
            />
          )}
        </div>
        <div className="card-footer d-flex justify-content-end py-6 px-9">
          <button type="submit" className="btn btn-primary">
            Salvar
          </button>
        </div>
      </div>
    </Form>
  )
}
