import { useCallback, useState } from 'react'
import Form, { Input } from 'components/Form'
import { DateInput } from 'components/Form/date'
import { useToast } from 'hooks/toast'
import api from 'services/api'
import { Loading } from 'components/Loading'
import { convertValueMaskInNumberWithTwoZero } from 'utlis/mask'
import moment from 'moment'

interface IChangeDueDate {
  client_id: number
  contract_id: number
  isOpenInModal: {
    handleOnClose: () => void
  }
}

interface IAddFinancialMovimentData {
  due_date: string
  value: string
}

const AddFinancialMovimentForm = ({
  client_id,
  contract_id,
  isOpenInModal
}: IChangeDueDate) => {
  const { addToast } = useToast()

  const [loading, setIsloading] = useState(false)
  const [dueDate, setDueDate] = useState<Date>()
  const onSubmitForm = useCallback(
    async (data: IAddFinancialMovimentData) => {
      const createData = {
        client_id: client_id,
        contract_id: contract_id,
        description: 'Criação de boleto avulso',
        manual: true,
        nf_status: 'Wainting',
        operation_type: 'C',
        sub_category_id: 1,
        category_id: 1,
        payment_gateway_id: 1,
        value: convertValueMaskInNumberWithTwoZero(data.value),
        due_date: dueDate,
        period: moment(dueDate).format('YYYY-MM'),
        send_email: false
      }

      setIsloading(true)
      try {
        await api.post('/financial/financialMoviments/', {
          ...createData
        })
        isOpenInModal?.handleOnClose?.()
        addToast({
          type: 'success',
          title: 'Boleto emitido com sucesso'
        })
        setIsloading(false)
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Error ao emitir o boleto',
          description:
            'Houve um error ao emitir o boleto, tente novamente mais tarde!'
        })
        setIsloading(false)
      }
    },
    [addToast, client_id, contract_id, dueDate, isOpenInModal]
  )

  return (
    <>
      <Loading isActive={loading} />
      <Form onSubmit={onSubmitForm}>
        <div className="row">
          <DateInput
            label="Data de Vencimento"
            name="due_date"
            className="col-6"
            onChange={date => setDueDate(date)}
            selected={dueDate}
            minDate={moment().add(3, 'd').toDate()}
            controlled
            rules={{ required: true }}
          />
          <Input
            name="value"
            label="Valor"
            price
            className="col-md-6"
            rules={{ required: true }}
          />
        </div>
        <button type="submit" className="btn btn-primary float-end">
          Confirmar
        </button>
      </Form>
    </>
  )
}

export default AddFinancialMovimentForm
